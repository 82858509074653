import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { months } from 'src/constants';
import { getDataFormat } from 'src/helpers';

import s from './style.module.scss';

const WhiteSimpleStudent = ({ setOnRendered }) => {
  const { currentResume } = useSelector((state) => state.templatesReducer);

  const { image } = currentResume;

  useEffect(() => {
    const img = document.getElementById('avatar');
    if (img) {
      const imgAvatar = document.getElementById('avatar');
      imgAvatar.onload = () => {
        setOnRendered(true);
      };
    } else {
      setOnRendered(true);
    }
  }, []);

  return (
    <>
      {currentResume && (
        <div className={s.white}>
          <div className={s.top}>
            <div className={s.top__name}>{currentResume.firstName}</div>
            <div className={s.top__name}>{currentResume.lastName}</div>
            <div className={s.top__job}>{currentResume.jobTitle}</div>
          </div>

          <div className="section-col">
            <div className={s.left}>
              <div className={`section ${s.left__section}`}>
                <div className={s.about}>
                  <div className={s.about__name}>Contact me</div>

                  <div className={s.about__block}>
                    <div className={s.about__block__left}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 500.002 508.491"
                        enableBackground="new 0 0 500.002 508.491"
                        xmlSpace="preserve"
                        width={20}
                        height={20}
                      >
                        <g id="__id122_sxjaogtkff">
                          <path d="M473.01,359.842c-15.878-10.084-37.902-19.466-61.982-26.439c-23.855-6.91-47.322-10.711-66.085-10.711   c-25.386,0-40.323,6.974-44.45,20.908c-0.012,0.037-1.617,5.707-3.649,12.504c-0.101,0.364-0.213,0.79-0.301,1.216l-4.465,15.465   c-5.118-1.455-9.733-4.214-13.471-8.127L144.054,224.825c-5.374-5.601-8.24-13.069-8.221-20.927   c0.921-0.27,1.862-0.552,2.834-0.847c4.998,0.539,9.558-0.364,12.862-2.314l19.641-10.831c5.268-2.94,9.651-5.368,10.103-5.631   c0.496-0.207,0.985-0.445,1.455-0.709c26.013-14.499,8.315-68.279-14.749-109.926C161.174,61.344,125.586,0,93.697,0   c-4.001,0-7.732,0.929-11.1,2.76c-0.182,0.1-0.364,0.207-0.54,0.313L71.247,9.056C70.87,9.25,70.444,9.507,70.03,9.771   c-16.01,8.805-27.624,18.287-39.959,32.616c-8.027,9.318-14.612,19.421-19.572,30.026C0.421,93.935-2.52,117.125,2.189,137.845   C8.73,200.18,58.152,283.372,137.946,366.326c74.996,77.975,162.559,131.957,228.53,140.874c6.447,0.866,12.417,1.292,18.236,1.292   c20.056,0,37.853-5.242,54.408-16.054c25.837-16.844,43.02-40.637,55.649-76.997c0.188-0.514,0.39-1.179,0.564-1.869l1.856-6.384   c0.815-2.772,1.404-4.816,1.43-4.954c0.163-0.427,0.313-0.854,0.439-1.292C503.062,387.171,494.294,373.337,473.01,359.842z" />
                        </g>
                      </svg>
                    </div>

                    <div className={s.about__block__text}>{currentResume.phone}</div>
                  </div>

                  <div className={s.about__block}>
                    <div className={s.about__block__left}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 500 332.985"
                        enableBackground="new 0 0 500 332.985"
                        xmlSpace="preserve"
                        width={20}
                        height={20}
                      >
                        <g id="__id120_sxjaogtkff">
                          <path d="M484.398,0C475.501,0,15.584,0,15.584,0C6.216,0,0,5.364,0,13.488c0,3.943,0,305.986,0,305.986   c0,7.454,6.994,13.511,15.584,13.511h468.814c8.613,0,15.602-6.057,15.602-13.511c0,0-0.103-302.498-0.103-306.179   C499.897,6.193,494.637,0,484.398,0z M370.744,233.228l91.269,70.099H42.043l89.348-70.054c5.977-4.818,6.329-12.886,0.835-18.044   c-5.517-5.17-14.806-5.511-20.76-0.716l-80.298,62.86V58.907l182.793,140.584c19.687,14.522,50.821,13.909,70.923,0.068   l183.98-144.505v219.74l-78.917-60.827c-6.113-4.602-15.386-4.057-20.692,1.296C363.949,220.581,364.562,228.615,370.744,233.228z" />
                        </g>
                      </svg>
                    </div>

                    <div className={s.about__block__text}>{currentResume.email}</div>
                  </div>

                  <div className={s.about__block}>
                    <div className={s.about__block__left}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 283.464 425.215"
                        enableBackground="new 0 0 283.464 425.215"
                        xmlSpace="preserve"
                        width={20}
                        height={20}
                      >
                        <g id="__id124_sxjaogtkff">
                          <path d="M141.734,0C63.454,0,0,63.458,0,141.737c-0.001,26.57,7.309,51.42,20.029,72.672l121.7,210.808   l121.797-210.957c12.657-21.216,19.938-46.018,19.938-72.521C283.468,63.457,220.014,0,141.734,0z M141.734,189.168   c-26.193,0-47.429-21.237-47.424-47.432c0-26.192,21.236-47.429,47.424-47.429c26.198,0,47.434,21.236,47.434,47.429   C189.167,167.932,167.932,189.168,141.734,189.168z" />
                        </g>
                      </svg>
                    </div>

                    <div className={s.about__block__text}>
                      {currentResume.country.name}, {currentResume.city.name}, {currentResume.state}{' '}
                      {currentResume.zip}
                    </div>
                  </div>
                </div>
              </div>

              {currentResume.languages.length > 0 && (
                <div className={`section ${s.left__section}`}>
                  <div className={s.about}>
                    <div className={s.about__name}>Languagess</div>
                    {currentResume.languages.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.language} - {item.proficiency}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {currentResume.hardSkills.length > 0 && (
                <div className={`section ${s.left__section}`}>
                  <div className={s.about}>
                    <div className={s.about__name}>Hard skills</div>
                    {currentResume.hardSkills.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.skill}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {currentResume.softSkills.length > 0 && (
                <div className={`section ${s.left__section}`}>
                  <div className={s.about}>
                    <div className={s.about__name}>Soft skills</div>
                    {currentResume.softSkills.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.skill}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {image && (
                <div className={s.avatar__wrap}>
                  <div className={s.avatar}>
                    <img width={252} src={image} alt="avatar" id="avatar" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="section-col">
            {currentResume.experiences.length > 0 && (
              <div className={`section ${s.work}`}>
                {currentResume.experiences.map((item) => (
                  <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                    <div className={s.work__title}>
                      <span className={s.work__title__decor} />
                      Experiences
                    </div>

                    <div className={s.work__wrap}>
                      <div className={s.work__name}>{item.jobTitle}</div>
                      <div className={s.work__job}>{item.companyName}</div>
                      <div className={s.work__data}>
                        {getDataFormat(item.startDate)} -{' '}
                        {item.isPresent ? 'Present' : getDataFormat(item.endDate)}
                      </div>

                      <div className={s.text}>{item.responsibilities}</div>
                      <div className={s.text}>{item.achievements}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {currentResume.educations.length > 0 && (
              <div className={`section ${s.work}`}>
                {currentResume.educations.map((item) => (
                  <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                    <div className={s.work__title}>
                      <span className={s.work__title__decor} />
                      Education
                    </div>

                    <div className={s.work__wrap}>
                      <div className={s.work__name}>{item.universityName}</div>
                      <div className={s.work__job}>
                        {item.country.name}, {item.city.name}
                      </div>
                      <div className={s.work__data}>
                        {months[item.graduationMonth].name} {item.graduationYear}
                      </div>

                      <div className={s.text}>{item.achievements}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {currentResume.hobbies.length > 0 && (
              <div className={`section ${s.work}`}>
                {currentResume.hobbies.map((item) => (
                  <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                    <div className={s.work__title}>
                      <span className={s.work__title__decor} />
                      Hobbies
                    </div>

                    <div className={s.work__wrap}>
                      <div className={s.work__name}>{item.name}</div>

                      <div className={s.text}>{item.description}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {currentResume.certificates.length > 0 && (
              <div className={`section ${s.work}`}>
                {currentResume.certificates.map((item) => (
                  <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                    <div className={s.work__title}>
                      <span className={s.work__title__decor} />
                      Certifications
                    </div>

                    <div className={s.work__wrap}>
                      <div className={s.work__name}>{item.name}</div>

                      <div className={s.text}>{item.skills}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

WhiteSimpleStudent.propTypes = {
  setOnRendered: PropTypes.func.isRequired,
};
export default WhiteSimpleStudent;
