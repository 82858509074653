import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { months } from 'src/constants';
import { getDataFormat } from 'src/helpers';

import s from './style.module.scss';

const GreyClean = ({ setOnRendered }) => {
  const { currentResume } = useSelector((state) => state.templatesReducer);

  const { image } = currentResume;

  useEffect(() => {
    const img = document.getElementById('avatar');
    if (img) {
      const imgAvatar = document.getElementById('avatar');
      imgAvatar.onload = () => {
        setOnRendered(true);
      };
    } else {
      setOnRendered(true);
    }
  }, []);

  return (
    <>
      {currentResume && (
        <div className={s.cari}>
          <div className={s.title__wrap}>
            <div className={s.title}>
              {currentResume.firstName} {currentResume.lastName}
            </div>
            <div className={s.title2}>{currentResume.jobTitle}</div>
            {image && (
              <div className={s.avatar__wrap}>
                <div className={s.avatar}>
                  <img width={157} src={image} alt="avatar" id="avatar" />
                </div>
              </div>
            )}
          </div>
          <div className={s.cari__left}>
            <div className="section-col">
              <div className={`section ${s.section}`}>
                <div className={s.about}>
                  <div className={s.about__block}>
                    <div className={s.title__left}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="xMidYMid meet"
                        version="1.0"
                        viewBox="9.1 6.1 212.1 143.1"
                        zoomAndPan="magnify"
                        height={15}
                      >
                        <g id="__id127_s698zmr778i">
                          <path
                            d="m132.08 26.793c-33.677 0-64.327 11.29-87.183 29.733-11 3.5876-18.882 13.229-18.882 24.552 0 4.1839 1.0816 8.1238 2.9934 11.628h50.744c1.9119-3.5045 3.0222-7.4444 3.0222-11.628 0-6.5126-2.5991-12.471-6.9079-17.039 16.696-8.6434 35.855-13.557 56.213-13.557s39.517 4.9134 56.213 13.557c-4.3088 4.5682-6.9079 10.527-6.9079 17.039 1e-5 4.1839 1.0816 8.1238 2.9934 11.628h50.744c1.9119-3.5045 3.0222-7.4444 3.0222-11.628 1e-5 -11.323-7.882-20.964-18.882-24.552-22.857-18.443-53.507-29.733-87.183-29.733zm0 26.365c-26.835 0-48.614 23.031-48.614 51.406 0 2.3739 0.16545 4.7055 0.46053 6.9942-4.7279 2.7287-7.9153 7.8279-7.9153 13.701v28.84c0 8.7625 7.068 15.831 15.831 15.831h80.477c8.7625 0 15.831-7.068 15.831-15.831v-28.84c0-5.8676-3.1943-10.941-7.9153-13.672 0.29766-2.2984 0.46053-4.6386 0.46053-7.023 0-28.375-21.78-51.406-48.614-51.406zm0 15.341c13.995 1e-5 25.358 10.519 25.358 23.487s-11.363 23.516-25.358 23.516-25.358-10.548-25.358-23.516 11.363-23.487 25.358-23.487z"
                            transform="translate(-16.921 -20.658)"
                            fill=" rgb(112, 111, 111)"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className={s.about__block__right}>{currentResume.phone}</div>
                  </div>

                  <div className={s.about__block}>
                    <div className={s.title__left}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 350 250.005"
                        enableBackground="new 0 0 350 250.005"
                        xmlSpace="preserve"
                        height={15}
                      >
                        <g id="__id125_s698zmr778i">
                          <path
                            d="M181.67,181.692c-2.349,2.341-5.517,3.649-8.827,3.649c-3.332,0-6.5-1.309-8.842-3.649L1.223,18.886   C0.728,20.895,0,22.819,0,24.997v199.997c0,13.821,11.183,25.011,25.004,25.011h299.999c13.765,0,24.997-11.189,24.997-25.011   V24.997c0-3.438-0.729-6.713-1.967-9.662L181.67,181.692z"
                            fill=" rgb(112, 111, 111)"
                          />
                          <path
                            d="M172.843,155.181L327.5,0.503C326.63,0.41,325.886,0,325.003,0H25.004c-2.178,0-4.095,0.708-6.104,1.217   L172.843,155.181z"
                            fill=" rgb(112, 111, 111)"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className={s.about__block__right}>{currentResume.email}</div>
                  </div>

                  <div className={s.about__block}>
                    <div className={s.title__left}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        preserveAspectRatio="xMidYMid meet"
                        version="1.0"
                        viewBox="3.0 2.0 18.0 20.0"
                        zoomAndPan="magnify"
                        height={20}
                      >
                        <g id="__id131_s698zmr778i">
                          <path
                            clipRule="evenodd"
                            d="M8.45092 5.3169C7.58459 6.15095 7 7.41842 7 9.15789C7 10.2331 7.58912 11.6344 8.51427 13.1159C9.41611 14.5602 10.5402 15.9303 11.4065 16.9063C11.7321 17.2733 12.2679 17.2733 12.5936 16.9063C13.4598 15.9303 14.5839 14.5602 15.4857 13.1159C16.4109 11.6344 17 10.2331 17 9.15789C17 7.41842 16.4154 6.15095 15.5491 5.3169C14.675 4.47542 13.4358 4 12 4C10.5642 4 9.32497 4.47542 8.45092 5.3169ZM7.0638 3.8761C8.36132 2.62693 10.122 2 12 2C13.878 2 15.6387 2.62693 16.9362 3.8761C18.2414 5.1327 19 6.94418 19 9.15789C19 10.8263 18.1441 12.6348 17.1821 14.1753C16.1969 15.7531 14.9917 17.2173 14.0894 18.2339C12.968 19.4974 11.032 19.4974 9.91062 18.2339C9.00832 17.2173 7.80314 15.7531 6.81785 14.1753C5.85587 12.6348 5 10.8263 5 9.15789C5 6.94418 5.75856 5.1327 7.0638 3.8761Z"
                            fillRule="evenodd"
                            fill=" rgb(112, 111, 111)"
                          />
                        </g>
                        <g id="__id132_s698zmr778i">
                          <path
                            clipRule="evenodd"
                            d="M12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7ZM9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8C15 9.65685 13.6569 11 12 11C10.3431 11 9 9.65685 9 8Z"
                            fillRule="evenodd"
                            fill=" rgb(112, 111, 111)"
                          />
                        </g>
                        <g id="__id133_s698zmr778i">
                          <path
                            clipRule="evenodd"
                            d="M8.937 15.1817H9.5V17.1817H9.06378C7.6826 17.3609 6.56283 17.6562 5.81476 17.9967C5.4328 18.1705 5.19796 18.3336 5.07287 18.4576C5.05652 18.4738 5.04324 18.488 5.0325 18.5004C5.06561 18.5388 5.12358 18.5954 5.22292 18.6688C5.50289 18.8757 5.97193 19.104 6.64142 19.3133C7.9685 19.728 9.86323 20 12 20C14.1368 20 16.0315 19.728 17.3586 19.3133C18.0281 19.104 18.4971 18.8757 18.7771 18.6688C18.8764 18.5954 18.9344 18.5388 18.9675 18.5004C18.9568 18.488 18.9435 18.4738 18.9271 18.4576C18.802 18.3336 18.5672 18.1705 18.1852 17.9967C17.4372 17.6562 16.3174 17.3609 14.9362 17.1817H14.5V15.1817H15.063L15.1255 15.1896C16.6478 15.3822 18.0058 15.7176 19.0136 16.1763C19.5152 16.4045 19.9806 16.6859 20.3349 17.037C20.6922 17.3911 21 17.8843 21 18.5C21 19.3114 20.4757 19.9005 19.9656 20.2774C19.4359 20.6687 18.7334 20.979 17.9551 21.2222C16.3868 21.7123 14.2815 22 12 22C9.71849 22 7.61323 21.7123 6.04487 21.2222C5.26665 20.979 4.56412 20.6687 4.03444 20.2774C3.52434 19.9005 3 19.3114 3 18.5C3 17.8843 3.30777 17.3911 3.66506 17.037C4.01936 16.6859 4.48481 16.4045 4.98637 16.1763C5.99423 15.7176 7.35222 15.3822 8.8745 15.1896L8.937 15.1817Z"
                            fillRule="evenodd"
                            fill=" rgb(112, 111, 111)"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className={s.about__block__right}>
                      <div>
                        {currentResume.country.name}, {currentResume.city.name}
                      </div>

                      <div>
                        {currentResume.state} {currentResume.zip}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {currentResume.languages.length > 0 && (
                <div className={`section ${s.section}`}>
                  <div className={s.about}>
                    <div className={s.name}>Languagess</div>
                    {currentResume.languages.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.language} - {item.proficiency}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {currentResume.hardSkills.length > 0 && (
                <div className={`section ${s.section}`}>
                  <div className={s.about}>
                    <div className={s.name}>Hard skills</div>
                    {currentResume.hardSkills.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.skill}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {currentResume.softSkills.length > 0 && (
                <div className={`section ${s.section}`}>
                  <div className={s.about}>
                    <div className={s.name}>Soft skills</div>
                    {currentResume.softSkills.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.skill}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={s.cari__right}>
            <div className="section-col">
              {currentResume.experiences.length > 0 && (
                <div className={`section ${s.section}`}>
                  {currentResume.experiences.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Experiences</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__job}>{item.jobTitle}</div>
                          <div className={s.work__name}>{item.companyName}</div>
                          <div className={s.work__data}>
                            <div className={s.work__data__col}>{getDataFormat(item.startDate)}</div>
                            -
                            <div className={s.work__data__col}>
                              {item.isPresent ? 'Present' : getDataFormat(item.endDate)}
                            </div>
                          </div>

                          <div className={s.text}>{item.responsibilities}</div>
                          <div className={s.text}>{item.achievements}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.educations.length > 0 && (
                <div className={`section ${s.section}`}>
                  {currentResume.educations.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Education</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__top}>
                            <div className={s.work__job}>{item.universityName}</div>
                            <div className={s.work__data}>
                              <div className={s.work__data__col}>
                                {months[item.graduationMonth].name} {item.graduationYear}
                              </div>
                            </div>
                          </div>

                          <div className={s.work__name}>
                            {item.country.name}, {item.city.name}
                          </div>

                          <div className={s.text}>{item.achievements}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.hobbies.length > 0 && (
                <div className={`section ${s.section}`}>
                  {currentResume.hobbies.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Hobbies</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__job}>{item.name}</div>

                          <div className={s.text}>{item.description}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.certificates.length > 0 && (
                <div className={`section ${s.section}`}>
                  {currentResume.certificates.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Certifications</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__job}>{item.name}</div>

                          <div className={s.text}>{item.skills}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

GreyClean.propTypes = {
  setOnRendered: PropTypes.func.isRequired,
};
export default GreyClean;
