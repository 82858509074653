import { Covers } from 'src/components';
import { useTranslation } from 'src/hooks';

const MyCovers = () => {
  const { translate } = useTranslation();
  return (
    <div className="wrap content">
      <div className="content__wrap">
        <div className="content__tilte">{translate('Home.cover_letters')}</div>
        <div className="content__text">{translate('Home.cover_letters_desc')}</div>
      </div>
      <div className="dash">
        <Covers showTitle={false} />
      </div>
    </div>
  );
};

export default MyCovers;
