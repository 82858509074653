import './style.scss';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/sprite.svg';
import { useTranslation } from 'src/hooks';
import { EmailField, PasswordField } from 'src/pages/account/components';
import { authActions } from 'src/store/actions';

const Account = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const subscriptionInformation = useSelector((state) => state.authReducer.subscriptionInformation);

  useEffect(() => {
    dispatch(authActions.getInformationAboutSubscription());
  }, []);
  return (
    <div className="wrap content">
      <div className="content__wrap">
        <div className="content__tilte">{translate('account.my_account')}</div>
      </div>
      <div className="sett">
        <div className="sett__col">
          <div className="sett__title">{translate('account.account_settings')}</div>
          <div className="sett__block">
            <EmailField />
          </div>
          <div className="sett__block">
            <PasswordField />
          </div>
        </div>
        <div className="sett__col">
          <div className="sett__title">{translate('account.subscription_settings')}</div>
          <div className="sett__block">
            <div className="sett__title2">
              {translate('account.subscription')}
              <Link to="/choose">
                <svg width="20" height="20" viewBox="0 0 20 20">
                  <use xlinkHref={`${sprite}#plus`} />
                </svg>
              </Link>
            </div>
            <div className="sett__text">
              {subscriptionInformation ? subscriptionInformation.name : ''}
            </div>
          </div>
          <div className="sett__block">
            <div className="sett__title2">{translate('account.due_to')}</div>
            <div className="sett__text">
              {subscriptionInformation ? subscriptionInformation.ended_at : ''}
            </div>
          </div>
        </div>
        <div className="sett__col sett__col--last">
          <div className="sett__info">
            <div className="sett__colorTitle">
              <svg width="32" height="32" viewBox="0 0 32 32">
                <use xlinkHref={`${sprite}#light`} />
              </svg>
              {translate('account.let_us_know')}
            </div>
            <div className="sett__des">
              {translate('account.feel_free')}
              <Link to="/faq">
                <b>FAQ</b>
              </Link>{' '}
              <br />
              {translate('account.message')}
              <br /> {translate('account.to')}
              <a href="mail:example@example.com">example@example.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Account;
