import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import completed from 'src/assets/img/create/completed.svg';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';

import TopSidebar from './top-sidebar';

const Completed = () => {
  const location = useLocation();
  const { translate } = useTranslation();

  const subscriptionInformation = useSelector((state) => state.authReducer.subscriptionInformation);
  const dispatch = useDispatch();

  const { redirectTo } = location.state;
  console.log(redirectTo);

  useEffect(() => {
    dispatch(authActions.getInformationAboutSubscription());
    dispatch(authActions.getMeRefresh());
  }, []);

  return (
    <>
      <TopSidebar step={4} />
      <div className="create__templates__t">{translate('choose.payment_completed')}</div>
      <div className="create__completed">
        <div className="create__completed__img">
          <img src={completed} alt="completed" />
        </div>
        <div className="create__completed__text">{translate('choose.payment_successfully')}</div>
        <div className="create__completed__text">
          {translate('choose.access')}
          <b>{subscriptionInformation ? subscriptionInformation.ended_at : ''}</b>
        </div>
        <div className="create__completed__link">
          <Link to={redirectTo === '' ? '/dashboard' : redirectTo} className="btn btn-primary">
            {translate('choose.done')}
          </Link>
        </div>
      </div>
    </>
  );
};

export default Completed;
