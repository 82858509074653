import PropTypes from 'prop-types';
import { AuthWidthTemplate } from 'src/components';
import { useTranslation } from 'src/hooks';

const CoverTemplate = ({ id, title, image, description }) => {
  const { translate } = useTranslation();
  return (
    <div className="templates__item">
      <div className="templates__name">{title}</div>
      <div className="templates__img">
        <img src={image} alt="template" />
        <AuthWidthTemplate
          to="/my-covers/create/"
          content={translate('cover.choose_this_template')}
          template={id}
        />
      </div>
      <div className="template__des">{description}</div>
    </div>
  );
};

CoverTemplate.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default CoverTemplate;
