import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'src/hooks';
import { setShowCoverPreview } from 'src/store/reducers/templates.reducer';

const Sidebar = ({ step }) => {
  const { id, preview } = useSelector((state2) => state2.templatesReducer.currentCover);
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const getProcent = () => {
    if (step) {
      if (step !== 1) {
        return (step / 3) * 100;
      }

      return 33;
    }
    return 33;
  };

  const handleShowCover = () => {
    dispatch(setShowCoverPreview(true));
  };

  return (
    <div className="create__left">
      <div className="sidebar">
        <nav className="create__nav">
          <ul>
            <li>
              <NavLink to={`/my-covers/create/?id=${id}`} end className="create__nav__link">
                <span>1</span>
                {translate('cover_create.contact_information')}
              </NavLink>
            </li>
            <li>
              {id ? (
                <NavLink to={`/my-covers/create/content/?id=${id}`} className="create__nav__link">
                  <span>2</span>
                  {translate('cover_create.content')}
                </NavLink>
              ) : (
                <div className="create__nav__link">
                  <span>2</span>
                  {translate('cover_create.content')}
                </div>
              )}
            </li>
          </ul>
        </nav>
        {preview && (
          <div className="create__preview">
            <div className="create__t">{translate('cover_create.preview')}</div>

            <div className="create__preview__img">
              <img src={preview} alt="" />
              <button type="button" className="btn btn-primary" onClick={handleShowCover}>
                {translate('cover_create.preview')}
              </button>
            </div>
          </div>
        )}
        <div className="create__progress">
          <div className="create__t">{translate('cover_create.progress')}</div>
          <div className="create__progress__line">
            <div className="create__progress__percent" style={{ width: `${getProcent()}%` }} />
          </div>
        </div>
      </div>
    </div>
  );
};
Sidebar.propTypes = {
  step: PropTypes.number.isRequired,
};
export default Sidebar;
