import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import sprite from 'src/assets/sprite.svg';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { clearErrors } from 'src/store/reducers/auth.reducer';
import * as Yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(Yup);

const RessetPass = ({ show, handleClose, handleShowSignIn }) => {
  const errors = useSelector((state) => state.authReducer.errors);
  const { translate } = useTranslation();
  const fixWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const dispatch = useDispatch();
  const { token } = useParams();
  const { email } = useParams();

  useEffect(() => () => dispatch(clearErrors()), []);

  const RessetPassSchema = Yup.object().shape({
    password: Yup.string().required('Required').min(8, '').minNumbers(1),
    password_confirmation: Yup.string()
      .required('Required')
      .min(8, '')
      .minNumbers(1)
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const handleSubmit = async (values) => {
    const res = await dispatch(authActions.ressetPassword(values));
    if (res.payload.status === 200) {
      handleClose();
      handleShowSignIn();
    }
  };
  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
      token,
      email,
    },
    validationSchema: RessetPassSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Modal show={show} onHide={handleClose} fullscreen="sm-down">
      <Modal.Header>
        <Link to="/" className="modal-sm-logo">
          {translate('cv.title')}
        </Link>
        <button className="modal-closeBtn" type="button" onClick={handleClose}>
          <svg id="close" width="24" height="24" viewBox="0 0 24 24">
            <use xlinkHref={`${sprite}#close`} />
          </svg>
        </button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="modal__titlewrap">
            <div className="modal__title">{translate('components.reset_pass')}</div>
          </div>
          {errors && (
            <>
              {errors.message && <Alert>{errors.message}</Alert>}
              {errors.email && <Alert>{errors.email}</Alert>}
            </>
          )}
          <div className="modal__sign-in">
            <div className="modal__input">
              <div
                className={`input-pass ${
                  formik.errors.password && formik.touched.password ? 'is-invalid' : ''
                }`}
              >
                <input
                  className={`form-control ${
                    formik.errors.password && formik.touched.password ? 'is-invalid' : ''
                  }`}
                  type={showPass ? 'text' : 'password'}
                  placeholder={translate('components.password')}
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <button
                  type="button"
                  className="input-pass__btn"
                  onClick={() => setShowPass(!showPass)}
                >
                  <svg id="show-pass" width="19" height="18" viewBox="0 0 19 18">
                    <use xlinkHref={`${sprite}#${!showPass ? 'show-pass' : 'hide-pass'}`} />
                  </svg>
                </button>
              </div>
              {formik.errors.password && formik.touched.password ? (
                <div className="invalid-feedback">{formik.errors.password}</div>
              ) : null}
            </div>
            <div className="modal__input">
              <div
                className={`input-pass ${
                  formik.errors.password_confirmation && formik.touched.password_confirmation
                    ? 'is-invalid'
                    : ''
                }`}
              >
                <input
                  className={`form-control ${
                    formik.errors.password_confirmation && formik.touched.password_confirmation
                      ? 'is-invalid'
                      : ''
                  }`}
                  type={showPass2 ? 'text' : 'password'}
                  placeholder="Confirm Password"
                  name="password_confirmation"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <button
                  type="button"
                  className="input-pass__btn"
                  onClick={() => setShowPass2(!showPass2)}
                >
                  <svg id="show-pass" width="19" height="18" viewBox="0 0 19 18">
                    <use xlinkHref={`${sprite}#${!showPass2 ? 'show-pass' : 'hide-pass'}`} />
                  </svg>
                </button>
              </div>
              {formik.errors.password_confirmation && formik.touched.password_confirmation ? (
                <div className="invalid-feedback">{formik.errors.password_confirmation}</div>
              ) : null}
            </div>

            <div className="modal__input">
              <button type="submit" className="btn btn-primary btn-full" disabled={fixWaiter}>
                {fixWaiter ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{translate('loading')}</span>
                  </>
                ) : (
                  <>{translate('components.reset_pass_1')}</>
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

RessetPass.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleShowSignIn: PropTypes.func.isRequired,
};

export default RessetPass;
