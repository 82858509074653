import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import cover from 'src/assets/img/cover/cover.svg';
import cover from 'src/assets/img/cover/cover.png';
import compLg from 'src/assets/img/home/comp-lg.svg';
import compSm from 'src/assets/img/home/comp-sm.svg';
import { AuthButton } from 'src/components';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { templatesActions } from 'src/store/actions';

import { CoverExample } from './components';

const CoverExamples = () => {
  const covers = useSelector((state2) => state2.templatesReducer.coverTemplates);
  const { translate } = useTranslation();

  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const [numElements, setNumElements] = useState(12);

  useEffect(() => {
    dispatch(templatesActions.getCoversTemplates());
  }, []);

  const handelShowMoreClick = () => {
    setNumElements(numElements + 3);
  };

  const coversToShow = covers.slice(0, numElements);

  return (
    <>
      <div className="firstGrey">
        <div className="s1--cv">
          <div className="wrap">
            <div className="cvS1">
              <div className="s1__left">
                <div className="s1__t1 s1__t1--grey">
                  {translate('cover_create.cover_letter_examples')}
                </div>
                <div className="s1__t2">{translate('cover_create.learn_create')}</div>
                {width <= 565 && width > 500 && (
                  <img src={cover} className="cvS1__mobImg" alt="img" />
                )}
                {width <= 500 && <img src={cover} className="cvS1__mobImg" alt="img" />}
                <div className="s1__t3">{translate('cover_create.learn_create_desc')}</div>
                <AuthButton to="/my-covers/create/" content={translate('cover.create_cover')} />
                <div className="s1__comp">
                  <img src={width >= 565 ? compLg : compSm} alt="" />
                </div>
              </div>
              {width > 565 && (
                <div className="cvS1__right" style={{ backgroundImage: `url(${cover})` }} />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="explore cv-examples">
        <div className="wrap">
          <div className="title__wrap">
            <div className="title__label">{translate('cover.examples')}</div>
            <div className="title">{translate('cover.look_at_the_examples')}</div>
            <div className="title__text">{translate('cover.look_at_the_examples_desc')}</div>
          </div>
          <div className="explore__tabs mt-5">
            <div className="explore__text">
              {translate('cover.explore_the_examples_without_html')}
            </div>
            <div className="explore__btns" />
          </div>
          <div className="templates__wrap templates__wrap--colonmob">
            {coversToShow.map((item) => (
              <CoverExample key={item.id} id={item.id} image={item.preview} title={item.name} />
            ))}
          </div>
          <div className="btn-center">
            <button type="button" className="btn btn-primary" onClick={handelShowMoreClick}>
              {translate('cover.show_more')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoverExamples;
