import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { months } from 'src/constants';
import { getDataFormat } from 'src/helpers';

import s from './style.module.scss';

const BrownDigital = ({ setOnRendered }) => {
  const { currentResume } = useSelector((state) => state.templatesReducer);

  const { image } = currentResume;

  useEffect(() => {
    const img = document.getElementById('avatar');
    if (img) {
      const imgAvatar = document.getElementById('avatar');
      imgAvatar.onload = () => {
        setOnRendered(true);
      };
    } else {
      setOnRendered(true);
    }
  }, []);

  return (
    <>
      {currentResume && (
        <div className={s.cari}>
          <div className={s.simpletop}>
            <div className={s.title__wrap}>
              <div className={s.title}>
                <div>{currentResume.firstName}</div>
                <div>{currentResume.lastName}</div>
              </div>
              <div className={s.title2}>{currentResume.jobTitle}</div>
            </div>
          </div>
          <div className={s.cari__left}>
            {image && (
              <div className={s.avatar__wrap}>
                <div className={s.avatar}>
                  <img src={image} alt="avatar" id="avatar" />
                </div>
              </div>
            )}
            <div className={s.about}>
              <div className={s.about__block}>
                <div className={s.title__left}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid meet"
                    version="1.0"
                    viewBox="-0.3 -0.5 74.8 102.9"
                    zoomAndPan="magnify"
                    height={20}
                  >
                    <g id="__id125_s493g7wdh8">
                      <path d="m21.547 0.091s-21.827 0.287-21.544 20.059 4.253 37.255 10.773 46.998 23.242 35.248 42.236 32.67c0 0 9.355-2.007 16.44-6.593l-22.96-18.054s-6.803 4.299-11.055-1.433-17.575-32.381-17.575-32.381-3.685-8.598 4.536-12.323l-0.851-28.943z" />
                    </g>
                    <g id="__id126_s493g7wdh8">
                      <path d="m24.099.091c0,0 1.701-.571 4.818,1.147s3.118,3.15 3.118,3.15l.284,19.775c0,0-.851,1.147-1.984,2.291-1.134,1.146-4.819,2.867-5.387,1.72-.565-1.145-.849-28.083-.849-28.083z" />
                    </g>
                    <g id="__id127_s493g7wdh8">
                      <path d="m48.401 73.058 6.312-3.332s1.417-0.285 3.684 1.146c2.268 1.436 14.174 10.893 14.174 10.893s1.984 1.719 1.417 3.725-1.984 6.305-1.984 6.305l-23.603-18.737z" />
                    </g>
                  </svg>
                </div>
                <div className={s.about__block__right}>{currentResume.phone}</div>
              </div>

              <div className={s.about__block}>
                <div className={s.title__left}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid meet"
                    version="1.0"
                    viewBox="3.0 6.0 26.0 20.0"
                    zoomAndPan="magnify"
                    height={20}
                  >
                    <g data-name="1" id="__id129_s493g7wdh8">
                      <path d="M16,15.834l13-7.8V8a2,2,0,0,0-2-2H5A2,2,0,0,0,3,8v.034Z" />
                      <path d="M16.515,17.857a1,1,0,0,1-1.03,0L3,10.366V24a2,2,0,0,0,2,2H27a2,2,0,0,0,2-2V10.366Z" />
                    </g>
                  </svg>
                </div>
                <div className={s.about__block__right}>{currentResume.email}</div>
              </div>

              <div className={s.about__block}>
                <div className={s.title__left}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid meet"
                    version="1.0"
                    viewBox="10.0 4.0 28.0 40.0"
                    zoomAndPan="magnify"
                    height={20}
                  >
                    <g id="__id121_s493g7wdh8">
                      <path d="M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
                    </g>
                  </svg>
                </div>
                <div className={s.about__block__right}>
                  <div>
                    {currentResume.country.name}, {currentResume.city.name}
                  </div>

                  <div>
                    {currentResume.state} {currentResume.zip}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={s.cari__right}>
            <div className="section-col">
              {currentResume.languages.length > 0 && (
                <div className={`section ${s.section}`}>
                  <div className={` ${s.work__sub__section}`}>
                    <div className={s.work}>
                      <div className={s.work__title}>Languagess</div>

                      <div className={s.work__wrap}>
                        {currentResume.languages.map((item2) => (
                          <div className={s.skill} key={item2.id}>
                            {item2.language} - {item2.proficiency}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {currentResume.hardSkills.length > 0 && (
                <div className={`section ${s.section}`}>
                  <div className={`${s.work__sub__section}`}>
                    <div className={s.work}>
                      <div className={s.work__title}>Hard skills</div>

                      <div className={s.work__wrap}>
                        {currentResume.hardSkills.map((item2) => (
                          <div className={s.skill} key={item2.id}>
                            {item2.skill}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {currentResume.experiences.length > 0 && (
                <div className={`section ${s.section}`}>
                  {currentResume.experiences.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Experiences</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__top}>
                            <div className={s.work__name}>{item.companyName}</div>
                            <div className={s.work__data}>
                              <div className={s.work__data__col}>
                                {getDataFormat(item.startDate)}
                              </div>
                              -
                              <div className={s.work__data__col}>
                                {item.isPresent ? 'Present' : getDataFormat(item.endDate)}
                              </div>
                            </div>
                          </div>

                          <div className={s.work__job}>{item.jobTitle}</div>

                          <div className={s.text}>{item.responsibilities}</div>
                          <div className={s.text}>{item.achievements}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.educations.length > 0 && (
                <div className={`section ${s.section}`}>
                  {currentResume.educations.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Education</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__top}>
                            <div className={s.work__name}>{item.universityName}</div>
                            <div className={s.work__data}>
                              <div className={s.work__data__col}>
                                {months[item.graduationMonth].name} {item.graduationYear}
                              </div>
                            </div>
                          </div>

                          <div className={s.work__job}>
                            {item.country.name}, {item.city.name}
                          </div>

                          <div className={s.text}>{item.achievements}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.hobbies.length > 0 && (
                <div className={`section ${s.section}`}>
                  {currentResume.hobbies.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Hobbies</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__job}>{item.name}</div>

                          <div className={s.text}>{item.description}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.certificates.length > 0 && (
                <div className={`section ${s.section}`}>
                  {currentResume.certificates.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Certifications</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__job}>{item.name}</div>

                          <div className={s.text}>{item.skills}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.softSkills.length > 0 && (
                <div className={`section ${s.section}`}>
                  <div className={` ${s.work__sub__section}`}>
                    <div className={s.work}>
                      <div className={s.work__title}>Soft skills</div>

                      <div className={s.work__wrap}>
                        {currentResume.softSkills.map((item2) => (
                          <div className={s.skill} key={item2.id}>
                            {item2.skill}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

BrownDigital.propTypes = {
  setOnRendered: PropTypes.func.isRequired,
};
export default BrownDigital;
