import 'react-input-range/lib/css/index.css';

import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import Cropper from 'react-easy-crop';
import InputRange from 'react-input-range';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import dropzoneimg from 'src/assets/img/create/dropzone.svg';
import dropzoneimgMob from 'src/assets/img/create/dropzone-mob.svg';
import sprite from 'src/assets/sprite.svg';
import { GetPreview } from 'src/components';
import { jobTitles } from 'src/constants';
import { useQuery } from 'src/helpers';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { templatesActions } from 'src/store/actions';
import { sethaveCV } from 'src/store/reducers/auth.reducer';
import {
  setChooseTemplate,
  setCvContacts,
  setRedirectTo,
} from 'src/store/reducers/templates.reducer';

import getCroppedImg from './cropImage';
import Sidebar from './sidebar';

const Contacts = () => {
  const cvContacts = useSelector((state) => state.templatesReducer.cvContacts);
  const queryId = useQuery().get('id');
  const { translate } = useTranslation();
  const fixWaiter = useSelector((state) => state.templatesReducer.fixWaiter);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countries = useSelector((state) => state.templatesReducer.countries);

  const { id, firstName, lastName, email, phone, city, country, state, zip, image, jobTitle } =
    useSelector((state2) => state2.templatesReducer.currentResume);

  const { width } = useWindowDimensions();
  const [preview, setPreview] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [positionData, setPositionData] = useState({});
  const [cities, setCities] = useState([]);
  const citiesOptions = cities.map((item) => ({ value: item.id, label: item.name }));
  const [countryWaiter, setCountryWaiter] = useState(true);
  const { citiesWaiter } = useSelector((state2) => state2.templatesReducer);
  const user = useSelector((state2) => state2.authReducer.user);
  const prefilFirstName = user.name.split(' ')[0] || '';
  const prefilLastName = user.name.split(' ')[1] || '';
  const chooseTemplate = useSelector((state2) => state2.templatesReducer.chooseTemplate);
  const { subscription } = useSelector((state2) => state2.authReducer.user);
  const haveCV = useSelector((state2) => state2.authReducer.user.haveCV);

  useEffect(() => {
    if (!subscription && haveCV && !id) {
      dispatch(setRedirectTo(`/my-cv/create/`));
      navigate('/choose/');
    }
  }, []);

  useEffect(() => {
    if (preview) {
      setShowPreview(false);
      const file = new File([preview], 'file');
      dispatch(templatesActions.resumesUploadPreview({ image: file, id })).then(() => {
        navigate(`/my-cv/create/work/?id=${id}`);
      });
    }
  }, [preview]);

  const handleSubmit = async (values) => {
    const res = await dispatch(templatesActions.resumeCreate(values));
    if (chooseTemplate) {
      await dispatch(
        templatesActions.resumeTemplate({ id: res.payload.data.id, template: chooseTemplate }),
      );
    }
    dispatch(sethaveCV(true));
    setShowPreview(true);
  };

  const [cvContactsState] = useState(cvContacts);

  const formik = useFormik({
    initialValues: cvContactsState || {
      id: id || '',
      firstName: firstName || prefilFirstName || '',
      lastName: lastName || prefilLastName || '',
      jobTitle: jobTitle || '',
      email: email || '',
      phone: phone || '',
      country: (country && country.id) || (countries[0] && countries[0].id) || '1',
      city: (city && city.id) || '1',
      state: state || '',
      zip: zip || '',
      step: 2,
      image: '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (id !== '') {
      dispatch(setChooseTemplate(0));
    }
  }, [id]);

  useEffect(() => {
    const handleSet = () => {
      if (id && queryId) {
        dispatch(setCvContacts(formik.values));
      }
    };

    return handleSet;
  }, [formik.values]);

  useEffect(() => {
    if (image) {
      const handleDownload = (url) => {
        axios({
          url,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          const blob = new Blob([response.data]);
          const file = new File([blob], 'file');
          formik.setFieldValue('image', file);
        });
      };
      handleDownload(image);
    }
  }, [image]);

  const handleCitiesRelatedToCountry = async () => {
    if (!countryWaiter) {
      const res = await dispatch(
        templatesActions.getAllCitiesRelatedToCountry({ id: formik.values.country }),
      );

      setCities(res.payload.data);

      formik.setFieldValue('city', res.payload.data[0].id);
    }
  };

  useEffect(() => {
    if (countries.length && formik.values.country) {
      handleCitiesRelatedToCountry();
    }
  }, [formik.values.country, countryWaiter]);

  useEffect(() => {
    if (!!positionData.country && countries.length > 0) {
      const defaultCountryLocal = countries.filter((item) => item.name === positionData.country)[0];

      if (defaultCountryLocal && !country) {
        formik.setFieldValue('country', defaultCountryLocal.id);

        const defaultCityLocal = cities.filter((item) => item.name === positionData.city)[0];
        if (defaultCityLocal && !city) {
          formik.setFieldValue('city', defaultCityLocal.id);
        }
      }
      setCountryWaiter(false);
    }
  }, [positionData, countries]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.coords.latitude}&lon=${position.coords.longitude}`,
        );
        const data = await response.json();
        setPositionData({
          country: data.address.country,
          city: data.address?.district ? data.address?.district.replace(/\sRaion\b/g, '') : '',
        });
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          setCountryWaiter(false);
        }
      },
    );
  }, []);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.1);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const [imageUrl, setImageUrl] = useState(image);

  useEffect(() => {
    if (croppedImage) {
      const file = new File([croppedImage], 'image.jpeg', {
        type: croppedImage.type,
      });

      formik.setFieldValue('image', file);
      setImageSrc(null);
    }
  }, [croppedImage]);

  const onCropChange = (cropData) => {
    setCrop(cropData);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels2) => {
    setCroppedAreaPixels(croppedAreaPixels2);
  };

  const onZoomChange = (zoomData) => {
    setZoom(zoomData);
  };

  const showCroppedImage = async () => {
    const croppedImg = await getCroppedImg(imageSrc, croppedAreaPixels);
    setImageUrl(URL.createObjectURL(croppedImg));
    setCroppedImage(croppedImg);
  };

  const readFile = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });

  const onFileInputChange = async (event) => {
    const res = await readFile(event.target.files[0]);

    setImageSrc(res);
  };

  const cropRemove = () => {
    setImageSrc(null);
    formik.setFieldValue('image', '');
    setImageUrl('');
  };

  const [suggestions, setSuggestions] = useState([]);

  const onChange = (event, { newValue }) => {
    formik.setFieldValue('jobTitle', newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const filteredSuggestions =
      inputLength === 0
        ? []
        : jobTitles.filter(
            (suggestion) => suggestion.name.toLowerCase().slice(0, inputLength) === inputValue,
          );

    setSuggestions(filteredSuggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  return (
    <>
      {showPreview && <GetPreview setPreview={setPreview} />}
      <div className="create__mob">
        <Link to="/my-cv/" type="button" className="create__back">
          <svg width="24" height="24" viewBox="0 0 24 24">
            <use xlinkHref={`${sprite}#arr-back`} />
          </svg>
          {translate('cover_create.back')}
        </Link>

        <button type="button" className="create__mobPreview">
          <svg width="32" height="32" viewBox="0 0 32 32">
            <use xlinkHref={`${sprite}#preview`} />
          </svg>
        </button>
      </div>
      <div className="create__wrap">
        <Sidebar step={1} />
        <div className="create__right">
          <div className="create__title">{translate('cv_create.contact_information')}</div>
          <form className="create__form" onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.first_name')}</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="John"
                    name="firstName"
                    defaultValue={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.last_name')}</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={translate('cover_create.last_name')}
                    name="lastName"
                    defaultValue={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12">
                  <div className="label">{translate('cover_create.jobTitle')}</div>
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={(suggestion) => suggestion.name}
                    renderSuggestion={(suggestion) => <div>{suggestion.name}</div>}
                    inputProps={{
                      className: 'form-control',
                      placeholder: translate('cover_create.jobTitle'),
                      value: formik.values.jobTitle,
                      onChange,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.email')}</div>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="example@example.com"
                    name="email"
                    defaultValue={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.phone_number')}</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="+370 123 456 78"
                    name="phone"
                    defaultValue={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.country')}</div>
                  {countryWaiter ? (
                    <input
                      type="text"
                      value={translate('loading')}
                      className="form-control"
                      readOnly
                    />
                  ) : (
                    <select
                      className="form-control"
                      value={formik.values.country}
                      name="country"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {countries.length &&
                        countries.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  )}
                </div>
                <div className="col-12 col-sm-6">
                  <div className="label">City</div>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={
                      citiesOptions.filter(
                        (item) => String(item.value) === String(formik.values.city),
                      )[0]
                    }
                    isDisabled={citiesWaiter}
                    isLoading={citiesWaiter}
                    isSearchable
                    name="city"
                    options={citiesOptions}
                    onChange={(e) => formik.setFieldValue('city', e.value)}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.state')}</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={translate('cover_create.state_placeholder')}
                    name="state"
                    defaultValue={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.zip_code')}</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="LV-2167"
                    name="zip"
                    defaultValue={formik.values.zip}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              {!imageSrc ? (
                <>
                  {width > 565 ? (
                    <label className="dropzone" htmlFor="dropzone">
                      <input
                        type="file"
                        id="dropzone"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={onFileInputChange}
                      />
                      <div className="dropzone__img">
                        {imageUrl ? (
                          <img src={imageUrl} alt="icondropzone" width={50} height={50} />
                        ) : (
                          <img src={dropzoneimg} alt="icondropzone" />
                        )}
                      </div>
                      <div
                        className="dropzone__text"
                        dangerouslySetInnerHTML={{ __html: translate('cv_create.drop_image') }}
                      />
                    </label>
                  ) : (
                    <label className="dropzone" htmlFor="dropzone">
                      <input
                        type="file"
                        id="dropzone"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={onFileInputChange}
                      />
                      <div className="dropzone__img">
                        {imageUrl ? (
                          <img src={imageUrl} alt="icondropzone" width={50} height={50} />
                        ) : (
                          <img src={dropzoneimgMob} alt="icondropzone" />
                        )}
                      </div>
                      <div className="dropzone__text">{translate('cv_create.add_photo')}</div>
                    </label>
                  )}
                </>
              ) : (
                <div className="exp-modal__file upload">
                  <div className="cropContainer">
                    <Cropper
                      image={imageSrc}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={onCropChange}
                      onCropComplete={onCropComplete}
                      onZoomChange={onZoomChange}
                    />
                  </div>
                  <div className="cropRange">
                    <InputRange
                      type="range"
                      value={zoom}
                      minValue={1}
                      maxValue={3}
                      step={0.1}
                      onChange={(val) => {
                        setZoom(val);
                      }}
                    />
                  </div>
                  <div className="create__sub">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => {
                        cropRemove();
                      }}
                    >
                      {translate('cv_create.remove_image')}
                    </button>
                    <button type="button" className="btn btn-primary" onClick={showCroppedImage}>
                      {translate('cv_create.save')}
                    </button>
                  </div>
                </div>
              )}
            </div>
            {!imageSrc && (
              <div className="create__sub">
                <button type="submit" className="btn btn-primary" disabled={fixWaiter}>
                  {fixWaiter ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">{translate('loading')}</span>
                    </>
                  ) : (
                    <>{translate('cover_create.continue')}</>
                  )}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default Contacts;
