import './style.scss';

import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sprite from 'src/assets/sprite.svg';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import * as Yup from 'yup';

const EmailField = () => {
  const [editMode, setEditMode] = useState(false);
  const emailState = useSelector((state) => state.authReducer.user.email);
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const fixWaiter = useSelector((state) => state.authReducer.emailFieldWaiter);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter a valid email address').required('Required'),
  });

  const handleSubmit = async (values) => {
    const res = await dispatch(authActions.changeAccountEmail(values));
    if (res.payload.status === 200) {
      setEditMode(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: emailState,
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = () => {
    formik.handleSubmit();
  };

  return (
    <div>
      <div className="sett__title2">
        {translate('account.email_id')}
        {fixWaiter ? (
          <div className="error">
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            <span className="sr-only">{translate('loading')}</span>
          </div>
        ) : (
          <button type="button" onClick={editMode ? handleSave : handleEdit}>
            <svg width="20" height="20" viewBox="0 0 20 20">
              <use xlinkHref={`${sprite}#pencil`} />
            </svg>
            {editMode ? 'save' : 'edit'}
          </button>
        )}
      </div>
      {editMode ? (
        <form onSubmit={formik.handleSubmit}>
          <input
            type="email"
            name="email"
            id="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className="sett__text input__border"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="error">{formik.errors.email}</div>
          ) : null}
        </form>
      ) : (
        <div>
          <div className="sett__text">{formik.values.email}</div>
        </div>
      )}
    </div>
  );
};

export default EmailField;
