import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { BigWaiter } from 'src/components';
import { constants } from 'src/constants';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { authActions } from 'src/store/actions';

import TopSidebar from '../top-sidebar';
import { FullPaymentElement, MoneyBackGuaranty } from './components';

const stripePromise = loadStripe(constants.stripe.sk);

const Payment = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslation();

  const { width } = useWindowDimensions();

  const clientSecret = useSelector((state) => state.authReducer.clientSecret);
  const createPaymentIntentWaiter = useSelector(
    (state) => state.authReducer.createPaymentIntentWaiter,
  );

  const location = useLocation();
  const { name } = location.state;
  const { price } = location.state;
  const { productId } = location.state;
  const { redirectTo } = location.state;

  const fetchPaymentIntent = async () => {
    await dispatch(authActions.createPaymentIntent());
  };

  useEffect(() => {
    fetchPaymentIntent().then();
  }, []);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {createPaymentIntentWaiter ? (
        <BigWaiter />
      ) : (
        <>
          <TopSidebar step={3} />
          <div className="create__templates__t">{translate('choose.payment_information')}</div>
          <div className="payment">
            {width <= 767 && (
              <div className="payment__review">
                <div className="payment__review__t">{translate('choose.order_review')}</div>
                <div className="payment__list">
                  <div className="payment__list__item">
                    {name}
                    <span>{price}</span>
                  </div>
                </div>
                <div className="payment__total">
                  {translate('choose.total_billed')}
                  <span>{price}</span>
                </div>
              </div>
            )}
            <div className="payment__method">
              <div className="payment__method__t">{translate('choose.choose_payment')}</div>
              <div className="payment__method__list">
                <Elements options={options} stripe={stripePromise}>
                  <FullPaymentElement productId={productId} redirectTo={redirectTo} />
                </Elements>
              </div>
            </div>
            <div className="payment__right">
              {width > 767 && (
                <div className="payment__review">
                  <div className="payment__review__t">{translate('choose.order_review')}</div>
                  <div className="payment__list">
                    <div className="payment__list__item">
                      {name}
                      <span>{price}</span>
                    </div>
                  </div>
                  <div className="payment__total">
                    {translate('choose.total_billed')}
                    <span>{price}</span>
                  </div>
                </div>
              )}

              <MoneyBackGuaranty />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Payment;
