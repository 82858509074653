import { configureStore } from '@reduxjs/toolkit';

import { authReducer, templatesReducer } from './reducers';

export const store = configureStore({
  reducer: {
    authReducer,
    templatesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV === 'development',
});
