import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { monthNames } from 'src/constants';

export const getHeaders = () => {
  const headers = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  };

  return headers;
};

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const getDataFormat = (dateStr) => {
  const dateObj = new Date(dateStr);

  const month = monthNames[dateObj.getUTCMonth()];
  const year = dateObj.getUTCFullYear();

  return `${month} ${year}`;
};

export const getDataObj = (dateString) => {
  const parts = dateString.split('-');

  // Перетворюємо частини рядка на числа
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const day = parseInt(parts[2], 10);

  return new Date(year, month, day);
};

export const getDataStandart = (currentDate) => {
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};
