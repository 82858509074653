import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import fon from 'src/assets/img/preview/minna.png';
import fon2 from 'src/assets/img/preview/minna-cover.png';

import s from './style.module.scss';

const Minna = ({ setOnRendered }) => {
  const { currentCover } = useSelector((state) => state.templatesReducer);

  useEffect(() => {
    setOnRendered(true);
  });

  return (
    <>
      {currentCover && (
        <div className={s.t}>
          <div className={s.t__left}>
            <img src={fon} alt="" className={s.t__left__img} />
            <img src={fon2} alt="" className={s.t__left__img2} />
            <div className={s.t__left__wrap}>
              <div className={s.contacts}>
                <div className={s.title}>Contacts</div>
                <div className={s.contacts__item}>{currentCover.date}</div>
                <div className={s.contacts__item}>
                  {currentCover.country.name}, {currentCover.city.name}
                </div>
                <div className={s.contacts__item}>
                  {currentCover.state} {currentCover.zip}
                </div>

                <div className={s.contacts__item}>{currentCover.phone}</div>
                <div className={s.contacts__item}>{currentCover.email}</div>
              </div>
            </div>
          </div>
          <div className={s.t__right}>
            <div className="section-col">
              <div className={s.t__name}>
                <div className={s.t__name__wrap}>
                  {currentCover.firstName} {currentCover.lastName}
                </div>
                <div className={s.t__name__wrap2}>{currentCover.jobTitle}</div>
              </div>

              <div className="section">
                <div className={s.main}>
                  <div className={`${s.main__row} sub-section`}>
                    <div className={s.main__title}>JOB APPLICATION</div>
                    <div className="pb-3" />
                  </div>

                  {currentCover.greeting && (
                    <div className={`${s.main__row} sub-section`}>
                      <div className={s.main__text}>{currentCover.greeting}</div>
                    </div>
                  )}

                  {currentCover.openingParagraph && (
                    <div className={`${s.main__row} sub-section`}>
                      <div className={s.main__text}>{currentCover.openingParagraph}</div>
                    </div>
                  )}

                  {currentCover.secondParagraph && (
                    <div className={`${s.main__row} sub-section`}>
                      <div className={s.main__text}>{currentCover.secondParagraph}</div>
                    </div>
                  )}

                  {currentCover.thirdParagraph && (
                    <div className={`${s.main__row} sub-section`}>
                      <div className={s.main__text}>{currentCover.thirdParagraph}</div>
                    </div>
                  )}

                  {currentCover.signature && (
                    <div className={`${s.main__row} sub-section`}>
                      <div className={s.main__text}>{currentCover.signature}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Minna.propTypes = {
  setOnRendered: PropTypes.func.isRequired,
};
export default Minna;
