import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Loader, TopWaiter } from 'src/components';
import { useQuery } from 'src/helpers';
import { templatesActions } from 'src/store/actions';
import { setDefaultCover } from 'src/store/reducers/templates.reducer';

import { Contacts, Content, Templates } from './components';

const CoverCreate = () => {
  const waiter = useSelector((state) => state.templatesReducer.waiter);
  const fixWaiter = useSelector((state) => state.templatesReducer.fixWaiter);
  const [defaultWaiter, setDefaultWaiter] = useState(true);
  const query = useQuery();
  const dispatch = useDispatch();
  const id = query.get('id');

  const handleDispatchCv = async () => {
    if (id) {
      await dispatch(templatesActions.getCover(id));
    } else {
      await dispatch(setDefaultCover());
    }
    setDefaultWaiter(false);
  };

  useEffect(() => {
    dispatch(templatesActions.getCountries());
    dispatch(templatesActions.getCoversTemplates());
    handleDispatchCv();
  }, []);

  return (
    <>
      <TopWaiter isActive={fixWaiter} />

      <div className="create">
        <div className="wrap content">
          {waiter && defaultWaiter ? (
            <Loader />
          ) : (
            <Routes>
              <Route path="/*" element={<Contacts />} />
              <Route path="/content/" element={<Content />} />
              <Route path="/templates/" element={<Templates />} />
            </Routes>
          )}
        </div>
      </div>
    </>
  );
};

export default CoverCreate;
