import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import cv from 'src/assets/img/cv/cv.svg';
import cv from 'src/assets/img/cv/cv.png';
import compLg from 'src/assets/img/home/comp-lg.svg';
import compSm from 'src/assets/img/home/comp-sm.svg';
import { AuthButton } from 'src/components';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { templatesActions } from 'src/store/actions';

import { CvTemplate } from './components';

const CvTemplates = () => {
  const resumeTemplates = useSelector((state) => state.templatesReducer.resumeTemplates);
  const { translate } = useTranslation();

  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const [numElements, setNumElements] = useState(12);

  useEffect(() => {
    dispatch(templatesActions.getResumeTemplates());
  }, []);

  const handelShowMoreClick = () => {
    setNumElements(numElements + 3);
  };

  const cvToShow = resumeTemplates.slice(0, numElements);

  return (
    <>
      <div className="firstGrey">
        <div className="s1--cv">
          <div className="wrap">
            <div className="cvS1">
              <div className="s1__left">
                <div className="s1__t1 s1__t1--grey">{translate('cv.cv_templates')}</div>
                <div className="s1__t2">{translate('cv.choose_personalize')}</div>
                {width <= 565 && width > 500 && <img src={cv} className="cvS1__mobImg" alt="img" />}
                {width <= 500 && <img src={cv} className="cvS1__mobImg" alt="img" />}
                <div className="s1__t3">{translate('cv.choose_personalize_desc')}</div>
                <AuthButton to="/my-cv/create/" content="Byuld my Resume Now" />
                <div className="s1__comp">
                  <img src={width >= 565 ? compLg : compSm} alt="" />
                </div>
              </div>
              {width > 565 && (
                <div className="cvS1__right" style={{ backgroundImage: `url(${cv})` }} />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="explore cv-templates">
        <div className="wrap">
          <div className="title__wrap">
            <div className="title__label">{translate('Home.templates')}</div>
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: translate('Home.choose_a_sample') }}
            />
            <div className="title__text">{translate('Home.choose_a_sample_desc')}</div>
          </div>

          <div className="templates__wrap templates__wrap--colonmob">
            {cvToShow.map((item) => (
              <CvTemplate
                key={item.id}
                id={item.id}
                title={item.name}
                image={item.preview}
                description={item.description}
              />
            ))}
          </div>
          <div className="btn-center">
            <button type="button" className="btn btn-primary" onClick={handelShowMoreClick}>
              Show more
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CvTemplates;
