import { gapi } from 'gapi-script';
import { useEffect } from 'react';
import GoogleOneTapLogin from 'react-google-one-tap-login';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authActions } from 'src/store/actions';

const googleID = process.env.REACT_APP_GOOGLE_SITE_KEY;

const GoogleModule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate('/');
  useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.auth2.init({ clientId: googleID });
    });
  });

  const googleOneTap = async (response) => {
    const res = await dispatch(
      authActions.signInGoogleModule({
        email: response.email,
        sub: response.sub,
        given_name: response.given_name,
        family_name: response.family_name,
      }),
    );
    if (res.payload.status === 200) {
      navigate('/account/');
    }
  };

  return (
    <GoogleOneTapLogin
      onSuccess={(response) => googleOneTap(response)}
      googleAccountConfigs={{ client_id: googleID }}
    />
  );
};

export default GoogleModule;
