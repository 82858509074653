import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/sprite.svg';
import { useTranslation } from 'src/hooks';
import { templatesActions } from 'src/store/actions';

import { BlogListComponent, ShowMoreButton } from './components';

const BlogList = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslation();

  const blogs = useSelector((state) => state.templatesReducer.blogs);
  const allBlogs = useSelector((state) => state.templatesReducer.allBlogs);

  useEffect(() => {
    dispatch(templatesActions.getAllBlogs());
    dispatch(templatesActions.getBlogs());
  }, []);

  return (
    <div className="wrap">
      <nav className="breadcrumb">
        <ul>
          <li>
            <Link to="/">{translate('blog.home')}</Link>
            <svg width="11" height="6">
              <use xlinkHref={`${sprite}#v`} />
            </svg>
          </li>
          <li>
            <span>{translate('blog.blog')}</span>
          </li>
        </ul>
      </nav>
      {blogs &&
        blogs.map((item, index) => (
          <div key={item.id}>
            <div className="title__wrap">
              <div className="title">{item.name}</div>
              <div className="title__text">{item.description}</div>
            </div>
            <div className="blog__block">
              <div className="row">
                {item.elements.map((elements, elementsIndex) => (
                  <div className="col-12 col-md-6">
                    <BlogListComponent
                      key={elements.id}
                      to={elements.slug}
                      image={elements.pictureUrl}
                      title={elements.title}
                      data={elements.created}
                      description={elements.text}
                      cssClass={elementsIndex === 0 ? 'blog__item--big' : 'blog__item--small'}
                    />
                  </div>
                ))}
              </div>
              <ShowMoreButton id={item.id} index={index} action />
            </div>
          </div>
        ))}
      <div className="title__wrap">
        <div className="title">{translate('blog.all_articles')}</div>
      </div>
      <div className="blog__block">
        <div className="row">
          {allBlogs &&
            allBlogs.data.map((item) => (
              <div className="col-12 col-sm-6 col-lg-4" key={item.id}>
                <BlogListComponent
                  to={item.slug}
                  image={item.pictureUrl}
                  title={item.title}
                  data={item.created}
                  description={item.text}
                  cssClass="blog__item--small"
                />
              </div>
            ))}
        </div>
        <div className="btn-center">
          <ShowMoreButton index={0} action={false} id={0} />
        </div>
      </div>
    </div>
  );
};

export default BlogList;
