import { Link } from 'react-router-dom';

const PageList = () => (
  <div className="wrap content-wraper">
    <ul>
      <li>
        <Link to="/cv">cv</Link>
      </li>
      <li>
        <Link to="/cv-examples">cv-examples</Link>
      </li>
      <li>
        <Link to="/cv-templates">cv-templates</Link>
      </li>
      <li>
        <Link to="/cover">cover</Link>
      </li>
      <li>
        <Link to="/cover-examples">cover-examples</Link>
      </li>
      <li>
        <Link to="/cover-templates">cover-templates</Link>
      </li>
      <li>
        <Link to="/blog">blog</Link>
        <ul>
          <li>
            <Link to="/blog/detail">blog detail</Link>
          </li>
        </ul>
      </li>

      <li>
        <Link to="/account">account</Link>
      </li>
      <li>
        <Link to="/dashboard">dashboard</Link>
      </li>
      <li>
        <Link to="/my-cv">my-cv</Link>
        <ul>
          <li>
            <Link to="/my-cv/create/">my-cv create</Link>
          </li>
          <li>
            <Link to="/my-cv/create/work">my-cv create work</Link>
          </li>
          <li>
            <Link to="/my-cv/create/education">my-cv create education</Link>
          </li>
          <li>
            <Link to="/my-cv/create/certifications">my-cv create certifications</Link>
          </li>
          <li>
            <Link to="/my-cv/create/skills">my-cv create skills</Link>
          </li>
          <li>
            <Link to="/my-cv/create/languages">my-cv create languages</Link>
          </li>
          <li>
            <Link to="/my-cv/create/templates">my-cv create templates</Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to="/my-covers">my-covers</Link>
        <ul>
          <li>
            <Link to="/my-covers/create/">my-covers create</Link>
          </li>
          <li>
            <Link to="/my-covers/create/content">my-covers create content</Link>
          </li>
          <li>
            <Link to="/my-covers/create/templates">my-covers create templates</Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to="/choose">choose</Link>
      </li>
      <li>
        <Link to="/choose/payment">payment</Link>
      </li>
      <li>
        <Link to="/choose/completed">completed</Link>
      </li>
    </ul>
  </div>
);

export default PageList;
