import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/sprite.svg';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { clearErrors } from 'src/store/reducers/auth.reducer';
import * as Yup from 'yup';

const FogotPass = ({ show, handleClose, handleShowSignIn }) => {
  const errors = useSelector((state) => state.authReducer.errors);
  const { translate } = useTranslation();
  const fixWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const [isSub, setIsSub] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => () => dispatch(clearErrors()), []);

  const FogotPassSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });

  const handleSubmit = async (values) => {
    const res = await dispatch(authActions.forgotPassword(values));
    if (res.payload.status === 200) {
      setIsSub(true);
    }
  };
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: FogotPassSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Modal show={show} onHide={handleClose} fullscreen="sm-down">
      <Modal.Header>
        <Link to="/" className="modal-sm-logo">
          {translate('cv.title')}
        </Link>
        <button className="modal-closeBtn" type="button" onClick={handleClose}>
          <svg id="close" width="24" height="24" viewBox="0 0 24 24">
            <use xlinkHref={`${sprite}#close`} />
          </svg>
        </button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          {!isSub ? (
            <>
              <div className="modal__titlewrap">
                <div className="modal__title">{translate('components.forgot_pass')}</div>
                <div className="modal__text">{translate('components.forgot_pass_desc')}</div>
              </div>
              {errors && (
                <>
                  {errors.message && <Alert>{errors.message}</Alert>}
                  {errors.email && <Alert>{errors.email}</Alert>}
                </>
              )}
              <div className="modal__sign-in">
                <div className="modal__input">
                  <input
                    className={`form-control ${
                      formik.errors.email && formik.touched.email ? 'is-invalid' : ''
                    }`}
                    type="email"
                    placeholder="Email address"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.email && formik.touched.email ? (
                    <div className="invalid-feedback">{formik.errors.email}</div>
                  ) : null}
                </div>

                <div className="modal__input">
                  <button type="submit" className="btn btn-primary btn-full" disabled={fixWaiter}>
                    {fixWaiter ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="sr-only">{translate('loading')}</span>
                      </>
                    ) : (
                      <>{translate('components.submit')}</>
                    )}
                  </button>
                </div>
                <div className="modal__bot">
                  {translate('components.remember_pass')}
                  <button
                    type="button"
                    onClick={() => {
                      handleClose();
                      handleShowSignIn();
                    }}
                  >
                    Login
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="modal__titlewrap">
                <div className="modal__title">{translate('components.email_sended')}</div>
                <div className="modal__text">{translate('components.email_sended_desc')}</div>
              </div>
              <div className="modal__sign-in">
                <div className="modal__input">
                  <button type="button" className="btn btn-primary btn-full" onClick={handleClose}>
                    {translate('components.ok')}
                  </button>
                </div>
              </div>
            </>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
};

FogotPass.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleShowSignIn: PropTypes.func.isRequired,
};

export default FogotPass;
