import { createSlice } from '@reduxjs/toolkit';

import { templatesActions } from '../actions';

const currentResume = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  state: '',
  zip: '',
  step: '1',
  created: '',
  country: null,
  city: null,
  template: null,
  experiences: [],
  hobbies: [],
  educations: [],
  certificates: [],
  hardSkills: [],
  softSkills: [],
  languages: [],
};

const currentCover = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  jobTitle: '',
  state: '',
  zip: '',
  step: 1,
  date: '',
  greeting: '',
  openingParagraph: '',
  secondParagraph: '',
  thirdParagraph: '',
  signature: '',
  created: '',
  country: null,
  city: null,
  template: null,
};

const templatesSlice = createSlice({
  name: 'templates',
  initialState: {
    resumes: [],
    resumeWaiter: false,
    covers: [],
    coversWaiter: false,
    currentResume,
    currentCover,
    countries: [],
    citiesWaiter: [],
    educationFields: [],
    educationDegrees: [],
    resumeTemplates: [],
    coverTemplates: [],
    errors: false,
    waiter: false,
    fixWaiter: false,
    showCvPreview: false,
    showCoverPreview: false,
    redirectTo: '',
    chooseTemplate: 0,
    coverContacts: false,
    coverContent: false,
    cvContacts: false,
    cvWork: false,
    cvHobbies: false,
    cvEducation: false,
    cvSertification: false,
    cvSkills: false,
    cvLangs: false,
  },
  reducers: {
    setCvContacts(state, action) {
      state.cvContacts = action.payload;
    },
    setCvWork(state, action) {
      state.cvWork = action.payload;
    },
    setCvHobbies(state, action) {
      state.cvHobbies = action.payload;
    },
    setCvEducation(state, action) {
      state.cvEducation = action.payload;
    },
    setCvSertification(state, action) {
      state.cvSertification = action.payload;
    },
    setCvSkills(state, action) {
      state.cvSkills = action.payload;
    },
    setCvLangs(state, action) {
      state.cvLangs = action.payload;
    },
    setCoverConacts(state, action) {
      state.coverContacts = action.payload;
    },
    setCoverContent(state, action) {
      state.coverContent = action.payload;
    },
    clearErrors(state) {
      state.errors = false;
    },
    setDefaultResume(state) {
      state.currentResume = currentResume;
      state.cvContacts = false;
      state.cvWork = false;
      state.cvEducation = false;
      state.cvSertification = false;
      state.cvSkills = false;
      state.cvLangs = false;
    },
    setDefaultCover(state) {
      state.currentCover = currentCover;
      state.coverContacts = false;
      state.coverContent = false;
    },
    setCurrentResume(state, action) {
      state.currentResume = action.payload;
    },
    setShowCvPreview(state, action) {
      state.showCvPreview = action.payload;
    },
    setCurrentCover(state, action) {
      state.currentCover = action.payload;
    },
    setShowCoverPreview(state, action) {
      state.showCoverPreview = action.payload;
    },
    setRedirectTo(state, action) {
      state.redirectTo = action.payload;
    },
    setChooseTemplate(state, action) {
      state.chooseTemplate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // getResumes
      .addCase(templatesActions.getResumes.pending, (state) => {
        state.resumeWaiter = true;
        state.errors = false;
      })
      .addCase(templatesActions.getResumes.fulfilled, (state, action) => {
        state.resumes = action.payload.data;
        state.resumeWaiter = false;
        state.errors = false;
      })
      .addCase(templatesActions.getResumes.rejected, (state, action) => {
        state.resumeWaiter = false;
        state.errors = action.payload.errors;
      })

      // getCovers
      .addCase(templatesActions.getCovers.pending, (state) => {
        state.coversWaiter = true;
        state.errors = false;
      })
      .addCase(templatesActions.getCovers.fulfilled, (state, action) => {
        state.covers = action.payload.data;
        state.coversWaiter = false;
        state.errors = false;
      })
      .addCase(templatesActions.getCovers.rejected, (state, action) => {
        state.coversWaiter = false;
        state.errors = action.payload.errors;
      })

      // getResume
      .addCase(templatesActions.getResume.pending, (state) => {
        state.waiter = true;
      })
      .addCase(templatesActions.getResume.fulfilled, (state, action) => {
        state.currentResume = action.payload.data;
        state.waiter = false;
      })
      .addCase(templatesActions.getResume.rejected, (state) => {
        state.waiter = false;
      })

      // getCover
      .addCase(templatesActions.getCover.pending, (state) => {
        state.waiter = true;
      })
      .addCase(templatesActions.getCover.fulfilled, (state, action) => {
        state.currentCover = action.payload.data;
        state.waiter = false;
      })
      .addCase(templatesActions.getCover.rejected, (state) => {
        state.waiter = false;
      })

      //  getCountries
      .addCase(templatesActions.getCountries.pending, (state) => {
        state.waiter = true;
      })
      .addCase(templatesActions.getCountries.fulfilled, (state, action) => {
        state.countries = action.payload.data;
        state.waiter = false;
      })
      .addCase(templatesActions.getCountries.rejected, (state) => {
        state.waiter = false;
      })

      //  getAllCitiesRelatedToCountry
      .addCase(templatesActions.getAllCitiesRelatedToCountry.pending, (state) => {
        state.citiesWaiter = true;
      })
      .addCase(templatesActions.getAllCitiesRelatedToCountry.fulfilled, (state) => {
        state.citiesWaiter = false;
      })

      //  getEducationFields
      .addCase(templatesActions.getEducationFields.pending, (state) => {
        state.waiter = true;
      })
      .addCase(templatesActions.getEducationFields.fulfilled, (state, action) => {
        state.educationFields = action.payload.data;
        state.waiter = false;
      })
      .addCase(templatesActions.getEducationFields.rejected, (state) => {
        state.waiter = false;
      })

      //  getResumeTemplates
      .addCase(templatesActions.getResumeTemplates.pending, (state) => {
        state.waiter = true;
      })
      .addCase(templatesActions.getResumeTemplates.fulfilled, (state, action) => {
        state.resumeTemplates = action.payload.data;
        state.waiter = false;
      })
      .addCase(templatesActions.getResumeTemplates.rejected, (state) => {
        state.waiter = false;
      })

      //  getCoversTemplates
      .addCase(templatesActions.getCoversTemplates.pending, (state) => {
        state.waiter = true;
      })
      .addCase(templatesActions.getCoversTemplates.fulfilled, (state, action) => {
        state.coverTemplates = action.payload.data;
        state.waiter = false;
      })
      .addCase(templatesActions.getCoversTemplates.rejected, (state) => {
        state.waiter = false;
      })

      //  getEducationDegrees
      .addCase(templatesActions.getEducationDegrees.pending, (state) => {
        state.waiter = true;
      })
      .addCase(templatesActions.getEducationDegrees.fulfilled, (state, action) => {
        state.educationDegrees = action.payload.data;
        state.waiter = false;
      })
      .addCase(templatesActions.getEducationDegrees.rejected, (state) => {
        state.waiter = false;
      })

      //  resumeCreate
      .addCase(templatesActions.resumeCreate.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.resumeCreate.fulfilled, (state, action) => {
        state.currentResume = action.payload.data;
      })

      //  coverCreate
      .addCase(templatesActions.coverCreate.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.coverCreate.fulfilled, (state, action) => {
        state.currentCover = action.payload.data;
      })

      //  resumeExperience
      .addCase(templatesActions.resumeExperience.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.resumeExperience.fulfilled, (state, action) => {
        state.currentResume = action.payload.data;
      })

      //  resumeExperience
      .addCase(templatesActions.resumeHobbies.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.resumeHobbies.fulfilled, (state, action) => {
        state.currentResume = action.payload.data;
      })

      //  resumeEducation
      .addCase(templatesActions.resumeEducation.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.resumeEducation.fulfilled, (state, action) => {
        state.currentResume = action.payload.data;
      })

      //  resumeCertificates
      .addCase(templatesActions.resumeCertificates.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.resumeCertificates.fulfilled, (state, action) => {
        state.currentResume = action.payload.data;
      })

      //  resumeSkills
      .addCase(templatesActions.resumeSkills.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.resumeSkills.fulfilled, (state, action) => {
        state.currentResume = action.payload.data;
      })

      //  resumeLanguages
      .addCase(templatesActions.resumeLanguages.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.resumeLanguages.fulfilled, (state, action) => {
        state.currentResume = action.payload.data;
      })

      //  resumeTemplate
      .addCase(templatesActions.resumeTemplate.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.resumeTemplate.fulfilled, (state, action) => {
        state.currentResume = action.payload.data;
      })

      //  coverContent
      .addCase(templatesActions.coverContent.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.coverContent.fulfilled, (state, action) => {
        state.currentCover = action.payload.data;
      })

      //  coverTemplate
      .addCase(templatesActions.coverTemplate.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.coverTemplate.fulfilled, (state, action) => {
        state.currentCover = action.payload.data;
      })

      //  resumesUploadPreview
      .addCase(templatesActions.resumesUploadPreview.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.resumesUploadPreview.fulfilled, (state, action) => {
        state.currentResume = action.payload.data;
        state.fixWaiter = false;
      })
      .addCase(templatesActions.resumesUploadPreview.rejected, (state) => {
        state.fixWaiter = false;
      })

      //  coverUploadPreview
      .addCase(templatesActions.coverUploadPreview.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.coverUploadPreview.fulfilled, (state, action) => {
        state.currentCover = action.payload.data;
        state.fixWaiter = false;
      })
      .addCase(templatesActions.coverUploadPreview.rejected, (state) => {
        state.fixWaiter = false;
      })

      // resumesDelete
      .addCase(templatesActions.resumesDelete.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.resumesDelete.fulfilled, (state, action) => {
        const { latest, resume } = action.meta.arg;
        if (!latest) {
          state.resumes = state.resumes.filter((item) => item.id !== resume);
        }
        state.fixWaiter = false;
      })
      .addCase(templatesActions.resumesDelete.rejected, (state) => {
        state.fixWaiter = false;
      })

      // coverDelete
      .addCase(templatesActions.coverDelete.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.coverDelete.fulfilled, (state, action) => {
        const { latest, cover } = action.meta.arg;
        if (!latest) {
          state.covers = state.resumes.filter((item) => item.id !== cover);
        }
        state.fixWaiter = false;
      })
      .addCase(templatesActions.coverDelete.rejected, (state) => {
        state.fixWaiter = false;
      })

      .addCase(templatesActions.getBlogs.pending, (state) => {
        state.blogsWaiter = true;
      })
      .addCase(templatesActions.getBlogs.fulfilled, (state, action) => {
        state.blogs = action.payload.data;
        state.blogsWaiter = false;
      })
      .addCase(templatesActions.getBlogs.rejected, (state) => {
        state.blogsWaiter = false;
      })

      .addCase(templatesActions.getAllBlogs.pending, (state) => {
        state.allBlogsWaiter = true;
      })
      .addCase(templatesActions.getAllBlogs.fulfilled, (state, action) => {
        state.allBlogs = action.payload.data;
        state.allBlogsWaiter = false;
      })
      .addCase(templatesActions.getAllBlogs.rejected, (state) => {
        state.allBlogsWaiter = false;
      })

      .addCase(templatesActions.getBlogsWithSectionsAndPage.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.getBlogsWithSectionsAndPage.fulfilled, (state, action) => {
        state.blogs[action.payload.index].elements = [
          ...state.blogs[action.payload.index].elements,
          ...action.payload.data.data,
        ];
        state.meta = action.payload.data.meta;
        state.fixWaiter = false;
      })
      .addCase(templatesActions.getBlogsWithSectionsAndPage.rejected, (state) => {
        state.fixWaiter = false;
      })

      .addCase(templatesActions.getAllBlogsWithSectionsAndPage.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.getAllBlogsWithSectionsAndPage.fulfilled, (state, action) => {
        state.allBlogs.data = [...state.allBlogs.data, ...action.payload.data.data];
        state.meta = action.payload.data.meta;
        state.fixWaiter = false;
      })
      .addCase(templatesActions.getAllBlogsWithSectionsAndPage.rejected, (state) => {
        state.fixWaiter = false;
      })

      .addCase(templatesActions.getBlog.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(templatesActions.getBlog.fulfilled, (state, action) => {
        state.blog = action.payload.data;
        state.fixWaiter = false;
      })
      .addCase(templatesActions.getBlog.rejected, (state) => {
        state.fixWaiter = false;
      });
  },
});

export const {
  clearErrors,
  setDefaultResume,
  setDefaultCover,
  setShowCvPreview,
  setCurrentResume,
  setCurrentCover,
  setShowCoverPreview,
  setRedirectTo,
  setChooseTemplate,
  setCoverConacts,
  setCoverContent,
  setCvContacts,
  setCvWork,
  setCvHobbies,
  setCvEducation,
  setCvSertification,
  setCvSkills,
  setCvLangs,
} = templatesSlice.actions;
export default templatesSlice.reducer;
