import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { months } from 'src/constants';
import { getDataFormat } from 'src/helpers';

import s from './style.module.scss';

const Mikko = ({ setOnRendered }) => {
  const { currentResume } = useSelector((state) => state.templatesReducer);

  const { image } = currentResume;

  useEffect(() => {
    const img = document.getElementById('avatar');
    if (img) {
      const imgAvatar = document.getElementById('avatar');
      imgAvatar.onload = () => {
        setOnRendered(true);
      };
    } else {
      setOnRendered(true);
    }
  }, []);

  return (
    <>
      {currentResume && (
        <div className={s.c}>
          <div className={s.c__top}>
            <div className={s.c__top__left}>
              {image && (
                <div className={s.avatar}>
                  <img width={110} src={image} alt="avatar" id="avatar" />
                </div>
              )}
              <h1>
                <div>{currentResume.firstName}</div>
                <div>{currentResume.lastName}</div>
                <span>{currentResume.jobTitle}</span>
              </h1>
            </div>

            <div className={s.contacts}>
              <div>
                {currentResume.country.name}, {currentResume.city.name}
                {currentResume.state && `, ${currentResume.state}`}
                {currentResume.zip && `, ${currentResume.zip}`}
              </div>
              <div>{currentResume.phone}</div>
              <div>{currentResume.email}</div>
            </div>
          </div>
          <div className={s.flex}>
            <div className={`section-col ${s.col}`}>
              {currentResume.experiences.length > 0 && (
                <div className={`section ${s.sec}`}>
                  {currentResume.experiences.map((item) => (
                    <div className={`sub-section ${s.sub_sec}`} key={item.id}>
                      <div className={s.title}>Experiences</div>
                      <div className={s.job}>{item.jobTitle}</div>
                      <div className={s.text}>
                        <div className={s.text_upper}>{item.companyName}</div>
                        <div>
                          {getDataFormat(item.startDate)} -{' '}
                          {item.isPresent ? 'Present' : getDataFormat(item.endDate)}
                        </div>
                        <div>
                          {item.country.name} {item.city.name}
                        </div>
                      </div>
                      <div className={s.text2}>
                        {item.responsibilities}
                        {item.achievements}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.hobbies.length > 0 && (
                <div className={`section ${s.sec}`}>
                  <div className={`sub-section ${s.sub_sec}`}>
                    {currentResume.hobbies.map((item) => (
                      <div key={item.id} className={s.item}>
                        <div className={s.title}>Hobbies</div>
                        <div className={s.job}>{item.name}</div>

                        <div className={s.text2}>{item.description}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {currentResume.certificates.length > 0 && (
                <div className={`section ${s.sec}`}>
                  <div className={`sub-section ${s.sub_sec}`}>
                    {currentResume.certificates.map((item) => (
                      <div key={item.id} className={s.item}>
                        <div className={s.title}>Certificates</div>
                        <div className={s.job}>{item.name}</div>

                        <div className={s.text2}>{item.skills}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className={`section-col ${s.col}`}>
              {currentResume.educations.length > 0 && (
                <div className={`section ${s.sec}`}>
                  {currentResume.educations.map((item) => (
                    <div className={`sub-section ${s.sub_sec}`} key={item.id}>
                      <div className={s.title}>Educations</div>
                      <div className={s.job}>{item.universityName}</div>
                      <div className={s.text}>
                        <div className={s.text_upper}>
                          {item.degree.name} {item.field.name}
                        </div>
                        <div>
                          {months[item.graduationMonth].name} {item.graduationYear}
                        </div>
                        <div>
                          {item.country.name}, {item.city.name}
                        </div>
                      </div>
                      <div className={s.text2}>{item.achievements}</div>
                    </div>
                  ))}
                </div>
              )}
              {currentResume.languages.length > 0 && (
                <div className={`section ${s.sec}`}>
                  <div className={`sub-section ${s.sub_sec}`}>
                    {currentResume.languages.map((item, index) => (
                      <div key={item.id} className={s.item}>
                        {index === 0 && <div className={s.title}>Languages</div>}
                        <div className={s.job}>{item.language}</div>
                        <div className={s.text2}>{item.proficiency}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {currentResume.hardSkills.length > 0 && (
                <div className={`section ${s.sec}`}>
                  <div className={`sub-section ${s.sub_sec}`}>
                    {currentResume.hardSkills.map((item, index) => (
                      <div key={item.id} className={s.item2}>
                        {index === 0 && <div className={s.title2}>Hard skills</div>}
                        <div className={s.job}>{item.skill}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {currentResume.softSkills.length > 0 && (
                <div className={`section ${s.sec}`}>
                  <div className={`sub-section ${s.sub_sec}`}>
                    {currentResume.softSkills.map((item, index) => (
                      <div key={item.id} className={s.item2}>
                        {index === 0 && <div className={s.title2}>Soft skills</div>}
                        <div className={s.job}>{item.skill}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Mikko.propTypes = {
  setOnRendered: PropTypes.func.isRequired,
};
export default Mikko;
