import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDataStandart } from 'src/helpers';
import { templatesService } from 'src/services';

export const getResumes = createAsyncThunk(
  'templates/getResumes',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.getResumes(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCovers = createAsyncThunk(
  'templates/getCovers',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.getCovers(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getResume = createAsyncThunk(
  'templates/getResume',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.getResume(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCover = createAsyncThunk(
  'templates/getCover',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.getCover(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCountries = createAsyncThunk(
  'templates/getCountries',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.getCountries(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAllCitiesRelatedToCountry = createAsyncThunk(
  'templates/getAllCitiesRelatedToCountry',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.getAllCitiesRelatedToCountry(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getEducationFields = createAsyncThunk(
  'templates/getEducationFields',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.getEducationFields(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getResumeTemplates = createAsyncThunk(
  'templates/getResumeTemplates',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.getResumeTemplates(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getEducationDegrees = createAsyncThunk(
  'templates/getEducationDegrees',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.getEducationDegrees(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resumeCreate = createAsyncThunk(
  'templates/resumeCreate',
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      if (data.id) {
        formData.append('id', data.id);
      }
      formData.append('firstName', data.firstName);
      formData.append('lastName', data.lastName);
      formData.append('jobTitle', data.jobTitle);
      formData.append('email', data.email);
      formData.append('phone', data.phone);
      formData.append('country', data.country);
      formData.append('city', data.city);
      formData.append('state', data.state);
      formData.append('zip', data.zip);
      formData.append('step', data.step);
      if (data.image) {
        formData.append('image', data.image, 'image.jpg');
      }

      const result = await templatesService.resumeCreate(formData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const coverCreate = createAsyncThunk(
  'templates/coverCreate',
  async (data, { rejectWithValue }) => {
    try {
      const newData = { ...data };
      if (!newData.id) {
        delete newData.id;
      }
      const result = await templatesService.coverCreate(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resumeExperience = createAsyncThunk(
  'templates/resumeExperience',
  async (data, { rejectWithValue }) => {
    try {
      const newData = { ...data };
      newData.experience = newData.experience.map((item) => ({
        ...item,
        country: item.country.id,
        city: item.city.id,
        id: typeof item.id === 'string' ? 0 : item.id,
        startDate: item.startDate ? getDataStandart(item.startDate) : getDataStandart(new Date()),
        endDate: item.endDate ? getDataStandart(item.endDate) : getDataStandart(new Date()),
      }));

      const result = await templatesService.resumeExperience(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resumeHobbies = createAsyncThunk(
  'templates/resumeHobbies',
  async (data, { rejectWithValue }) => {
    try {
      const newData = { ...data };
      newData.hobbies = newData.hobbies.map((item) => ({
        ...item,
        id: typeof item.id === 'string' ? 0 : item.id,
      }));

      newData.hobbies = newData.hobbies.filter((item) => item.name.length > 0);
      const result = await templatesService.resumeHobbies(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resumeEducation = createAsyncThunk(
  'templates/resumeEducation',
  async (data, { rejectWithValue }) => {
    try {
      const newData = { ...data };
      newData.education = newData.education.map((item) => ({
        ...item,
        country: item.country.id,
        city: item.city.id,
        degree: item.degree.id,
        field: item.field.id,
        id: typeof item.id === 'string' ? 0 : item.id,
      }));

      const result = await templatesService.resumeEducation(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resumeCertificates = createAsyncThunk(
  'templates/resumeCertificates',
  async (data, { rejectWithValue }) => {
    try {
      const newData = { ...data };
      newData.certificates = newData.certificates.map((item) => ({
        ...item,
        id: typeof item.id === 'string' ? 0 : item.id,
      }));

      newData.certificates = newData.certificates.filter((item) => item.name.length > 0);
      const result = await templatesService.resumeCertificates(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resumeSkills = createAsyncThunk(
  'templates/resumeSkills',
  async (data, { rejectWithValue }) => {
    try {
      const newData = { ...data };
      newData.hardSkills = newData.hardSkills.map((item) => ({
        ...item,
        id: typeof item.id === 'string' ? 0 : item.id,
      }));
      newData.hardSkills = newData.hardSkills.filter((item) => item.skill);

      newData.softSkills = newData.softSkills.map((item) => ({
        ...item,
        id: typeof item.id === 'string' ? 0 : item.id,
      }));

      newData.softSkills = newData.softSkills.filter((item) => item.skill);

      const result = await templatesService.resumeSkills(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resumeLanguages = createAsyncThunk(
  'templates/resumeLanguages',
  async (data, { rejectWithValue }) => {
    try {
      const newData = { ...data };
      newData.languages = newData.languages.map((item) => ({
        ...item,
        id: typeof item.id === 'string' ? 0 : item.id,
      }));

      newData.languages = newData.languages.filter((item) => item.language);
      newData.languages = newData.languages.filter((item) => item.proficiency);

      const result = await templatesService.resumeLanguages(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resumeTemplate = createAsyncThunk(
  'templates/resumeTemplate',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.resumeTemplate(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const coverContent = createAsyncThunk(
  'templates/coverContent',
  async (data, { rejectWithValue }) => {
    try {
      const newData = { ...data };
      if (!newData.date) {
        delete newData.date;
      }
      const result = await templatesService.coverContent(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCoversTemplates = createAsyncThunk(
  'templates/getCoversTemplates',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.getCoversTemplates(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const coverTemplate = createAsyncThunk(
  'templates/coverTemplate',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.coverTemplate(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resumesUploadPreview = createAsyncThunk(
  'templates/resumesUploadPreview',
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('id', data.id);
      formData.append('preview', data.image, 'image.jpg');
      const result = await templatesService.resumesUploadPreview(formData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const coverUploadPreview = createAsyncThunk(
  'templates/coverUploadPreview',
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('id', data.id);
      formData.append('preview', data.image, 'image.jpg');
      const result = await templatesService.coverUploadPreview(formData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resumesDelete = createAsyncThunk(
  'templates/resumesDelete',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.resumesDelete(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const coverDelete = createAsyncThunk(
  'templates/coverDelete',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.coverDelete(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getBlogs = createAsyncThunk('blog/getBlogs', async (data, { rejectWithValue }) => {
  try {
    const result = await templatesService.getBlogs();

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getBlogsWithSectionsAndPage = createAsyncThunk(
  'blog/getBlogsWithSectionsAndPage',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.getBlogsWithSectionsAndPage(data);

      return { index: data.index, ...result };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAllBlogsWithSectionsAndPage = createAsyncThunk(
  'blog/getAllBlogsWithSectionsAndPage',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.getAllBlogsWithSectionsAndPage(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getBlog = createAsyncThunk('blog/getBlog', async (data, { rejectWithValue }) => {
  try {
    const result = await templatesService.getBlog(data);

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getAllBlogs = createAsyncThunk(
  'blog/getAllBlogs',
  async (data, { rejectWithValue }) => {
    try {
      const result = await templatesService.getAllBlogs();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
