import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import add from 'src/assets/img/create/add.svg';
import remove from 'src/assets/img/create/remove.svg';
import sprite from 'src/assets/sprite.svg';
import { GetPreview } from 'src/components';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { templatesActions } from 'src/store/actions';
import { setCvSkills } from 'src/store/reducers/templates.reducer';
import { v4 as uuidv4 } from 'uuid';

import Sidebar from './sidebar';

const Skills = () => {
  const cvSkills = useSelector((state) => state.templatesReducer.cvSkills);
  const fixWaiter = useSelector((state) => state.templatesReducer.fixWaiter);
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { id, hardSkills, softSkills } = useSelector(
    (state2) => state2.templatesReducer.currentResume,
  );
  const { width } = useWindowDimensions();

  const [preview, setPreview] = useState('');
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (preview) {
      setShowPreview(false);
      const file = new File([preview], 'file');
      dispatch(templatesActions.resumesUploadPreview({ image: file, id })).then(() => {
        navigate(`/my-cv/create/languages/?id=${id}`);
      });
    }
  }, [preview]);

  const handleSubmit = async (values) => {
    await dispatch(templatesActions.resumeSkills(values));
    setShowPreview(true);
  };

  const [cvSkillsState] = useState(cvSkills);

  const formik = useFormik({
    initialValues: cvSkillsState || {
      id: id || '',
      hardSkills: hardSkills.length
        ? hardSkills
        : [
            {
              id: 0,
              skill: '',
            },
          ],
      softSkills: softSkills.length
        ? softSkills
        : [
            {
              id: 0,
              skill: '',
            },
          ],
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    const handleSet = () => {
      if (id) {
        dispatch(setCvSkills(formik.values));
      }
    };

    return handleSet;
  }, [formik.values]);

  const handleAddHardSkill = () => {
    formik.setFieldValue('hardSkills', [
      ...formik.values.hardSkills,
      {
        id: uuidv4(),
        skill: '',
      },
    ]);
  };

  const removeHardSkills = () => {
    formik.setFieldValue('hardSkills', formik.values.hardSkills.slice(0, -1));
  };

  const handleAddSoftSkill = () => {
    formik.setFieldValue('softSkills', [
      ...formik.values.softSkills,
      {
        id: uuidv4(),
        skill: '',
      },
    ]);
  };

  const removeSoftSkills = () => {
    formik.setFieldValue('softSkills', formik.values.softSkills.slice(0, -1));
  };

  return (
    <>
      {showPreview && <GetPreview setPreview={setPreview} />}
      <div className="create__mob">
        <Link to={`/my-cv/certifications/?id=${id}`} type="button" className="create__back">
          <svg width="24" height="24" viewBox="0 0 24 24">
            <use xlinkHref={`${sprite}#arr-back`} />
          </svg>
          {translate('cover_create.back')}
        </Link>

        <button type="button" className="create__mobPreview">
          <svg width="32" height="32" viewBox="0 0 32 32">
            <use xlinkHref={`${sprite}#preview`} />
          </svg>
        </button>
      </div>
      <div className="create__wrap">
        <Sidebar step={6} />
        <div className="create__right">
          <div className="create__title">{translate('cv_create.skills')}</div>
          <form className="create__form" onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
              <div className="create__blocks">
                <div className="create__block">
                  <div className="form-group--list">
                    <div className="label" title={translate('cv_create.skills_title')}>
                      Hard Skills
                      <svg width="18" height="18" viewBox="0 0 18 18">
                        <use xlinkHref={`${sprite}#tooltip`} />
                      </svg>
                    </div>
                    <FieldArray
                      name="hardSkills"
                      render={() => (
                        <>
                          {formik.values.hardSkills.map((item, index) => (
                            <input
                              key={item.id}
                              name={`hardSkills[${index}].skill`}
                              type="text"
                              className="form-control"
                              placeholder="hard skill name"
                              defaultValue={item.skill}
                              onChange={formik.handleChange}
                            />
                          ))}
                        </>
                      )}
                    />
                  </div>
                  {formik.values.hardSkills.length > 1 && (
                    <button type="button" className="create__add mb-1" onClick={removeHardSkills}>
                      <img src={remove} alt="add icon" />
                      {translate('cv_create.remove_skill')}
                    </button>
                  )}
                  <button type="button" className="create__add" onClick={handleAddHardSkill}>
                    <img src={add} alt="add icon" />
                    {translate('cv_create.add_skill')}
                  </button>
                </div>
                <div className="create__block">
                  <div className="form-group--list">
                    <div className="label" title={translate('cv_create.soft_skills_title')}>
                      {translate('cv_create.soft_skills')}
                      <svg width="18" height="18" viewBox="0 0 18 18">
                        <use xlinkHref={`${sprite}#tooltip`} />
                      </svg>
                    </div>
                    <FieldArray
                      name="hardSkills"
                      render={() => (
                        <>
                          {formik.values.softSkills.map((item, index) => (
                            <input
                              key={item.id}
                              name={`softSkills[${index}].skill`}
                              type="text"
                              className="form-control"
                              placeholder={translate('cv_create.soft_skill_placeholder')}
                              defaultValue={item.skill}
                              onChange={formik.handleChange}
                            />
                          ))}
                        </>
                      )}
                    />
                  </div>
                  {formik.values.softSkills.length > 1 && (
                    <button type="button" className="create__add mb-1" onClick={removeSoftSkills}>
                      <img src={remove} alt="add icon" />
                      {translate('cv_create.remove_skill')}
                    </button>
                  )}
                  <button type="button" className="create__add" onClick={handleAddSoftSkill}>
                    <img src={add} alt="add icon" />
                    {translate('cv_create.add_skill')}
                  </button>
                </div>
              </div>
            </FormikProvider>

            <div className="create__sub">
              {width > 565 ? (
                <Link
                  to={`/my-cv/create/certifications?id=${id}`}
                  className="btn btn-outline-primary"
                >
                  {translate('cover_create.back')}
                </Link>
              ) : (
                <Link to={`/my-cv/create/languages/?id=${id}`} className="btn btn-outline-primary">
                  {translate('cover_create.skip_this_step')}
                </Link>
              )}

              <button type="submit" className="btn btn-primary" disabled={fixWaiter}>
                {fixWaiter ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{translate('loading')}</span>
                  </>
                ) : (
                  <>{translate('cover_create.continue')}</>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Skills;
