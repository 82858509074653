import './style.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import * as htmlToImage from 'html-to-image';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import CoverTemplates from 'src/cover-templates';

const Children = ({ setPreview }) => {
  const ref = useRef();
  const [fullImg, setFullImg] = useState(false);
  const [onRendered, setOnRendered] = useState(false);

  useEffect(() => {
    if (onRendered) {
      const view = document.getElementById('view');
      htmlToImage.toJpeg(view, { quality: 0.75, pixelRatio: 1 }).then((url) => {
        const img = new Image();
        img.src = url;

        const c = document.createElement('canvas');
        const ctx = c.getContext('2d');

        img.onload = () => {
          c.width = 794;
          c.height = 1122;
          ctx.drawImage(img, 0, 0);
          c.toBlob(
            (blob) => {
              setFullImg(blob);
            },
            'image/jpeg',
            0.75,
          );
        };
      });
    }
  }, [onRendered]);

  useEffect(() => {
    setPreview(fullImg);
  }, [fullImg]);

  return (
    <div ref={ref} id="view" className="template template--setPreview toPrint showPrint">
      <CoverTemplates setOnRendered={setOnRendered} />
    </div>
  );
};

const GetPreviewCover = ({ setPreview }) =>
  createPortal(<Children setPreview={setPreview} />, document.body);

GetPreviewCover.propTypes = {
  setPreview: PropTypes.func.isRequired,
};
Children.propTypes = {
  setPreview: PropTypes.func.isRequired,
};
export default GetPreviewCover;
