import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import sprite from 'src/assets/sprite.svg';
import { useTranslation } from 'src/hooks';

const DeleteResume = ({ handleDeleteResume, show, handleClose }) => {
  const fixWaiter = useSelector((state) => state.templatesReducer.fixWaiter);
  const { translate } = useTranslation();
  return (
    <Modal show={show} onHide={handleClose} fullscreen="sm-down">
      <Modal.Header>
        <div />
        <button className="modal-closeBtn" type="button" onClick={handleClose}>
          <svg id="close" width="24" height="24" viewBox="0 0 24 24">
            <use xlinkHref={`${sprite}#close`} />
          </svg>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="deleteResume">
          <div className="modal__title">{translate('components.delete_resume')}</div>
          <div className="modal__text">{translate('components.delete_resume_desc')}</div>
          <div className="deleteResume__btns">
            <button type="button" className="btn btn-outline-primary" onClick={handleDeleteResume}>
              {fixWaiter ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{translate('loading')}</span>
                </>
              ) : (
                <>{translate('components.delete')}</>
              )}
            </button>
            <button type="button" className="btn btn-primary" onClick={handleClose}>
              {translate('cancel')}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

DeleteResume.propTypes = {
  handleDeleteResume: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DeleteResume;
