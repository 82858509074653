import './style.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Loader, TopWaiter } from 'src/components';
import { useQuery } from 'src/helpers';
import { templatesActions } from 'src/store/actions';
import { setDefaultResume } from 'src/store/reducers/templates.reducer';

import {
  Certification,
  Contacts,
  Education,
  Hobbies,
  Languages,
  Skills,
  Templates,
  Work,
} from './components';

const CvCreate = () => {
  const waiter = useSelector((state) => state.templatesReducer.waiter);
  const fixWaiter = useSelector((state) => state.templatesReducer.fixWaiter);
  const [defaultWaiter, setDefaultWaiter] = useState(true);
  const query = useQuery();
  const dispatch = useDispatch();
  const id = query.get('id');

  const handleDispatchResume = async () => {
    if (id) {
      await dispatch(templatesActions.getResume(id));
    } else {
      await dispatch(setDefaultResume());
    }
    setDefaultWaiter(false);
  };

  useEffect(() => {
    dispatch(templatesActions.getCountries());
    dispatch(templatesActions.getEducationFields());
    dispatch(templatesActions.getEducationDegrees());
    dispatch(templatesActions.getResumeTemplates());
    handleDispatchResume();
  }, []);

  return (
    <>
      <TopWaiter isActive={fixWaiter} />
      <div className="create">
        <div className="wrap content">
          {waiter && defaultWaiter ? (
            <Loader />
          ) : (
            <Routes>
              <Route path="/" element={<Contacts />} />
              {id && (
                <>
                  <Route path="/work/" element={<Work />} />
                  <Route path="/hobbies/" element={<Hobbies />} />
                  <Route path="/education/" element={<Education />} />
                  <Route path="/certifications/" element={<Certification />} />
                  <Route path="/skills/" element={<Skills />} />
                  <Route path="/languages/" element={<Languages />} />
                  <Route path="/templates/" element={<Templates />} />
                </>
              )}
            </Routes>
          )}
        </div>
      </div>
    </>
  );
};

export default CvCreate;
