import './style.scss';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  BlackAndWhite,
  BlackAndWhiteMinimalist,
  BlackAndWhiteProfessional,
  BlackModern,
  BlueProfesional,
  BrightBlue,
  BrownDigital,
  Cari,
  CorporateGrey,
  DarkGreen,
  Eero,
  ElegantMinimalist,
  GreenAndGrey,
  GreyAndWhiteMinimal,
  GreyAndWhiteModern,
  GreyClean,
  GreySimple,
  Laura,
  Maija,
  Mikko,
  Minna,
  ModernWhite,
  NeutralMinimalist,
  OrangeSimple,
  PinkSimple,
  PinkSimpleProfessional,
  Profesional,
  RedAbstract,
  Saara,
  Sakari,
  SimpleBlue,
  Susanna,
  Terhi,
  WhiteSimple,
  WhiteSimpleStudent,
} from './components';

const CvTemplates = ({ setOnRendered, setOverHeight }) => {
  const templateId = useSelector((state) =>
    state.templatesReducer.currentResume.template
      ? state.templatesReducer.currentResume.template.id
      : 1,
  );

  const getTemplate = () => {
    switch (templateId) {
      case 1:
        return <Cari setOnRendered={setOnRendered} />;
      case 2:
        return <Eero setOnRendered={setOnRendered} />;
      case 3:
        return <Laura setOnRendered={setOnRendered} />;
      case 4:
        return <Maija setOnRendered={setOnRendered} />;
      case 5:
        return <Mikko setOnRendered={setOnRendered} />;
      case 6:
        return <Minna setOnRendered={setOnRendered} />;
      case 7:
        return <Saara setOnRendered={setOnRendered} setOverHeight={setOverHeight} />;
      case 8:
        return <Sakari setOnRendered={setOnRendered} />;
      case 9:
        return <Susanna setOnRendered={setOnRendered} />;
      case 10:
        return <Terhi setOnRendered={setOnRendered} />;
      case 11:
        return <BlackAndWhite setOnRendered={setOnRendered} />;
      case 12:
        return <BlackAndWhiteMinimalist setOnRendered={setOnRendered} />;
      case 13:
        return <BlackAndWhiteProfessional setOnRendered={setOnRendered} />;
      case 14:
        return <BlackModern setOnRendered={setOnRendered} />;
      case 15:
        return <BlueProfesional setOnRendered={setOnRendered} />;
      case 16:
        return <BrightBlue setOnRendered={setOnRendered} />;
      case 17:
        return <BrownDigital setOnRendered={setOnRendered} />;
      case 18:
        return <CorporateGrey setOnRendered={setOnRendered} />;
      case 19:
        return <DarkGreen setOnRendered={setOnRendered} />;
      case 20:
        return <ElegantMinimalist setOnRendered={setOnRendered} />;
      case 21:
        return <GreenAndGrey setOnRendered={setOnRendered} />;
      case 22:
        return <GreyAndWhiteMinimal setOnRendered={setOnRendered} />;
      case 23:
        return <GreyAndWhiteModern setOnRendered={setOnRendered} />;
      case 24:
        return <GreyClean setOnRendered={setOnRendered} />;
      case 25:
        return <GreySimple setOnRendered={setOnRendered} />;
      case 26:
        return <ModernWhite setOnRendered={setOnRendered} />;
      case 27:
        return <NeutralMinimalist setOnRendered={setOnRendered} />;
      case 28:
        return <OrangeSimple setOnRendered={setOnRendered} />;
      case 29:
        return <PinkSimple setOnRendered={setOnRendered} />;
      case 30:
        return <PinkSimpleProfessional setOnRendered={setOnRendered} />;
      case 31:
        return <Profesional setOnRendered={setOnRendered} />;
      case 32:
        return <RedAbstract setOnRendered={setOnRendered} />;
      case 33:
        return <SimpleBlue setOnRendered={setOnRendered} />;
      case 34:
        return <WhiteSimple setOnRendered={setOnRendered} />;
      case 35:
        return <WhiteSimpleStudent setOnRendered={setOnRendered} />;
      default:
        return <Cari setOnRendered={setOnRendered} />;
    }
  };

  return getTemplate();
};

CvTemplates.propTypes = {
  setOnRendered: PropTypes.func.isRequired,
  setOverHeight: PropTypes.func.isRequired,
};
export default CvTemplates;
