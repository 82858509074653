import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FogotPass, SignIn, SignSoc, SignUp } from 'src/components';
import { setRedirectToAfterAuth } from 'src/store/reducers/auth.reducer';
import { setChooseTemplate } from 'src/store/reducers/templates.reducer';

const AuthWidthTemplate = ({ content, to, template }) => {
  const ifAuth = !!useSelector((state) => state.authReducer.access_token);
  const dispatch = useDispatch();
  const redirectTo = to === '/my-cv/create/' ? 'cv' : 'cover';

  // foggot
  const [showFoggot, setShowFoggot] = useState(false);
  const handleCloseFoggot = () => setShowFoggot(false);
  const handleShowFoggot = () => setShowFoggot(true);

  // signIn
  const [showSignIn, setShowSignIn] = useState(false);
  const handleCloseSignIn = () => setShowSignIn(false);
  const handleShowSignIn = () => setShowSignIn(true);

  // signIn
  const [showSignUp, setShowSignUp] = useState(false);
  const handleCloseSignUp = () => setShowSignUp(false);
  const handleShowSignUp = () => setShowSignUp(true);

  // signSoc
  const [showSignSoc, setShowSignSoc] = useState(false);
  const handleCloseSignSoc = () => setShowSignSoc(false);
  const handleShowSignSoc = () => setShowSignSoc(true);
  return (
    <>
      {ifAuth ? (
        <Link
          to={to}
          className="btn btn-primary s1__link"
          onClick={() => dispatch(setChooseTemplate(template))}
        >
          {content}
        </Link>
      ) : (
        <button
          type="button"
          className="btn btn-primary s1__link"
          onClick={() => {
            dispatch(setChooseTemplate(template));
            dispatch(setRedirectToAfterAuth(redirectTo));
            handleShowSignSoc();
          }}
        >
          {content}
        </button>
      )}

      {showSignIn && (
        <SignIn
          show={showSignIn}
          handleClose={handleCloseSignIn}
          handleShowSignUp={handleShowSignUp}
          handleShowFoggot={handleShowFoggot}
        />
      )}

      {showSignUp && (
        <SignUp
          show={showSignUp}
          handleClose={handleCloseSignUp}
          handleShowSignIn={handleShowSignIn}
        />
      )}

      {showSignSoc && (
        <SignSoc
          show={showSignSoc}
          handleClose={handleCloseSignSoc}
          handleShowSignUp={handleShowSignUp}
          handleShowSignIn={handleShowSignIn}
        />
      )}

      {showFoggot && (
        <FogotPass
          show={showFoggot}
          handleClose={handleCloseFoggot}
          handleShowSignIn={handleShowSignIn}
        />
      )}
    </>
  );
};

AuthWidthTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  template: PropTypes.number.isRequired,
};

export default AuthWidthTemplate;
