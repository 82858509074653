import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import sprite from 'src/assets/sprite.svg';
import { GetPreviewCover } from 'src/components';
import { useQuery } from 'src/helpers';
import { useTranslation } from 'src/hooks';
import { templatesActions } from 'src/store/actions';
import { setHaveCover } from 'src/store/reducers/auth.reducer';
import {
  setChooseTemplate,
  setCoverConacts,
  setRedirectTo,
} from 'src/store/reducers/templates.reducer';

import Sidebar from './sidebar';

const Contacts = () => {
  const fixWaiter = useSelector((state) => state.templatesReducer.fixWaiter);
  const coverContacts = useSelector((state) => state.templatesReducer.coverContacts);
  const queryId = useQuery().get('id');
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const countries = useSelector((state) => state.templatesReducer.countries);
  const { id, firstName, lastName, email, phone, city, country, state, zip, jobTitle } =
    useSelector((state2) => state2.templatesReducer.currentCover);
  const [preview, setPreview] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [positionData, setPositionData] = useState({});
  const [cities, setCities] = useState([]);
  const citiesOptions = cities.map((item) => ({ value: item.id, label: item.name }));
  const [countryWaiter, setCountryWaiter] = useState(true);
  const { citiesWaiter } = useSelector((state2) => state2.templatesReducer);
  const user = useSelector((state3) => state3.authReducer.user);
  const prefilFirstName = user.name.split(' ')[0] || '';
  const prefilLastName = user.name.split(' ')[1] || '';
  const chooseTemplate = useSelector((state2) => state2.templatesReducer.chooseTemplate);
  const { subscription } = useSelector((state2) => state2.authReducer.user);
  const haveCover = useSelector((state2) => state2.authReducer.user.haveCover);

  useEffect(() => {
    if (!subscription && haveCover && !id) {
      dispatch(setRedirectTo(`/my-covers/create/`));
      navigate('/choose/');
    }
  }, []);

  useEffect(() => {
    if (preview) {
      setShowPreview(false);
      const file = new File([preview], 'file');
      dispatch(templatesActions.coverUploadPreview({ image: file, id })).then(() => {
        navigate(`/my-covers/create/content/?id=${id}`);
      });
    }
  }, [preview]);

  const handleSubmit = async (values) => {
    const res = await dispatch(templatesActions.coverCreate(values));
    if (chooseTemplate) {
      await dispatch(
        templatesActions.coverTemplate({ id: res.payload.data.id, template: chooseTemplate }),
      );
    }
    dispatch(setHaveCover(true));
    setShowPreview(true);
  };

  const [coverContactsState] = useState(coverContacts);

  const formik = useFormik({
    initialValues: coverContactsState || {
      id: id || '',
      firstName: firstName || prefilFirstName || '',
      lastName: lastName || prefilLastName || '',
      email: email || '',
      phone: phone || '',
      jobTitle: jobTitle || '',
      country: (country && country.id) || (countries[0] && countries[0].id) || '1',
      city: (city && city.id) || '1',
      state: state || '',
      zip: zip || '',
      step: 2,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (id !== '') {
      dispatch(setChooseTemplate(0));
    }
  }, [id]);

  useEffect(() => {
    const handleSet = () => {
      if (id && queryId) {
        dispatch(setCoverConacts(formik.values));
      }
    };

    return handleSet;
  }, [formik.values]);

  const handleCitiesRelatedToCountry = async () => {
    if (!countryWaiter) {
      const res = await dispatch(
        templatesActions.getAllCitiesRelatedToCountry({ id: formik.values.country }),
      );

      setCities(res.payload.data);
      if (!city) {
        formik.setFieldValue('city', res.payload.data[0].id);
      }
    }
  };

  useEffect(() => {
    if (countries.length && formik.values.country) {
      handleCitiesRelatedToCountry();
    }
  }, [formik.values.country, countryWaiter]);

  useEffect(() => {
    if (!!positionData.country && countries.length > 0) {
      const defaultCountryLocal = countries.filter((item) => item.name === positionData.country)[0];

      if (defaultCountryLocal && !country) {
        formik.setFieldValue('country', defaultCountryLocal.id);

        const defaultCityLocal = cities.filter((item) => item.name === positionData.city)[0];
        if (defaultCityLocal && !city) {
          formik.setFieldValue('city', defaultCityLocal.id);
        }
      }
      setCountryWaiter(false);
    }
  }, [positionData, countries]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.coords.latitude}&lon=${position.coords.longitude}`,
        );
        const data = await response.json();
        setPositionData({
          country: data.address.country,
          city: data.address?.district ? data.address?.district.replace(/\sRaion\b/g, '') : '',
        });
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          setCountryWaiter(false);
        }
      },
    );
  }, []);

  return (
    <>
      {showPreview && <GetPreviewCover setPreview={setPreview} />}
      <div className="create__mob">
        <Link to="/my-covers/" type="button" className="create__back">
          <svg width="24" height="24" viewBox="0 0 24 24">
            <use xlinkHref={`${sprite}#arr-back`} />
          </svg>
          {translate('cover_create.back')}
        </Link>

        <button type="button" className="create__mobPreview">
          <svg width="32" height="32" viewBox="0 0 32 32">
            <use xlinkHref={`${sprite}#preview`} />
          </svg>
        </button>
      </div>
      <div className="create__wrap">
        <Sidebar step={1} />
        <div className="create__right">
          <div className="create__title">{translate('cover_create.contact_information')}</div>
          <form className="create__form" onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.first_name')}</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="John"
                    name="firstName"
                    defaultValue={formik.values.firstName}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.last_name')}</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last name"
                    name="lastName"
                    defaultValue={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.email')}</div>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="example@example.com"
                    name="email"
                    defaultValue={formik.values.email}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.phone_number')}</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="+370 123 456 78"
                    name="phone"
                    defaultValue={formik.values.phone}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="label">{translate('cover_create.job_title')}</div>
              <input
                type="text"
                className="form-control"
                placeholder={translate('cover_create.job_title_placeholder')}
                name="jobTitle"
                defaultValue={formik.values.jobTitle}
                onChange={formik.handleChange}
              />
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.country')}</div>
                  {countryWaiter ? (
                    <input
                      type="text"
                      value={translate('loading')}
                      className="form-control"
                      readOnly
                    />
                  ) : (
                    <select
                      className="form-control"
                      value={formik.values.country}
                      name="country"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {countries.length &&
                        countries.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  )}
                </div>
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.city')}</div>

                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={
                      citiesOptions.filter(
                        (item) => String(item.value) === String(formik.values.city),
                      )[0]
                    }
                    isDisabled={citiesWaiter}
                    isLoading={citiesWaiter}
                    isSearchable
                    name="city"
                    options={citiesOptions}
                    onChange={(e) => formik.setFieldValue('city', e.value)}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.state')}</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={translate('cover_create.state_placeholder')}
                    name="state"
                    defaultValue={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.zip_code')}</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="LV-2167"
                    name="zip"
                    defaultValue={formik.values.zip}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>

            <div className="create__sub">
              <button type="submit" className="btn btn-primary" disabled={fixWaiter}>
                {fixWaiter ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{translate('loading')}</span>
                  </>
                ) : (
                  <>{translate('cover_create.continue')}</>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contacts;
