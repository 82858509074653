import 'src/style/App.scss';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  BigWaiter,
  Footer,
  GoogleModule,
  Header,
  HeaderAc,
  Preview,
  PreviewCover,
  TopWaiter,
} from 'src/components';
import {
  Account,
  Blog,
  ChoosePage,
  Cover,
  CoverCreate,
  CoverExamples,
  CoverTemplates,
  Cv,
  CvCreate,
  CvExamples,
  CvTemplates,
  Dashboard,
  Home,
  MyCovers,
  MyCv,
  PageList,
} from 'src/pages';
import { authActions } from 'src/store/actions';

const App = () => {
  const dispatch = useDispatch();
  const showCvPreview = useSelector((state) => state.templatesReducer.showCvPreview);
  const showCoverPreview = useSelector((state) => state.templatesReducer.showCoverPreview);
  const waiter = useSelector((state) => state.authReducer.waiter);
  const fixWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const ifAuth = !!useSelector((state) => state.authReducer.access_token);
  const location = useLocation();
  useEffect(() => {
    if (location.hash !== '#faq') {
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (ifAuth) {
      dispatch(authActions.getMe());
    }
  }, []);

  useEffect(() => {
    if (showCvPreview) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [showCvPreview]);

  useEffect(() => {
    if (showCoverPreview) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [showCoverPreview]);

  return (
    <>
      {waiter ? (
        <BigWaiter />
      ) : (
        <>
          {!ifAuth && !fixWaiter && <GoogleModule />}

          <TopWaiter isActive={fixWaiter} />
          <div className="app">
            <Routes>
              <Route path="/*" element={ifAuth ? <HeaderAc /> : <Header />} />
              <Route path="/" element={<Header />} />
              <Route path="/reset-password/:token/:email" element={<Header />} />
              <Route path="/cv" element={<Header />} />
              <Route path="/cv-examples" element={<Header />} />
              <Route path="/cv-templates" element={<Header />} />
              <Route path="/cover" element={<Header />} />
              <Route path="/cover-examples" element={<Header />} />
              <Route path="/cover-templates" element={<Header />} />
              <Route path="/blog" element={<Header />} />
            </Routes>

            <Routes>
              <Route path="/*" element={<Home />} />
              <Route path="/cv" element={<Cv />} />
              <Route path="/cv-examples" element={<CvExamples />} />
              <Route path="/cv-templates" element={<CvTemplates />} />
              <Route path="/cover" element={<Cover />} />
              <Route path="/cover-examples" element={<CoverExamples />} />
              <Route path="/cover-templates" element={<CoverTemplates />} />
              <Route path="/blog/*" element={<Blog />} />

              <Route path="/page-list/*" element={<PageList />} />

              {ifAuth && (
                <>
                  <Route path="/account/*" element={<Account />} />
                  <Route path="/dashboard/*" element={<Dashboard />} />
                  <Route path="/my-cv/*" element={<MyCv />} />
                  <Route path="/my-cv/create/*" element={<CvCreate />} />
                  <Route path="/my-covers/*" element={<MyCovers />} />
                  <Route path="/my-covers/create/*" element={<CoverCreate />} />
                  <Route path="/choose/*" element={<ChoosePage />} />
                </>
              )}
            </Routes>

            <Footer />
          </div>
        </>
      )}

      {showCvPreview && <Preview />}
      {showCoverPreview && <PreviewCover />}
    </>
  );
};

export default App;
