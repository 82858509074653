import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import s from './style.module.scss';

const Mikko = ({ setOnRendered }) => {
  const { currentCover } = useSelector((state) => state.templatesReducer);

  useEffect(() => {
    setOnRendered(true);
  });

  return (
    <>
      {currentCover && (
        <div className={s.c}>
          <div className="section-col">
            <div className="section">
              <div className="sub-section">
                <div className={s.c__top}>
                  <h1>
                    {currentCover.firstName} {currentCover.lastName}
                    <div className={s.c__job}>{currentCover.jobTitle}</div>
                  </h1>
                  <div className={s.contacts}>
                    <div>
                      {currentCover.country.name}, {currentCover.city.name}
                      {currentCover.state && `, ${currentCover.state}`}
                      {currentCover.zip && `, ${currentCover.zip}`}
                    </div>
                    <div>{currentCover.phone}</div>
                    <div>{currentCover.email}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section">
              <div className={s.main}>
                <div className={`${s.main__row} sub-section`}>
                  <div className={s.main__title__top}>JOB APPLICATION</div>
                  <div className={s.main__title}>
                    {currentCover.jobTitle} <span>{currentCover.date}</span>
                  </div>
                  <div className="pb-4" />
                </div>

                {currentCover.greeting && (
                  <div className={`${s.main__row} sub-section`}>
                    <div className={s.main__text}>{currentCover.greeting}</div>
                  </div>
                )}

                {currentCover.openingParagraph && (
                  <div className={`${s.main__row} sub-section`}>
                    <div className={s.main__text}>{currentCover.openingParagraph}</div>
                  </div>
                )}

                {currentCover.secondParagraph && (
                  <div className={`${s.main__row} sub-section`}>
                    <div className={s.main__text}>{currentCover.secondParagraph}</div>
                  </div>
                )}

                {currentCover.thirdParagraph && (
                  <div className={`${s.main__row} sub-section`}>
                    <div className={s.main__text}>{currentCover.thirdParagraph}</div>
                  </div>
                )}

                {currentCover.signature && (
                  <div className={`${s.main__row} sub-section`}>
                    <div className={s.main__text}>{currentCover.signature}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Mikko.propTypes = {
  setOnRendered: PropTypes.func.isRequired,
};
export default Mikko;
