import { createAsyncThunk } from '@reduxjs/toolkit';
import { authService } from 'src/services';

export const signIn = createAsyncThunk('auth/signIn', async (data, { rejectWithValue }) => {
  try {
    const result = await authService.signIn(data);

    if (result.data.token) {
      localStorage.setItem('userToken', result.data.token);
      localStorage.setItem('blockTime', +new Date() + result.data.time * 1000);
    }

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const signInGoogle = createAsyncThunk(
  'auth/signInGoogle',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.signInGoogle(data);

      if (result.data.token) {
        localStorage.setItem('userToken', result.data.token);
        localStorage.setItem('blockTime', +new Date() + result.data.time * 1000);
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const signInGoogleModule = createAsyncThunk(
  'auth/signInGoogleModule',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.signInGoogleModule(data);

      if (result.data.token) {
        localStorage.setItem('userToken', result.data.token);
        localStorage.setItem('blockTime', +new Date() + result.data.time * 1000);
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const signInFacebook = createAsyncThunk(
  'auth/signInFacebook',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.signInFacebook(data);

      if (result.data.token) {
        localStorage.setItem('userToken', result.data.token);
        localStorage.setItem('blockTime', +new Date() + result.data.time * 1000);
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const signUp = createAsyncThunk('auth/signUp', async (data, { rejectWithValue }) => {
  try {
    const result = await authService.signUp(data);

    if (result.data.token) {
      localStorage.setItem('userToken', result.data.token);
      localStorage.setItem('blockTime', +new Date() + result.data.time * 1000);
    }

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const logout = createAsyncThunk('auth/logout', async (data, { rejectWithValue }) => {
  try {
    const result = await authService.logout(data);

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.forgotPassword(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const ressetPassword = createAsyncThunk(
  'auth/ressetPassword',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.ressetPassword(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getMe = createAsyncThunk('auth/getMe', async (data, { rejectWithValue }) => {
  try {
    const result = await authService.getMe();

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getMeRefresh = createAsyncThunk(
  'auth/getMeRefresh',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.getMe();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getUserNotifications = createAsyncThunk(
  'user/notifications',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.getUserNotifications();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const markNotificationsAsRead = createAsyncThunk(
  'user/markNotificationsAsRead',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.markNotificationsAsRead();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const checkIfUserHaveUnReadNotification = createAsyncThunk(
  'user/checkNotifications',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.checkIfUserHaveUnReadNotification();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAccountSubscriptionData = createAsyncThunk(
  'account/settings',
  async (data, { rejectWithValue }) => {
    try {
      return await authService.getAccountSubscriptionData();
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const changeAccountEmail = createAsyncThunk(
  'account/change/email',
  async (data, { rejectWithValue }) => {
    try {
      return await authService.changeAccountEmail(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const changeAccountPassword = createAsyncThunk(
  'account/change/password',
  async (data, { rejectWithValue }) => {
    try {
      return await authService.changeAccountPassword(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createSubscription = createAsyncThunk(
  'subscription/create',
  async (data, { rejectWithValue }) => {
    try {
      return await authService.createSubscription(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getInformationAboutSubscription = createAsyncThunk(
  'subscription/data',
  async (data, { rejectWithValue }) => {
    try {
      return await authService.getInformationAboutSubscription();
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createPaymentIntent = createAsyncThunk(
  'subscription/createPaymentIntent',
  async (data, { rejectWithValue }) => {
    try {
      return await authService.createPaymentIntent();
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateSubscription = createAsyncThunk(
  'subscription/updateSubscription',
  async (data, { rejectWithValue }) => {
    try {
      return await authService.updateSubscription(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const cancelSubscription = createAsyncThunk(
  'subscription/cancelSubscription',
  async (data, { rejectWithValue }) => {
    try {
      return await authService.cancelSubscription();
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getDataSubscription = createAsyncThunk(
  'subscription/getDataSubscription',
  async (data, { rejectWithValue }) => {
    try {
      return await authService.getDataSubscription();
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
