import './style.scss';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  Cari,
  Eero,
  Laura,
  Maija,
  Mikko,
  Minna,
  Saara,
  Sakari,
  Susanna,
  Terhi,
} from './components';

const CoverTemplates = ({ setOnRendered }) => {
  const templateId = useSelector((state) =>
    state.templatesReducer.currentCover.template
      ? state.templatesReducer.currentCover.template.id
      : 1,
  );

  const getTemplate = () => {
    switch (templateId) {
      case 1:
        return <Cari setOnRendered={setOnRendered} />;
      case 2:
        return <Eero setOnRendered={setOnRendered} />;
      case 3:
        return <Laura setOnRendered={setOnRendered} />;
      case 4:
        return <Maija setOnRendered={setOnRendered} />;
      case 5:
        return <Mikko setOnRendered={setOnRendered} />;
      case 6:
        return <Minna setOnRendered={setOnRendered} />;
      case 7:
        return <Saara setOnRendered={setOnRendered} />;
      case 8:
        return <Sakari setOnRendered={setOnRendered} />;
      case 9:
        return <Susanna setOnRendered={setOnRendered} />;
      case 10:
        return <Terhi setOnRendered={setOnRendered} />;

      default:
        return <Cari setOnRendered={setOnRendered} />;
    }
  };

  return getTemplate();
};

CoverTemplates.propTypes = {
  setOnRendered: PropTypes.func.isRequired,
};
export default CoverTemplates;
