export const constants = {
  product: {
    days: {
      id: 'price_1MyudrEMguyAtbevAdQReZcY',
    },
    weeks: {
      id: 'price_1MvK8KEMguyAtbev2r8OhNSj',
    },
    year: {
      id: 'price_1MvK8KEMguyAtbevi504VPQq',
    },
  },
  stripe: {
    sk: 'pk_test_51MtAzZEMguyAtbevq1CGTSTcKdWTZMTYj1QQNMI7x8b2F2GdGuRB8TuULxX8KuIBAuhjdzN2qXekVa5uzu4lq9KR005Ejwh5Yh',
    sp: 'sk_test_51MtAzZEMguyAtbevU0OjvWXfXTCcFRL1Vtoz8sYSeNXkvWIFK8N2iG4ctFgaEcIXGmNYBLKjR0kHG1gJVxTEzqLM005jXoMq5N',
  },
};

export const months = [
  { id: 1, name: 'January' },
  { id: 2, name: 'February' },
  { id: 3, name: 'March' },
  { id: 4, name: 'April' },
  { id: 5, name: 'May' },
  { id: 6, name: 'June' },
  { id: 7, name: 'July' },
  { id: 8, name: 'August' },
  { id: 9, name: 'September' },
  { id: 10, name: 'October' },
  { id: 11, name: 'November' },
  { id: 12, name: 'December' },
];

export const languagesLevel = [
  { id: 2, name: 'A1 Beginner' },
  { id: 3, name: 'A2 Elementary' },
  { id: 4, name: 'B1 Intermediate' },
  { id: 5, name: 'B2 Upper intermediate' },
  { id: 6, name: 'C1 Advanced' },
  { id: 7, name: 'C2 Mastery or proficiency' },
];

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const jobTitles = [
  { id: 1, name: 'Software Engineer' },
  { id: 2, name: 'Data Analyst' },
  { id: 3, name: 'Marketing Manager' },
  { id: 4, name: 'Project Manager' },
  { id: 5, name: 'Graphic Designer' },
  { id: 6, name: 'Accountant' },
  { id: 7, name: 'Sales Representative' },
  { id: 8, name: 'Human Resources Manager' },
  { id: 9, name: 'Web Developer' },
  { id: 10, name: 'Product Manager' },
  { id: 11, name: 'Customer Service Representative' },
  { id: 12, name: 'Financial Analyst' },
  { id: 13, name: 'Nurse' },
  { id: 14, name: 'Electrician' },
  { id: 15, name: 'Mechanical Engineer' },
  { id: 16, name: 'Teacher' },
  { id: 17, name: 'Content Writer' },
  { id: 18, name: 'Architect' },
  { id: 19, name: 'Lawyer' },
  { id: 20, name: 'Chef' },
  { id: 21, name: 'Data Scientist' },
  { id: 22, name: 'HR Specialist' },
  { id: 23, name: 'IT Support Specialist' },
  { id: 24, name: 'Business Analyst' },
  { id: 25, name: 'Pharmacist' },
  { id: 26, name: 'Civil Engineer' },
  { id: 27, name: 'Operations Manager' },
  { id: 28, name: 'Social Media Manager' },
  { id: 29, name: 'Financial Advisor' },
  { id: 30, name: 'Marketing Coordinator' },
  { id: 31, name: 'Medical Doctor' },
  { id: 32, name: 'Dentist' },
  { id: 33, name: 'Electrician Technician' },
  { id: 34, name: 'Network Administrator' },
  { id: 35, name: 'Interior Designer' },
  { id: 36, name: 'Customer Support Specialist' },
  { id: 37, name: 'Software Developer' },
  { id: 38, name: 'Art Director' },
  { id: 39, name: 'Sales Manager' },
  { id: 40, name: 'Public Relations Specialist' },
  { id: 41, name: 'Mechanic' },
  { id: 42, name: 'Physical Therapist' },
  { id: 43, name: 'Legal Assistant' },
  { id: 44, name: 'Executive Assistant' },
  { id: 45, name: 'Graphic Artist' },
  { id: 46, name: 'Content Manager' },
  { id: 47, name: 'Construction Manager' },
  { id: 48, name: 'Research Scientist' },
  { id: 49, name: 'HR Coordinator' },
  { id: 50, name: 'Quality Assurance Analyst' },
  { id: 51, name: 'Pharmacy Technician' },
  { id: 52, name: 'Architectural Designer' },
  { id: 53, name: 'Project Coordinator' },
  { id: 54, name: 'Web Designer' },
  { id: 55, name: 'Marketing Assistant' },
  { id: 56, name: 'Sales Associate' },
  { id: 57, name: 'Accounting Clerk' },
  { id: 58, name: 'Nursing Assistant' },
  { id: 59, name: 'Mechanical Technician' },
  { id: 60, name: 'Math Teacher' },
  { id: 61, name: 'Copywriter' },
  { id: 62, name: 'Carpenter' },
  { id: 63, name: 'Biomedical Engineer' },
  { id: 64, name: 'Event Planner' },
  { id: 65, name: 'Investment Analyst' },
  { id: 66, name: 'Public Health Specialist' },
  { id: 67, name: 'Data Entry Specialist' },
  { id: 68, name: 'Medical Assistant' },
  { id: 69, name: 'Veterinarian' },
  { id: 70, name: 'Electrical Engineer' },
  { id: 71, name: 'Marketing Director' },
  { id: 72, name: 'Technical Support Specialist' },
  { id: 73, name: 'UX Designer' },
  { id: 74, name: 'Financial Planner' },
  { id: 75, name: 'Elementary School Teacher' },
  { id: 76, name: 'Customer Service Manager' },
  { id: 77, name: 'Software Tester' },
  { id: 78, name: 'Graphic Designer' },
  { id: 79, name: 'Art Teacher' },
  { id: 80, name: 'Software Architect' },
  { id: 81, name: 'Project Engineer' },
  { id: 82, name: 'Data Engineer' },
  { id: 83, name: 'Digital Marketing Specialist' },
  { id: 84, name: 'Product Designer' },
  { id: 85, name: 'Customer Support Manager' },
  { id: 86, name: 'Legal Counsel' },
  { id: 87, name: 'Chef de Cuisine' },
  { id: 88, name: 'Pharmaceutical Sales Representative' },
  { id: 89, name: 'Civil Engineering Technician' },
  { id: 90, name: 'Retail Manager' },
  { id: 91, name: 'Research Analyst' },
  { id: 92, name: 'HR Director' },
  { id: 93, name: 'Quality Control Inspector' },
  { id: 94, name: 'IT Manager' },
  { id: 95, name: 'Marketing Analyst' },
  { id: 96, name: 'Medical Laboratory Technician' },
  { id: 97, name: 'Emergency Room Nurse' },
  { id: 98, name: 'IT Technician' },
  { id: 99, name: 'Interior Decorator' },
  { id: 100, name: 'Customer Success Specialist' },
  { id: 101, name: 'Lead Software Engineer' },
  { id: 102, name: 'Data Security Analyst' },
  { id: 103, name: 'Brand Manager' },
  { id: 104, name: 'Financial Consultant' },
  { id: 105, name: 'Nurse Practitioner' },
  { id: 106, name: 'Dental Hygienist' },
  { id: 107, name: 'HVAC Technician' },
  { id: 108, name: 'Network Engineer' },
  { id: 109, name: 'Urban Planner' },
  { id: 110, name: 'Customer Service Supervisor' },
  { id: 111, name: 'Graphic Illustrator' },
  { id: 112, name: 'Content Creator' },
  { id: 113, name: 'Construction Worker' },
  { id: 114, name: 'Biomedical Scientist' },
  { id: 115, name: 'Event Coordinator' },
  { id: 116, name: 'Investment Banker' },
  { id: 117, name: 'Public Relations Manager' },
  { id: 118, name: 'Data Analyst' },
  { id: 119, name: 'Bank Teller' },
  { id: 120, name: 'Medical Technologist' },
  { id: 121, name: 'Pharmacy Assistant' },
  { id: 122, name: 'Chemical Engineer' },
  { id: 123, name: 'Elementary Teacher' },
  { id: 124, name: 'Real Estate Agent' },
  { id: 125, name: 'Fitness Trainer' },
  { id: 126, name: 'Psychologist' },
  { id: 127, name: 'Social Worker' },
  { id: 128, name: 'Content Strategist' },
  { id: 129, name: 'Interior Decorator' },
  { id: 130, name: 'Database Administrator' },
  { id: 131, name: 'User Experience Designer' },
  { id: 132, name: 'Logistics Manager' },
  { id: 133, name: 'Financial Planner' },
  { id: 134, name: 'Network Security Specialist' },
  { id: 135, name: 'Quality Assurance Manager' },
  { id: 136, name: 'Educational Consultant' },
  { id: 137, name: 'Software Quality Engineer' },
  { id: 138, name: 'Chemist' },
  { id: 139, name: 'Loan Officer' },
  { id: 140, name: 'Human Resources Specialist' },
  { id: 141, name: 'IT Project Manager' },
  { id: 142, name: 'Sales Director' },
  { id: 143, name: 'Brand Ambassador' },
  { id: 144, name: 'Dental Assistant' },
  { id: 145, name: 'Medical Secretary' },
  { id: 146, name: 'Environmental Scientist' },
  { id: 147, name: 'Mechanical Designer' },
  { id: 148, name: 'Public Speaker' },
  { id: 149, name: 'Legal Secretary' },
  { id: 150, name: 'Sales Analyst' },
  { id: 151, name: 'Travel Agent' },
  { id: 152, name: 'Event Planner' },
  { id: 153, name: 'Publicist' },
  { id: 154, name: 'Sales Coordinator' },
  { id: 155, name: 'Copy Editor' },
  { id: 156, name: 'Social Media Coordinator' },
  { id: 157, name: 'Technical Writer' },
  { id: 158, name: 'Library Assistant' },
  { id: 159, name: 'Environmental Engineer' },
  { id: 160, name: 'Fashion Designer' },
  { id: 161, name: 'Technical Support Engineer' },
  { id: 162, name: 'Market Research Analyst' },
  { id: 163, name: 'Clinical Psychologist' },
  { id: 164, name: 'Database Developer' },
  { id: 165, name: 'User Interface Designer' },
  { id: 166, name: 'Logistics Coordinator' },
  { id: 167, name: 'Investment Consultant' },
  { id: 168, name: 'Paralegal' },
  { id: 169, name: 'Financial Advisor' },
  { id: 170, name: 'Sales Consultant' },
  { id: 171, name: 'Nutritionist' },
  { id: 172, name: 'Environmental Specialist' },
  { id: 173, name: 'Mechanical Technician' },
  { id: 174, name: 'Physical Therapist Assistant' },
  { id: 175, name: 'Public Relations Coordinator' },
  { id: 176, name: 'Administrative Assistant' },
  { id: 177, name: 'Editorial Assistant' },
  { id: 178, name: 'Marketing Specialist' },
  { id: 179, name: 'Environmental Technician' },
  { id: 180, name: 'Fashion Stylist' },
  { id: 181, name: 'Technical Support Specialist' },
  { id: 182, name: 'Market Research Manager' },
  { id: 183, name: 'Clinical Research Coordinator' },
  { id: 184, name: 'Database Manager' },
  { id: 185, name: 'UX/UI Designer' },
  { id: 186, name: 'Logistics Specialist' },
  { id: 187, name: 'Investment Manager' },
  { id: 188, name: 'Part-Time Sales Associate' },
  { id: 189, name: 'Nutrition Specialist' },
  { id: 190, name: 'Environmental Consultant' },
  { id: 191, name: 'Medical Sales Representative' },
  { id: 192, name: 'Physical Education Teacher' },
  { id: 193, name: 'Public Relations Director' },
  { id: 194, name: 'Administrative Coordinator' },
  { id: 195, name: 'Editor' },
  { id: 196, name: 'Marketing Strategist' },
  { id: 197, name: 'Environmental Analyst' },
  { id: 198, name: 'Fashion Merchandiser' },
  { id: 199, name: 'Technical Analyst' },
  { id: 200, name: 'Market Research Specialist' },
  { id: 201, name: 'Clinical Research Manager' },
  { id: 202, name: 'Database Specialist' },
  { id: 203, name: 'Visual Designer' },
  { id: 204, name: 'Logistics Analyst' },
  { id: 205, name: 'Investor Relations Manager' },
  { id: 206, name: 'Nutrition Consultant' },
  { id: 207, name: 'Environmental Researcher' },
  { id: 208, name: 'Medical Writer' },
  { id: 209, name: 'Physical Therapist Aide' },
  { id: 210, name: 'Public Relations Specialist' },
  { id: 211, name: 'Administrative Specialist' },
  { id: 212, name: 'Editorial Coordinator' },
  { id: 213, name: 'Marketing Coordinator' },
  { id: 214, name: 'Event Coordinator' },
  { id: 215, name: 'Fashion Model' },
  { id: 216, name: 'Technical Specialist' },
  { id: 217, name: 'Market Research Analyst' },
  { id: 218, name: 'Clinical Researcher' },
  { id: 219, name: 'Database Analyst' },
  { id: 220, name: 'Visual Merchandiser' },
  { id: 221, name: 'Logistics Manager' },
  { id: 222, name: 'Investor Relations Coordinator' },
  { id: 223, name: 'Nutrition Specialist' },
  { id: 224, name: 'Environmental Consultant' },
  { id: 225, name: 'Medical Sales Representative' },
  { id: 226, name: 'Physical Education Teacher' },
  { id: 227, name: 'Public Relations Director' },
  { id: 228, name: 'Administrative Coordinator' },
  { id: 229, name: 'Editor' },
  { id: 230, name: 'Marketing Strategist' },
  { id: 231, name: 'Environmental Analyst' },
  { id: 232, name: 'Fashion Merchandiser' },
  { id: 233, name: 'Technical Analyst' },
  { id: 234, name: 'Market Research Specialist' },
  { id: 235, name: 'Clinical Research Manager' },
  { id: 236, name: 'Database Specialist' },
  { id: 237, name: 'Visual Designer' },
  { id: 238, name: 'Logistics Analyst' },
  { id: 239, name: 'Investor Relations Manager' },
  { id: 240, name: 'Nutrition Consultant' },
  { id: 241, name: 'Environmental Researcher' },
  { id: 242, name: 'Medical Writer' },
  { id: 243, name: 'Physical Therapist Aide' },
  { id: 244, name: 'Public Relations Specialist' },
  { id: 245, name: 'Administrative Specialist' },
  { id: 246, name: 'Editorial Coordinator' },
  { id: 247, name: 'Marketing Coordinator' },
  { id: 248, name: 'Event Coordinator' },
  { id: 249, name: 'Fashion Model' },
  { id: 250, name: 'Technical Specialist' },
  { id: 251, name: 'Market Research Analyst' },
  { id: 252, name: 'Clinical Researcher' },
  { id: 253, name: 'Database Analyst' },
  { id: 254, name: 'Visual Merchandiser' },
  { id: 255, name: 'Logistics Manager' },
  { id: 256, name: 'Investor Relations Coordinator' },
  { id: 257, name: 'Nutrition Specialist' },
  { id: 258, name: 'Environmental Consultant' },
  { id: 259, name: 'Medical Sales Representative' },
  { id: 260, name: 'Physical Education Teacher' },
  { id: 261, name: 'Public Relations Director' },
  { id: 262, name: 'Administrative Coordinator' },
  { id: 263, name: 'Editor' },
  { id: 264, name: 'Marketing Strategist' },
  { id: 265, name: 'Environmental Analyst' },
  { id: 266, name: 'Fashion Merchandiser' },
  { id: 267, name: 'Technical Analyst' },
  { id: 268, name: 'Market Research Specialist' },
  { id: 269, name: 'Clinical Research Manager' },
  { id: 270, name: 'Database Specialist' },
  { id: 271, name: 'Visual Designer' },
  { id: 272, name: 'Logistics Analyst' },
  { id: 273, name: 'Investor Relations Manager' },
  { id: 274, name: 'Nutrition Consultant' },
  { id: 275, name: 'Environmental Researcher' },
  { id: 276, name: 'Medical Writer' },
  { id: 277, name: 'Physical Therapist Aide' },
  { id: 278, name: 'Public Relations Specialist' },
  { id: 279, name: 'Administrative Specialist' },
  { id: 280, name: 'Editorial Coordinator' },
  { id: 281, name: 'Marketing Coordinator' },
  { id: 282, name: 'Event Coordinator' },
  { id: 283, name: 'Fashion Model' },
  { id: 284, name: 'Technical Specialist' },
  { id: 285, name: 'Market Research Analyst' },
  { id: 286, name: 'Clinical Researcher' },
  { id: 287, name: 'Database Analyst' },
  { id: 288, name: 'Visual Merchandiser' },
  { id: 289, name: 'Logistics Manager' },
  { id: 290, name: 'Investor Relations Coordinator' },
  { id: 291, name: 'Nutrition Specialist' },
  { id: 292, name: 'Environmental Consultant' },
  { id: 293, name: 'Medical Sales Representative' },
  { id: 294, name: 'Physical Education Teacher' },
  { id: 295, name: 'Public Relations Director' },
  { id: 296, name: 'Administrative Coordinator' },
  { id: 297, name: 'Editor' },
  { id: 298, name: 'Marketing Strategist' },
  { id: 299, name: 'Environmental Analyst' },
  { id: 300, name: 'Fashion Merchandiser' },
  { id: 301, name: 'Technical Analyst' },
  { id: 302, name: 'Market Research Specialist' },
  { id: 303, name: 'Clinical Research Manager' },
  { id: 304, name: 'Database Specialist' },
  { id: 305, name: 'Visual Designer' },
  { id: 306, name: 'Logistics Analyst' },
  { id: 307, name: 'Investor Relations Manager' },
  { id: 308, name: 'Nutrition Consultant' },
  { id: 309, name: 'Environmental Researcher' },
  { id: 310, name: 'Medical Writer' },
  { id: 311, name: 'Physical Therapist Aide' },
  { id: 312, name: 'Public Relations Specialist' },
  { id: 313, name: 'Administrative Specialist' },
  { id: 314, name: 'Editorial Coordinator' },
  { id: 315, name: 'Marketing Coordinator' },
  { id: 316, name: 'Event Coordinator' },
  { id: 317, name: 'Fashion Model' },
  { id: 318, name: 'Technical Specialist' },
  { id: 319, name: 'Market Research Analyst' },
  { id: 320, name: 'Clinical Researcher' },
  { id: 321, name: 'Database Analyst' },
  { id: 322, name: 'Visual Merchandiser' },
  { id: 323, name: 'Logistics Manager' },
  { id: 324, name: 'Investor Relations Coordinator' },
  { id: 325, name: 'Nutrition Specialist' },
  { id: 326, name: 'Environmental Consultant' },
  { id: 327, name: 'Medical Sales Representative' },
  { id: 328, name: 'Physical Education Teacher' },
  { id: 329, name: 'Public Relations Director' },
  { id: 330, name: 'Administrative Coordinator' },
  { id: 331, name: 'Editor' },
  { id: 332, name: 'Marketing Strategist' },
  { id: 333, name: 'Environmental Analyst' },
  { id: 334, name: 'Fashion Merchandiser' },
  { id: 335, name: 'Technical Analyst' },
  { id: 336, name: 'Market Research Specialist' },
  { id: 337, name: 'Clinical Research Manager' },
  { id: 338, name: 'Database Specialist' },
  { id: 339, name: 'Visual Designer' },
  { id: 340, name: 'Logistics Analyst' },
  { id: 341, name: 'Investor Relations Manager' },
  { id: 342, name: 'Nutrition Consultant' },
  { id: 343, name: 'Environmental Researcher' },
  { id: 344, name: 'Medical Writer' },
  { id: 345, name: 'Physical Therapist Aide' },
  { id: 346, name: 'Public Relations Specialist' },
  { id: 347, name: 'Administrative Specialist' },
  { id: 348, name: 'Editorial Coordinator' },
  { id: 349, name: 'Marketing Coordinator' },
  { id: 350, name: 'Event Coordinator' },
  { id: 351, name: 'Fashion Model' },
  { id: 352, name: 'Technical Specialist' },
  { id: 353, name: 'Market Research Analyst' },
  { id: 354, name: 'Clinical Researcher' },
  { id: 355, name: 'Database Analyst' },
  { id: 356, name: 'Visual Merchandiser' },
  { id: 357, name: 'Logistics Manager' },
  { id: 358, name: 'Investor Relations Coordinator' },
  { id: 359, name: 'Nutrition Specialist' },
  { id: 360, name: 'Environmental Consultant' },
  { id: 361, name: 'Medical Sales Representative' },
  { id: 362, name: 'Physical Education Teacher' },
  { id: 363, name: 'Public Relations Director' },
  { id: 364, name: 'Administrative Coordinator' },
  { id: 365, name: 'Editor' },
  { id: 366, name: 'Marketing Strategist' },
  { id: 367, name: 'Environmental' },
  { id: 367, name: 'Front-end developer' },
  { id: 367, name: 'Back-end developer' },
];
