import * as translations from 'src/translations';

import { useLocalStorage } from '../useStorage/hook';

const getNestedTranslation = (language, keys) =>
  keys.reduce((obj, key) => obj?.[key], translations[language]);

const useTranslation = () => {
  const [language, setLanguage] = useLocalStorage('language', 'en');
  const [fallbackLanguage, setFallbackLanguage] = useLocalStorage('fallbackLanguage', 'en');

  const translate = (key) => {
    const keys = key.split('.');

    return (
      getNestedTranslation(language, keys) ?? getNestedTranslation(fallbackLanguage, keys) ?? key
    );
  };

  return {
    language,
    setLanguage,
    fallbackLanguage,
    setFallbackLanguage,
    translate,
  };
};

export default useTranslation;
