import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { months } from 'src/constants';
import { getDataFormat } from 'src/helpers';

import s from './style.module.scss';

const Profesional = ({ setOnRendered }) => {
  const { currentResume } = useSelector((state) => state.templatesReducer);

  const { image } = currentResume;

  useEffect(() => {
    const img = document.getElementById('avatar');
    if (img) {
      const imgAvatar = document.getElementById('avatar');
      imgAvatar.onload = () => {
        setOnRendered(true);
      };
    } else {
      setOnRendered(true);
    }
  }, []);

  return (
    <>
      {currentResume && (
        <div className={s.cari}>
          <div className={s.cari__left}>
            <div className="section-col">
              <div className="section">
                {image && (
                  <div className={s.avatar__wrap}>
                    <div className={s.avatar}>
                      <img width={215} src={image} alt="avatar" id="avatar" />
                    </div>
                  </div>
                )}
              </div>
              <div className="section">
                <div className={s.about}>
                  <div className={s.name}>Contacts</div>

                  <div className={s.about__block}>
                    <div className={s.title__left}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="xMidYMid meet"
                        version="1.0"
                        viewBox="-0.3 -0.5 74.8 102.9"
                        zoomAndPan="magnify"
                        width={15}
                        height={15}
                      >
                        <g id="__id130_sjz8b5do6l">
                          <path d="m21.547 0.091s-21.827 0.287-21.544 20.059 4.253 37.255 10.773 46.998 23.242 35.248 42.236 32.67c0 0 9.355-2.007 16.44-6.593l-22.96-18.054s-6.803 4.299-11.055-1.433-17.575-32.381-17.575-32.381-3.685-8.598 4.536-12.323l-0.851-28.943z" />
                        </g>
                        <g id="__id131_sjz8b5do6l">
                          <path d="m24.099.091c0,0 1.701-.571 4.818,1.147s3.118,3.15 3.118,3.15l.284,19.775c0,0-.851,1.147-1.984,2.291-1.134,1.146-4.819,2.867-5.387,1.72-.565-1.145-.849-28.083-.849-28.083z" />
                        </g>
                        <g id="__id132_sjz8b5do6l">
                          <path d="m48.401 73.058 6.312-3.332s1.417-0.285 3.684 1.146c2.268 1.436 14.174 10.893 14.174 10.893s1.984 1.719 1.417 3.725-1.984 6.305-1.984 6.305l-23.603-18.737z" />
                        </g>
                      </svg>
                    </div>
                    <div className={s.about__block__right}>{currentResume.phone}</div>
                  </div>

                  <div className={s.about__block}>
                    <div className={s.title__left}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width={15}
                        height={15}
                      >
                        <g id="__id126_sjz8b5do6l">
                          <path d="m43.98 16c0-1.44-0.75-2.69-1.88-3.4l-18.1-10.6-18.1 10.6c-1.13 0.71-1.9 1.96-1.9 3.4v20c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4l-0.02-20zm-19.98 10l-16.52-10.33 16.52-9.67 16.52 9.67-16.52 10.33z" />
                        </g>
                        <path d="m0 0h48v48h-48z" style={{ fill: 'none' }} />
                      </svg>
                    </div>
                    <div className={s.text}>{currentResume.email}</div>
                  </div>

                  <div className={s.about__block}>
                    <div className={s.title__left}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 283.464 425.215"
                        enableBackground="new 0 0 283.464 425.215"
                        xmlSpace="preserve"
                        width={15}
                        height={15}
                      >
                        <g id="__id122_sjz8b5do6l">
                          <path d="M141.734,0C63.454,0,0,63.458,0,141.737c-0.001,26.57,7.309,51.42,20.029,72.672l121.7,210.808   l121.797-210.957c12.657-21.216,19.938-46.018,19.938-72.521C283.468,63.457,220.014,0,141.734,0z M141.734,189.168   c-26.193,0-47.429-21.237-47.424-47.432c0-26.192,21.236-47.429,47.424-47.429c26.198,0,47.434,21.236,47.434,47.429   C189.167,167.932,167.932,189.168,141.734,189.168z" />
                        </g>
                      </svg>
                    </div>
                    <div className={s.about__block__right}>
                      <div>
                        {currentResume.country.name}, {currentResume.city.name}
                      </div>

                      <div>
                        {currentResume.state} {currentResume.zip}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {currentResume.languages.length > 0 && (
                <div className="section">
                  <div className={s.about}>
                    <div className={s.name}>Languagess</div>
                    {currentResume.languages.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.language} - {item.proficiency}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {currentResume.hardSkills.length > 0 && (
                <div className="section">
                  <div className={s.about}>
                    <div className={s.name}>Hard skills</div>
                    {currentResume.hardSkills.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.skill}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {currentResume.softSkills.length > 0 && (
                <div className="section">
                  <div className={s.about}>
                    <div className={s.name}>Soft skills</div>
                    {currentResume.softSkills.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.skill}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={s.cari__right}>
            <div className="section-col">
              <div className="section section-first-page">
                <div className={s.title__wrap}>
                  <div className={s.title}>
                    <b>{currentResume.firstName}</b> {currentResume.lastName}
                  </div>
                  <div className={s.title2}>{currentResume.jobTitle}</div>
                </div>
              </div>
              {currentResume.experiences.length > 0 && (
                <div className="section">
                  {currentResume.experiences.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Experiences</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__top}>
                            <div className={s.work__name}>{item.jobTitle}</div>
                            <div className={s.work__data}>
                              <div className={s.work__data__col}>
                                {getDataFormat(item.startDate)}
                              </div>
                              -
                              <div className={s.work__data__col}>
                                {item.isPresent ? 'Present' : getDataFormat(item.endDate)}
                              </div>
                            </div>
                          </div>

                          <div className={s.work__job}>{item.companyName}</div>

                          <div className={s.text}>{item.responsibilities}</div>
                          <div className={s.text}>{item.achievements}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.educations.length > 0 && (
                <div className="section">
                  {currentResume.educations.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Education</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__top}>
                            <div className={s.work__name}>{item.universityName}</div>
                            <div className={s.work__data}>
                              <div className={s.work__data__col}>
                                {months[item.graduationMonth].name} {item.graduationYear}
                              </div>
                            </div>
                          </div>

                          <div className={s.work__job}>
                            {item.country.name}, {item.city.name}
                          </div>

                          <div className={s.text}>{item.achievements}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.hobbies.length > 0 && (
                <div className="section">
                  {currentResume.hobbies.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Hobbies</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__job}>{item.name}</div>

                          <div className={s.text}>{item.description}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.certificates.length > 0 && (
                <div className="section">
                  {currentResume.certificates.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Certifications</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__job}>{item.name}</div>

                          <div className={s.text}>{item.skills}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Profesional.propTypes = {
  setOnRendered: PropTypes.func.isRequired,
};
export default Profesional;
