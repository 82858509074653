import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { months } from 'src/constants';
import { getDataFormat } from 'src/helpers';

import s from './style.module.scss';

const Cari = ({ setOnRendered }) => {
  const { currentResume } = useSelector((state) => state.templatesReducer);

  const { image } = currentResume;

  useEffect(() => {
    const img = document.getElementById('avatar');
    if (img) {
      const imgAvatar = document.getElementById('avatar');
      imgAvatar.onload = () => {
        setOnRendered(true);
      };
    } else {
      setOnRendered(true);
    }
  }, []);

  const getSlice = (value, size) => {
    const arraySize = size;

    const slicedArray = [];

    for (let i = 0; i < value.length; i += arraySize) {
      slicedArray.push({ id: i, childs: value.slice(i, i + arraySize) });
    }

    return slicedArray;
  };

  const sliceLangs = currentResume.languages.length > 0 ? getSlice(currentResume.languages, 4) : [];

  return (
    <>
      {currentResume && (
        <div className={s.cari}>
          <div className={s.cari__left}>
            <div className="section-col">
              <div className="section">
                {image && (
                  <div className={s.avatar__wrap}>
                    <div className={s.avatar}>
                      <img width={170} src={image} alt="avatar" id="avatar" />
                    </div>
                  </div>
                )}
              </div>
              <div className="section">
                <div className={s.about}>
                  <div className={s.name}>
                    {currentResume.firstName} {currentResume.lastName}
                  </div>
                  <div className={s.text}>
                    {currentResume.country.name}, {currentResume.city.name}
                  </div>
                  <div className={s.text}>
                    {currentResume.state} {currentResume.zip}
                  </div>
                  <div className={s.text}>{currentResume.phone}</div>
                  <div className={s.text}>{currentResume.email}</div>
                </div>
              </div>
              {currentResume.educations.length > 0 && (
                <div className="section">
                  <div className={s.about}>
                    <div className={s.name}>Education</div>
                    {currentResume.educations.map((item) => (
                      <div className={s.about2} key={item.id}>
                        <div className={s.text2}>
                          <b>{item.universityName}</b>
                          <div>
                            {months[item.graduationMonth].name} {item.graduationYear}
                          </div>
                          <div>
                            {item.country.name}, {item.city.name}
                          </div>
                          <div>
                            {item.degree.name} {item.field.name}
                          </div>
                        </div>
                        <div className={s.text}>{item.achievements}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {currentResume.hardSkills.length > 0 && (
                <div className="section">
                  <div className={`${s.about} ${s.last}`}>
                    <div className={s.suo}>
                      <div className={s.text}>
                        <b>Hard skills</b>
                      </div>
                      {currentResume.hardSkills.map((item) => (
                        <div className={s.text} key={item.id}>
                          {item.skill}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {currentResume.softSkills.length > 0 && (
                <div className="section">
                  <div className={`${s.about} ${s.last}`}>
                    <div className={s.suo}>
                      <div className={s.text}>
                        <b>Soft skills</b>
                      </div>
                      {currentResume.softSkills.map((item) => (
                        <div className={s.text} key={item.id}>
                          {item.skill}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={s.cari__right}>
            <div className="section-col">
              <div className="section section-first-page">
                <div className={s.title__wrap}>
                  <div className={s.title}>
                    {currentResume.firstName} {currentResume.lastName}
                  </div>
                  <div className={s.title2}>{currentResume.jobTitle}</div>
                </div>
              </div>
              {currentResume.experiences.length > 0 && (
                <div className="section">
                  {currentResume.experiences.map((item, index) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        {index === 0 && (
                          <div className={s.work__title}>
                            Experiences <span />
                          </div>
                        )}

                        <div className={s.work__wrap}>
                          <div className={s.work__left}>
                            <div className={s.work__name}>{item.companyName}</div>
                            <div className={s.text}>
                              {getDataFormat(item.startDate)} -{' '}
                              {item.isPresent ? 'Present' : getDataFormat(item.endDate)}
                            </div>
                          </div>
                          <div className={s.work__right}>
                            <div className={s.work__name}>{item.jobTitle}</div>
                            <div className={s.text}>{item.responsibilities}</div>
                            <div className={s.text}>{item.achievements}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.hobbies.length > 0 && (
                <div className="section">
                  {currentResume.hobbies.map((item, index) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        {index === 0 && (
                          <div className={s.work__title}>
                            Hobbies <span />
                          </div>
                        )}

                        <div className={s.work__wrap}>
                          <div className={s.work__left}>
                            <div className={s.work__name}>{item.name}</div>
                          </div>
                          <div className={s.work__right}>
                            <div className={s.text}>{item.description}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.certificates.length > 0 && (
                <div className="section">
                  {currentResume.certificates.map((item, index) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div
                        className={`${s.work} ${
                          index === currentResume.certificates.length - 1 ? `${s.last}` : ''
                        }`}
                      >
                        {index === 0 && (
                          <div className={s.work__title}>
                            Certifications <span />
                          </div>
                        )}

                        <div className={s.work__wrap}>
                          <div className={s.work__left}>
                            <div className={s.work__name}>{item.name}</div>
                          </div>
                          <div className={s.work__right}>
                            <div className={s.text}>{item.skills}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {sliceLangs.length > 0 && (
                <div className="section">
                  {sliceLangs.map((item, index) => (
                    <div className="sub-section" key={item.id}>
                      <div className={`${s.work} ${s.hobi}`}>
                        {index === 0 && (
                          <div className={s.work__title}>
                            Languages <span />
                          </div>
                        )}

                        <div className={s.work__flex}>
                          {item.childs.map((lang) => (
                            <div className={s.work__col} key={lang.id}>
                              <div className={s.text}>
                                <b>{lang.language}</b>
                              </div>
                              <div className={s.text}>{lang.proficiency}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Cari.propTypes = {
  setOnRendered: PropTypes.func.isRequired,
};
export default Cari;
