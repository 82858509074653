import './style.scss';
import 'swiper/css';
import 'swiper/css/navigation';

import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import compFn from 'src/assets/img/home/comp-fn.svg';
import compLg from 'src/assets/img/home/comp-lg.svg';
import compLit from 'src/assets/img/home/comp-lit.svg';
import compSm from 'src/assets/img/home/comp-sm.svg';
import s1 from 'src/assets/img/home/s1.png';
import s1Mob from 'src/assets/img/home/s1-mob.png';
import s2n1 from 'src/assets/img/home/s2-1.svg';
import s2n2 from 'src/assets/img/home/s2-2.svg';
import s2n3 from 'src/assets/img/home/s2-3.svg';
import s2n4 from 'src/assets/img/home/s2-4.svg';
import s4n1 from 'src/assets/img/home/s4-1.svg';
import s4n2 from 'src/assets/img/home/s4-2.svg';
import s4n3 from 'src/assets/img/home/s4-3.svg';
import s4n4 from 'src/assets/img/home/s4-4.svg';
import s4n5 from 'src/assets/img/home/s4-5.svg';
import s4n6 from 'src/assets/img/home/s4-6.svg';
import t1 from 'src/assets/img/home/testim/1.jpg';
import t2 from 'src/assets/img/home/testim/2.jpg';
import t3 from 'src/assets/img/home/testim/3.jpg';
import t4 from 'src/assets/img/home/testim/4.jpg';
import t5 from 'src/assets/img/home/testim/5.jpg';
import t6 from 'src/assets/img/home/testim/6.jpg';
import t7 from 'src/assets/img/home/testim/7.jpg';
import sprite from 'src/assets/sprite.svg';
import { AuthButton } from 'src/components';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { FreeMode, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { FAQ } from './components';
import Sample from './sample';

const Home = () => {
  const { width } = useWindowDimensions();
  const { translate, language } = useTranslation();

  const [sample, setSample] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const elem = document.getElementById(location.hash.slice(1));
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <>
      <div className="firstGrey">
        <div className="s1">
          <div className="wrap">
            <div className="s1__left">
              <div className="s1__t1">{translate('Home.header')}</div>
              <div className="s1__t2">{translate('Home.header2')}</div>
              <div className="s1__t3">{translate('Home.header_description')}</div>
              <AuthButton to="/my-cv/create/" content={translate('Home.button_build')} />
              <div className="s1__t4">{translate('Home.header_logos')}</div>
              <div className="s1__comp">
                {language === 'en' && <img src={width >= 565 ? compLg : compSm} alt="" />}
                {language === 'fn' && <img src={width >= 565 ? compFn : compFn} alt="" />}
                {language === 'lit' && <img src={width >= 565 ? compLit : compLit} alt="" />}
              </div>
            </div>
          </div>
          <div className="s1__right">
            <img src={width >= 565 ? s1 : s1Mob} alt="img" />
          </div>
        </div>
      </div>
      <div className="s2">
        <div className="wrap">
          <div className="title__wrap">
            <div className="title__label">{translate('Home.process')}</div>
            <div className="title">{translate('Home.see_how_it_works')}</div>
            <div className="title__text">{translate('Home.see_how_it_works_desc')}</div>
          </div>
          <div className="s2__wrap">
            <div className="s2__col">
              <img src={s2n1} alt="img" width="43.09" height="45" />
              <div className="s2__title">{translate('Home.pick_a_template')}</div>
              <div className="s2__text">{translate('Home.choose_one')}</div>
            </div>
            <div className="s2__col">
              <img src={s2n2} alt="img" width="43.09" height="45" />
              <div className="s2__title">{translate('Home.customize_layout')}</div>
              <div className="s2__text">{translate('Home.customize_the_layout')}</div>
            </div>
            <div className="s2__col">
              <img src={s2n3} alt="img" width="43.09" height="45" />
              <div className="s2__title">{translate('Home.fill_in_the_blanks')}</div>
              <div className="s2__text">{translate('Home.fill_in_your_resume')}</div>
            </div>
            <div className="s2__col">
              <img src={s2n4} alt="img" width="43.09" height="45" />
              <div className="s2__title">{translate('Home.download')}</div>
              <div className="s2__text">{translate('Home.download_your_cv')}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="s3">
        <div className="wrap">
          <div className="title__wrap">
            <div className="title__label">{translate('Home.templates')}</div>
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: translate('Home.choose_a_sample') }}
            />
            <div className="title__text">{translate('Home.choose_a_sample_desc')}</div>
          </div>

          <div className="s3__tabs">
            <button
              className={`btn btn-outline-primary btn-outline--purple ${sample ? 'active' : ''}`}
              type="button"
              onClick={() => setSample(true)}
            >
              {translate('Home.choose_popular')}
            </button>
            <button
              className={`btn btn-outline-primary btn-outline--purple ${!sample ? 'active' : ''}`}
              type="button"
              onClick={() => setSample(false)}
            >
              {translate('Home.choose_all')}
            </button>
          </div>
          <Sample />
          <div className="btn-center">
            <AuthButton to="/my-cv/create/" content={translate('Home.button_build')} />
          </div>
        </div>
      </div>

      <div className="s4">
        <div className="wrap">
          <div className="title__wrap">
            <div className="title__label">{translate('Home.benefits')}</div>
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: translate('Home.what_you_get') }}
            />
            <div className="title__text">{translate('Home.what_you_get_desc')}</div>
          </div>
          <div className="s4__wrap">
            <div className="s4__col">
              <div className="s4__top">
                <img src={s4n1} alt="icon" />
                {translate('Home.unlimited')}
              </div>
              <div className="s4__text">
                {translate('Home.our_templates_1')}
                <br /> {translate('Home.our_templates_2')}
                <Link to="/cv-templates">{translate('Home.our_templates_3')}</Link>
              </div>
            </div>
            <div className="s4__col">
              <div className="s4__top">
                <img src={s4n2} alt="icon" />
                {translate('Home.cover_letter_builder')}
              </div>
              <div className="s4__text">
                {translate('Home.generate_a_cover_1')}
                <Link to="/cover">{translate('Home.generate_a_cover_2')}</Link>
              </div>
            </div>
            <div className="s4__col">
              <div className="s4__top">
                <img src={s4n3} alt="icon" />
                {translate('Home.quick_smart_approach')}
              </div>
              <div className="s4__text">
                {translate('Home.cv_builder_1')}
                <Link to="/cv">{translate('Home.cv')}</Link> {translate('Home.or')}
                <Link to="/cover">{translate('Home.cv_builder_2')}</Link>.
              </div>
            </div>
            <div className="s4__col">
              <div className="s4__top">
                <img src={s4n4} alt="icon" />
                {translate('Home.tips_career_articles')}
              </div>
              <div className="s4__text">{translate('Home.tips_career_articles_desc')}</div>
            </div>
            <div className="s4__col">
              <div className="s4__top">
                <img src={s4n5} alt="icon" />
                {translate('Home.easy_editable_templates')}
              </div>
              <div className="s4__text">{translate('Home.easy_editable_templates_desc')}</div>
            </div>
            <div className="s4__col">
              <div className="s4__top">
                <img src={s4n6} alt="icon" />
                {translate('Home.high_quality_output')}
              </div>
              <div className="s4__text">{translate('Home.high_quality_output_desc')}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="s5">
        <div className="wrap">
          <div className="title__wrap">
            <div className="title__label">{translate('Home.testimonials')}</div>
            <div className="title">{translate('Home.what_users_say')}</div>
            <div className="title__text">{translate('Home.what_users_say_desc')}</div>
          </div>
          <div className="s5__slider">
            <Swiper
              slidesPerView={1}
              spaceBetween={20}
              navigation
              freeMode
              className="mySwiper"
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  spaceBetween: 20,
                  slidesPerView: 4,
                },
              }}
              modules={[Navigation, FreeMode]}
            >
              <SwiperSlide>
                <div className="s5__item">
                  <div className="s5__img">
                    <img src={t1} alt="avatar" />
                  </div>
                  <div className="s5__name">Stephanie Liverani</div>
                  <div className="s5__text">
                    Thanks to the WOW Career CV builder, I was able to create a professional and
                    eye-catching CV in just a few minutes. It made the job application process so
                    much easier!
                  </div>
                  <div className="s5__stars">
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="s5__item">
                  <div className="s5__img">
                    <img src={t2} alt="avatar" />
                  </div>
                  <div className="s5__name">Jurica Koletic</div>
                  <div className="s5__text">
                    I highly recommend this WOW Career. It&apos;s user-friendly, and the templates
                    are fantastic. I got several job interviews within a week of using it!
                  </div>
                  <div className="s5__stars">
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14" className="grey">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="s5__item">
                  <div className="s5__img">
                    <img src={t3} alt="avatar" />
                  </div>
                  <div className="s5__name">Christian Buehner</div>
                  <div className="s5__text">
                    As a recent graduate, I was struggling to create a resume that would stand out.
                    This CV builder not only helped me create an impressive CV but also boosted my
                    confidence during interviews.
                  </div>
                  <div className="s5__stars">
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="s5__item">
                  <div className="s5__img">
                    <img src={t4} alt="avatar" />
                  </div>
                  <div className="s5__name">Sergio de Paula</div>
                  <div className="s5__text">
                    I&apos;ve been in the job market for years, and this WOW Career CV builder is a
                    game-changer. It helped me revamp my old CV, and I received job offers I
                    couldn&apos;t have imagined.
                  </div>
                  <div className="s5__stars">
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="s5__item">
                  <div className="s5__img">
                    <img src={t5} alt="avatar" />
                  </div>
                  <div className="s5__name">Robert Godwin</div>
                  <div className="s5__text">
                    This CV builder is a must-try for anyone looking to enhance their career
                    prospects. It&apos;s simple, efficient, and produces high-quality resumes. I
                    landed my dream job thanks to it!
                  </div>
                  <div className="s5__stars">
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="s5__item">
                  <div className="s5__img">
                    <img src={t6} alt="avatar" />
                  </div>
                  <div className="s5__name">Tamara Bellis</div>
                  <div className="s5__text">
                    I&apos;m not tech-savvy, but this WOW Career was so easy to use. It took the
                    stress out of resume writing, and I got hired within a month of using it.
                  </div>
                  <div className="s5__stars">
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14" className="grey">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="s5__item">
                  <div className="s5__img">
                    <img src={t7} alt="avatar" />
                  </div>
                  <div className="s5__name">Allef Vinicius</div>
                  <div className="s5__text">
                    I&apos;ve used some CV builders in the past, but this one stands out. The
                    templates are modern, and nice. It&apos;s worth every penny
                  </div>
                  <div className="s5__stars">
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                    <svg width="15" height="14">
                      <use xlinkHref={`${sprite}#star`} />
                    </svg>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="s6" id="faq">
        <div className="wrap--small">
          <div className="title__wrap">
            <div className="title__label">faq</div>
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: translate('Home.faq_sub_title') }}
            />
            <div className="title__text">{translate('Home.faq_desc')}</div>
          </div>
          <div className="s6__spoler">
            <FAQ />
          </div>
          <div className="s6__link">
            <Link to="/">{translate('Home.see_more')}</Link>
          </div>
          <div className="btn-center">
            <AuthButton to="/my-cv/create/" content={translate('Home.button_build')} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
