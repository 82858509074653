import './style.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import planetLang from 'src/assets/img/home/lang-planet.svg';
import sprite from 'src/assets/sprite.svg';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';

const HeaderAc = () => {
  const dispatch = useDispatch();
  const { translate, setLanguage, language } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [headerFix, setHeaderFix] = useState(false);
  const [burger, setBurger] = useState(false);
  const [userMenu, setUserMenu] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [sett, setSett] = useState(false);

  const fixWaiter = useSelector((state) => state.authReducer.userNatificationWaiter);
  const name = useSelector((state) => state.authReducer.user.name);
  const notifications = useSelector((state) => state.authReducer.notifications);
  const userHaveUnReadNotifications = useSelector(
    (state) => state.authReducer.userHaveUnReadNotification,
  );

  useEffect(() => {
    setBurger(false);
  }, [location.pathname]);

  useEffect(() => {
    dispatch(authActions.checkIfUserHaveUnReadNotification());
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset) {
        setHeaderFix(true);
      } else {
        setHeaderFix(false);
      }
    });
  }, [window.pageYOffset]);

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.user-nav') && !e.target.closest('.userAc')) {
        setUserMenu(false);
      }
    });

    document.addEventListener('click', (e) => {
      if (!e.target.closest('.notification-nav') && !e.target.closest('.bell')) {
        setNotificationModal(false);
      }
    });
  }, []);

  const logout = async () => {
    await dispatch(authActions.logout());
    localStorage.clear();
    navigate('/');
  };

  const handleNotifications = async () => {
    setNotificationModal(!notificationModal);
    if (!notificationModal) {
      await dispatch(authActions.getUserNotifications());
      await dispatch(authActions.markNotificationsAsRead());
    }
  };

  const handelChangeLang = (e) => {
    e.preventDefault();
    setLanguage(e.target.value);
    window.location.reload();
  };

  return (
    <>
      <div className={`header ${headerFix ? 'fix' : ''}`}>
        <div className="wrap">
          <div className="header__wrap">
            <div className="header__logo">
              <Link to="/">{translate('cv.title')}</Link>
            </div>
            <nav className="header__nav">
              <ul>
                <li className="header__nav__li">
                  <NavLink to="/dashboard" className="header__nav__link">
                    {translate('components.dashboard')}
                  </NavLink>
                </li>
                <li className="header__nav__li">
                  <NavLink to="/my-cv/" className="header__nav__link">
                    {translate('components.my_cvs')}
                  </NavLink>
                </li>
                <li className="header__nav__li">
                  <NavLink to="/my-covers" className="header__nav__link">
                    {translate('components.mcl')}
                  </NavLink>
                </li>
              </ul>
            </nav>
            <div className="header__auth--ac__right">
              <div className="lang lang--ac">
                <img src={planetLang} width={23} height={23} alt="" className="lang-image" />
                <select onChange={handelChangeLang} defaultValue={language}>
                  <option value="en">English</option>
                  <option value="fn">Suomi</option>
                  <option value="lit">Lietuviškai</option>
                </select>
              </div>
              <div className="header__auth--ac">
                <button type="button" className="bell" onClick={handleNotifications}>
                  <svg width="24" height="25" viewBox="0 0 24 25">
                    <use xlinkHref={`${sprite}#bell`} />
                  </svg>
                  {userHaveUnReadNotifications ? <span className="bell__alert" /> : ''}
                </button>
                <button
                  type="button"
                  className={`userAc ${userMenu ? 'active' : ''}`}
                  onClick={() => setUserMenu((prev) => !prev)}
                >
                  <svg width="24" height="25" viewBox="0 0 24 25">
                    <use xlinkHref={`${sprite}#userAc`} />
                  </svg>
                  <svg width="10" height="6" className="userAc__ac">
                    <use xlinkHref={`${sprite}#v-ac`} />
                  </svg>
                </button>
                <nav className={`user-nav ${userMenu ? 'fadeIn' : ''}`}>
                  <div className="user-nav__title">
                    <svg width="24" height="25" viewBox="0 0 24 25">
                      <use xlinkHref={`${sprite}#userAc`} />
                    </svg>
                    {name}
                  </div>
                  <ul>
                    <li>
                      <Link to="/account" type="button">
                        <svg width="25" height="25" viewBox="0 0 25 25">
                          <use xlinkHref={`${sprite}#sett`} />
                        </svg>
                        {translate('components.settings')}
                      </Link>
                    </li>
                    <li>
                      <button type="button" onClick={logout}>
                        <svg width="25" height="25" viewBox="0 0 25 25">
                          <use xlinkHref={`${sprite}#sign-out`} />
                        </svg>
                        {translate('components.sign_out')}
                      </button>
                    </li>
                  </ul>
                </nav>

                <nav className={`notification-nav ${notificationModal ? 'fadeIn' : ''}`}>
                  <div className="user-nav__title">{translate('components.notifications')}</div>
                  {fixWaiter ? (
                    <div className="error">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">{translate('loading')}</span>
                    </div>
                  ) : (
                    <ul className="notification-nav__list notification-window">
                      {notifications ? (
                        notifications.map((notification) => (
                          <li key={notification.id}>
                            <Link
                              to="/account"
                              className={`notification-item ${
                                notification.read_at ? '' : 'unread'
                              }`}
                            >
                              {notification.message}
                            </Link>
                          </li>
                        ))
                      ) : (
                        <li>
                          <Link to="/account">{translate('components.didnt_notification')}</Link>
                        </li>
                      )}
                    </ul>
                  )}
                </nav>
              </div>
              <button
                type="button"
                className={`header__burger ${burger ? 'active' : ''}`}
                onClick={() => setBurger(!burger)}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" className="open">
                  <use xlinkHref={`${sprite}#burger-open`} />
                </svg>
                <svg width="24" height="24" viewBox="0 0 24 24" className="close">
                  <use xlinkHref={`${sprite}#burger-close`} />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <nav className={`header__mobMenu header__mobMenu--ac ${burger ? 'fadeIn active' : ''}`}>
        <ul>
          <li>
            <div className="header__mobMenu--ac__link header__mobMenu--ac__link--first">
              <svg width="20" height="20" viewBox="0 0 24 25">
                <use xlinkHref={`${sprite}#userAc`} />
              </svg>
              example@example.com
            </div>
          </li>
          <li>
            <Link to="/dashboard" className="header__mobMenu--ac__link">
              <svg width="20" height="20" viewBox="0 0 20 20">
                <use xlinkHref={`${sprite}#dashboard`} />
              </svg>
              {translate('components.dashboard')}
            </Link>
          </li>
          <li>
            <Link to="/my-cv" className="header__mobMenu--ac__link">
              <svg width="20" height="20" viewBox="0 0 20 20">
                <use xlinkHref={`${sprite}#mycv`} />
              </svg>
              {translate('components.my_cvs')}
            </Link>
          </li>
          <li>
            <Link to="/my-covers" className="header__mobMenu--ac__link">
              <svg id="mycovers" width="20" height="20" viewBox="0 0 20 20">
                <use xlinkHref={`${sprite}#mycovers`} />
              </svg>
              {translate('components.mcl')}
            </Link>
          </li>
          <li>
            <button
              type="button"
              className="header__mobMenu--ac__link"
              onClick={() => setSett(true)}
            >
              <svg width="20" height="20" viewBox="0 0 25 25">
                <use xlinkHref={`${sprite}#sett`} />
              </svg>
              {translate('components.settings')}
            </button>
          </li>
          <li>
            <button type="button" className="header__mobMenu--ac__link" onClick={logout}>
              <svg width="20" height="20" viewBox="0 0 25 25">
                <use xlinkHref={`${sprite}#sign-out`} />
              </svg>
              {translate('components.sign_out')}
            </button>
          </li>
        </ul>
      </nav>

      <div className={`settign ${sett ? 'fadeRight active' : ''}`}>
        <button type="button" className="settign__back" onClick={() => setSett(false)}>
          <svg width="24" height="24" viewBox="0 0 24 24">
            <use xlinkHref={`${sprite}#arr-back`} />
          </svg>
          {translate('components.settings')}
        </button>
        <div className="settign__row">
          <div className="sett__title">Account Settings</div>
          <div className="sett__block">
            <div className="sett__title2">
              {translate('components.email_id')}:
              <button type="button">
                <svg width="20" height="20" viewBox="0 0 20 20">
                  <use xlinkHref={`${sprite}#pencil`} />
                </svg>
                {translate('components.dashboard')}
              </button>
            </div>
            <div className="sett__text">example@example.com</div>
          </div>
          <div className="sett__block">
            <div className="sett__title2">
              {translate('components.password')}:
              <button type="button">
                <svg width="20" height="20" viewBox="0 0 20 20">
                  <use xlinkHref={`${sprite}#pencil`} />
                </svg>
                {translate('components.edit')}
              </button>
            </div>
            <div className="sett__text">**************</div>
          </div>
        </div>
        <div className="settign__row">
          <div className="sett__title">{translate('components.subscription_settings')}</div>
          <div className="sett__block">
            <div className="sett__title2">
              Subscription
              <button type="button">
                <svg width="20" height="20" viewBox="0 0 20 20">
                  <use xlinkHref={`${sprite}#pencil`} />
                </svg>
                edit
              </button>
            </div>
            <div className="sett__text">{translate('components.full_access')}</div>
          </div>
          <div className="sett__block">
            <div className="sett__title2">
              Due to:
              <button type="button">
                <svg width="20" height="20" viewBox="0 0 20 20">
                  <use xlinkHref={`${sprite}#pencil`} />
                </svg>
                edit
              </button>
            </div>
            <div className="sett__text">{translate('components.february')}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderAc;
