import 'swiper/css';
import 'swiper/css/free-mode';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthWidthTemplate } from 'src/components';
import { templatesActions } from 'src/store/actions';
import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const Sample = () => {
  const dispatch = useDispatch();
  const resume = useSelector((state) => state.templatesReducer.resumeTemplates);

  useEffect(() => {
    dispatch(templatesActions.getResumeTemplates());
  }, []);

  return (
    <>
      <div className="s3__mobname">CV templates</div>
      <div className="s3__swiper__wrap fadeIn" id="sample1">
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          freeMode
          initialSlide={1}
          className="s3__swiper"
          modules={[FreeMode]}
          breakpoints={{
            565: {
              slidesPerView: 2,
              initialSlide: 2,
            },
          }}
        >
          {resume &&
            resume.map(({ id, name, preview, description }) => (
              <SwiperSlide key={id}>
                <div className="s3__item">
                  <div className="s3__name">{name}</div>
                  <div className="s3__img">
                    <img src={preview} alt="resume" />
                    <AuthWidthTemplate
                      to="/my-cv/create/"
                      content="Choose template"
                      template={id}
                    />
                  </div>

                  <div className="s3__des">{description}</div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </>
  );
};

export default Sample;
