import { gapi } from 'gapi-script';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import sprite from 'src/assets/sprite.svg';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { clearErrors } from 'src/store/reducers/auth.reducer';

const googleID = process.env.REACT_APP_GOOGLE_SITE_KEY;
const facebookID = process.env.REACT_APP_FB_SITE_KEY;

const SignSoc = ({ show, handleClose, handleShowSignUp, handleShowSignIn }) => {
  const { translate } = useTranslation();
  const fixWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const redirectToAfterAuth = useSelector((state) => state.authReducer.redirectToAfterAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => () => dispatch(clearErrors()), []);

  useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.auth2.init({ clientId: googleID });
    });
  });

  const handleSignInGoogle = async (response) => {
    if (response.accessToken) {
      const res = await dispatch(authActions.signInGoogle({ accessToken: response.accessToken }));
      if (res.payload.status === 200) {
        if (redirectToAfterAuth === 'cv') {
          navigate('/my-cv/create/');
        } else if (redirectToAfterAuth === 'cover') {
          navigate('/my-covers/create/');
        } else {
          navigate('/account/');
        }
      }
    }
  };

  const handleSignInFacebook = async (response) => {
    if (response.accessToken) {
      const res = await dispatch(authActions.signInFacebook({ accessToken: response.accessToken }));
      if (res.payload.status === 200) {
        if (redirectToAfterAuth === 'cv') {
          navigate('/my-cv/create/');
        } else if (redirectToAfterAuth === 'cover') {
          navigate('/my-covers/create/');
        } else {
          navigate('/account/');
        }
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} fullscreen="sm-down">
      <Modal.Header>
        <Link to="/" className="modal-sm-logo">
          {translate('cv.title')}
        </Link>
        <button className="modal-closeBtn" type="button" onClick={handleClose}>
          <svg id="close" width="24" height="24" viewBox="0 0 24 24">
            <use xlinkHref={`${sprite}#close`} />
          </svg>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="modal__titlewrap">
          <div className="modal__title text-center">Sign In to Your Account</div>
        </div>
        <div className="modal__sign-in">
          <div className="modal__input">
            <FacebookLogin
              appId={facebookID}
              callback={handleSignInFacebook}
              render={(renderProps) => (
                <button
                  type="button"
                  className="btn btn-soc btn-full"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled || fixWaiter}
                >
                  <svg width="30" height="30" viewBox="0 0 30 30">
                    <use xlinkHref={`${sprite}#fb-auth`} />
                  </svg>
                  {translate('components.sign_in_face')}
                </button>
              )}
            />
          </div>
          <div className="modal__input">
            <GoogleLogin
              clientId={googleID}
              render={(renderProps) => (
                <button
                  type="button"
                  className="btn btn-soc btn-full"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled || fixWaiter}
                >
                  <svg width="29" height="28" viewBox="0 0 29 28">
                    <use xlinkHref={`${sprite}#google`} />
                  </svg>
                  {translate('components.sign_in_goo')}
                </button>
              )}
              onSuccess={handleSignInGoogle}
              onFailure={handleSignInGoogle}
              cookiePolicy="single_host_origin"
            />
          </div>
          <div className="modal__bot">
            {translate('components.register_with')}
            <button
              type="button"
              onClick={() => {
                handleClose();
                handleShowSignUp();
              }}
            >
              {translate('cover_create.email')}
            </button>
          </div>
          <div className="modal__bot mt-2">
            {translate('components.already_have')}
            <button
              type="button"
              onClick={() => {
                handleClose();
                handleShowSignIn();
              }}
            >
              {translate('components.login')}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

SignSoc.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleShowSignUp: PropTypes.func.isRequired,
  handleShowSignIn: PropTypes.func.isRequired,
};

export default SignSoc;
