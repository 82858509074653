import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import sprite from 'src/assets/sprite.svg';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { clearErrors } from 'src/store/reducers/auth.reducer';
import * as Yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(Yup);

const SignUp = ({ show, handleClose, handleShowSignIn }) => {
  const { translate } = useTranslation();
  const errors = useSelector((state) => state.authReducer.errors);
  const fixWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const redirectToAfterAuth = useSelector((state) => state.authReducer.redirectToAfterAuth);
  const [showPass, setShowPass] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => () => dispatch(clearErrors()), []);

  const SignUpSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    password: Yup.string().required('Required').min(8, '').minNumbers(1),
    email: Yup.string().email('Invalid email').required('Required'),
  });

  const handleSubmit = async (values) => {
    const res = await dispatch(authActions.signUp(values));

    if (res.payload.status === 200) {
      if (redirectToAfterAuth === 'cv') {
        navigate('/my-cv/create/');
      } else if (redirectToAfterAuth === 'cover') {
        navigate('/my-covers/create/');
      } else {
        navigate('/account/');
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
    },
    validationSchema: SignUpSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Modal show={show} onHide={handleClose} fullscreen="sm-down">
      <Modal.Header>
        <Link to="/" className="modal-sm-logo">
          {translate('cv.title')}
        </Link>
        <button className="modal-closeBtn" type="button" onClick={handleClose}>
          <svg id="close" width="24" height="24" viewBox="0 0 24 24">
            <use xlinkHref={`${sprite}#close`} />
          </svg>
        </button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="modal__titlewrap">
            <div className="modal__title">{translate('components.new_account')}</div>
            <div className="modal__text">{translate('components.get_started')}</div>
          </div>
          {errors && (
            <>
              {errors.message && <Alert>{errors.message}</Alert>}
              {errors.email && <Alert>{errors.email}</Alert>}
            </>
          )}
          <div className="modal__sign-in">
            <div className="modal__input">
              <input
                className={`form-control ${
                  formik.errors.name && formik.touched.name ? 'is-invalid' : ''
                }`}
                type="name"
                placeholder="Name"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.name && formik.touched.name ? (
                <div className="invalid-feedback">{formik.errors.name}</div>
              ) : null}
            </div>
            <div className="modal__input">
              <input
                className={`form-control ${
                  formik.errors.email && formik.touched.email ? 'is-invalid' : ''
                }`}
                type="email"
                placeholder="Email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.email && formik.touched.email ? (
                <div className="invalid-feedback">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="modal__input">
              <div
                className={`input-pass ${
                  formik.errors.password && formik.touched.password ? 'is-invalid' : ''
                }`}
              >
                <input
                  className={`form-control ${
                    formik.errors.password && formik.touched.password ? 'is-invalid' : ''
                  }`}
                  type={showPass ? 'text' : 'password'}
                  placeholder="Password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <button
                  type="button"
                  className="input-pass__btn"
                  onClick={() => setShowPass(!showPass)}
                >
                  <svg id="show-pass" width="19" height="18" viewBox="0 0 19 18">
                    <use xlinkHref={`${sprite}#${!showPass ? 'show-pass' : 'hide-pass'}`} />
                  </svg>
                </button>
              </div>
              {formik.errors.password && formik.touched.password ? (
                <div className="invalid-feedback">{formik.errors.password}</div>
              ) : null}
            </div>
            <div className="checkboxes">
              <label htmlFor="subscribe" className="checkboxes__label">
                <input type="checkbox" id="subscribe" />
                <div className="checkbox__check" />

                <span className="checkbox__text">Subscribe to newsletters</span>
              </label>
              <label htmlFor="subscribe2" className="checkboxes__label">
                <input type="checkbox" id="subscribe2" required />
                <div className="checkbox__check" />

                <span className="checkbox__text">
                  {translate('components.accept_1')}{' '}
                  <Link to="/">{translate('components.accept_2')}</Link>{' '}
                  {translate('components.accept_3')}{' '}
                  <Link to="/">{translate('components.accept_4')}</Link>{' '}
                </span>
              </label>
            </div>

            <div className="modal__input">
              <button type="submit" className="btn btn-primary btn-full" disabled={fixWaiter}>
                {fixWaiter ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{translate('loading')}</span>
                  </>
                ) : (
                  <> {translate('components.create_account')}</>
                )}
              </button>
            </div>

            <div className="modal__bot">
              {translate('components.have_account')}
              <button
                type="button"
                onClick={() => {
                  handleClose();
                  handleShowSignIn();
                }}
              >
                {translate('components.login')}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

SignUp.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleShowSignIn: PropTypes.func.isRequired,
};

export default SignUp;
