import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import cvDefault from 'src/assets/img/cv/cv-default.jpg';
import sprite from 'src/assets/sprite.svg';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { templatesActions } from 'src/store/actions';
import { sethaveCV } from 'src/store/reducers/auth.reducer';
import {
  setCurrentResume,
  setRedirectTo,
  setShowCvPreview,
} from 'src/store/reducers/templates.reducer';

import DeleteResume from './delete-resume';

const Resumes = ({ showTitle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const { width } = useWindowDimensions();
  const resumeWaiter = useSelector((state) => state.templatesReducer.resumeWaiter);
  const resumes = useSelector((state) => state.templatesReducer.resumes);
  const [latest, setLatest] = useState(true);
  const { subscription } = useSelector((state) => state.authReducer.user);

  const [resumeId, setResumeId] = useState(0);
  const [deleteResume, setDeleteResume] = useState(false);

  useEffect(() => {
    dispatch(templatesActions.getResumes(latest));
  }, [latest]);

  const handleShowDeleteResume = () => setDeleteResume(true);
  const handleCloseDeleteResume = () => setDeleteResume(false);

  const handleDownload = (item) => {
    dispatch(setCurrentResume(item));
    dispatch(setShowCvPreview(true));
  };

  const handleDeleteResume = async () => {
    await dispatch(templatesActions.resumesDelete({ resume: resumeId, latest }));
    if (latest) {
      dispatch(templatesActions.getResumes(latest));
    }

    handleCloseDeleteResume();
  };

  const handelRedirect = (id) => {
    dispatch(setRedirectTo(`/my-cv/create/templates/?id=${id}`));
    navigate('/choose');
  };

  useEffect(() => {
    if (resumes.length === 0 && !subscription) {
      dispatch(sethaveCV(false));
    } else {
      dispatch(sethaveCV(true));
    }
  }, [resumes]);

  return (
    <>
      <div className="dash__block">
        <div className="dash__topMob">{latest ? 'Latest' : 'All'} CVs</div>
        <div className="dash__top">
          {showTitle ? 'RESUMES' : <div />}

          <div className="dash__btns">
            <button
              className={`btn btn-outline-primary btn-outline--purple ${latest ? 'active' : ''}`}
              type="button"
              onClick={() => setLatest(true)}
            >
              {translate('components.latest')}
            </button>
            <button
              className={`btn btn-outline-primary btn-outline--purple ${!latest ? 'active' : ''}`}
              type="button"
              onClick={() => setLatest(false)}
            >
              {translate('components.all')}
            </button>
          </div>
        </div>
        <div className="dash__content">
          <div className="dash__list">
            <div className="dash__item dash__item--create">
              <Link to="/my-cv/create/" className="dash__create">
                <svg id="big-pencil" width="33" height="32" viewBox="0 0 33 32">
                  <use xlinkHref={`${sprite}#big-pencil`} />
                </svg>
                {translate('components.ban')}
                <br /> {translate('Home.resume')}
              </Link>
            </div>
            {resumeWaiter ? (
              <>
                <div className="dash__item dash__item--load" width="170" height="234" />
                {width > 450 && (
                  <>
                    <div className="dash__item dash__item--load" width="170" height="234" />
                    <div className="dash__item dash__item--load" width="170" height="234" />
                    <div className="dash__item dash__item--load" width="170" height="234" />
                  </>
                )}
              </>
            ) : (
              <>
                {resumes &&
                  resumes.map((item, index) => {
                    if ((latest && index < 4) || !latest) {
                      return (
                        <div className="dash__item" key={item.id}>
                          <div className="dash__img">
                            <div className="dash__item__actions">
                              <Link to={`/my-cv/create/?id=${item.id}`} className="btn btn-primary">
                                <svg width="24" height="24" viewBox="0 0 24 24">
                                  <use xlinkHref={`${sprite}#edit`} />
                                </svg>
                                {translate('components.edit')}
                              </Link>
                              {subscription ? (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => handleDownload(item)}
                                >
                                  <svg width="24" height="24" viewBox="0 0 24 24">
                                    <use xlinkHref={`${sprite}#download2`} />
                                  </svg>
                                  {translate('components.download')}
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => {
                                    handelRedirect(item.id);
                                  }}
                                  className="btn btn-primary"
                                >
                                  <svg width="24" height="24" viewBox="0 0 24 24">
                                    <use xlinkHref={`${sprite}#download2`} />
                                  </svg>
                                  {translate('components.download')}
                                </button>
                              )}

                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                  setResumeId(item.id);
                                  handleShowDeleteResume();
                                }}
                              >
                                <svg width="24" height="24" viewBox="0 0 24 24">
                                  <use xlinkHref={`${sprite}#delete`} />
                                </svg>
                                {translate('components.delete')}
                              </button>
                            </div>

                            <img
                              src={item.preview ? item.preview : cvDefault}
                              width="170"
                              height="234"
                              alt="img"
                            />
                          </div>
                          <div className="dash__data">
                            {item.updated ? (
                              <>
                                <span>{translate('components.updated')}:</span>
                                {item.updated}
                              </>
                            ) : (
                              <>
                                <span>{translate('components.created')}:</span>
                                {item.created}
                              </>
                            )}
                          </div>
                        </div>
                      );
                    }
                    return false;
                  })}
              </>
            )}
          </div>
        </div>
        <div className="dash__botMob">
          {latest && (
            <div className="dash__more">
              <button type="button" onClick={() => setLatest(false)}>
                See all
              </button>
            </div>
          )}

          <div className="btn-center">
            <Link to="/my-cv/create/" type="button" className="btn btn-primary">
              Build a New CV
            </Link>
          </div>
        </div>
      </div>
      <DeleteResume
        handleDeleteResume={handleDeleteResume}
        show={deleteResume}
        handleClose={handleCloseDeleteResume}
      />
    </>
  );
};
Resumes.propTypes = {
  showTitle: PropTypes.bool.isRequired,
};
export default Resumes;
