import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { months } from 'src/constants';
import { getDataFormat } from 'src/helpers';

import s from './style.module.scss';

const GreyAndWhiteMinimal = ({ setOnRendered, setOverHeight }) => {
  const { currentResume } = useSelector((state) => state.templatesReducer);
  useEffect(() => {
    setOverHeight(1062);
  });
  const { image } = currentResume;

  useEffect(() => {
    const img = document.getElementById('avatar');
    if (img) {
      const imgAvatar = document.getElementById('avatar');
      imgAvatar.onload = () => {
        setOnRendered(true);
      };
    } else {
      setOnRendered(true);
    }
  }, []);

  return (
    <>
      {currentResume && (
        <div className={s.cari}>
          <div className={s.cari__left}>
            <div className="section-col">
              <div className={`section ${s.section}`}>
                {image && (
                  <div className={s.avatar__wrap}>
                    <div className={s.avatar}>
                      <img width={265} src={image} alt="avatar" id="avatar" />
                    </div>
                  </div>
                )}
              </div>

              <div className={`section section-first-page ${s.section}`}>
                <div className={s.title__wrap}>
                  <div className={s.title}>
                    {currentResume.firstName} {currentResume.lastName}
                  </div>
                  <div className={s.title2}>{currentResume.jobTitle}</div>
                </div>
              </div>

              <div className={`section ${s.section} ${s.section__left__first}`}>
                <div className={s.about}>
                  <div className={s.name}>Contacts</div>

                  <div className={s.about__block}>{currentResume.phone}</div>

                  <div className={s.about__block}>{currentResume.email}</div>

                  <div className={s.about__block}>
                    {currentResume.country.name}, {currentResume.city.name} {currentResume.state}{' '}
                    {currentResume.zip}
                  </div>
                </div>
              </div>

              {currentResume.languages.length > 0 && (
                <div className={`section ${s.section}`}>
                  <div className={s.about}>
                    <div className={s.name}>Languagess</div>
                    {currentResume.languages.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.language} - {item.proficiency}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {currentResume.hardSkills.length > 0 && (
                <div className={`section ${s.section}`}>
                  <div className={s.about}>
                    <div className={s.name}>Hard skills</div>
                    {currentResume.hardSkills.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.skill}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {currentResume.softSkills.length > 0 && (
                <div className={`section ${s.section}`}>
                  <div className={s.about}>
                    <div className={s.name}>Soft skills</div>
                    {currentResume.softSkills.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.skill}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={s.cari__right}>
            <div className="section-col">
              {currentResume.experiences.length > 0 && (
                <div className={`section ${s.section}`}>
                  {currentResume.experiences.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Experiences</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__job}>{item.jobTitle}</div>
                          <div className={s.work__top}>
                            <div className={s.work__name}>{item.companyName}</div>
                            <div className={s.work__data}>
                              {getDataFormat(item.startDate)} -{' '}
                              {item.isPresent ? 'Present' : getDataFormat(item.endDate)}
                            </div>
                          </div>

                          <div className={s.text}>{item.responsibilities}</div>
                          <div className={s.text}>{item.achievements}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.educations.length > 0 && (
                <div className={`section ${s.section}`}>
                  {currentResume.educations.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Education</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__job}>{item.universityName}</div>
                          <div className={s.work__data}>
                            {months[item.graduationMonth].name} {item.graduationYear}
                          </div>

                          <div className={s.work__name}>
                            {item.country.name}, {item.city.name}
                          </div>

                          <div className={s.text}>{item.achievements}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.hobbies.length > 0 && (
                <div className={`section ${s.section}`}>
                  {currentResume.hobbies.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Hobbies</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__job}>{item.name}</div>

                          <div className={s.text}>{item.description}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.certificates.length > 0 && (
                <div className={`section ${s.section}`}>
                  {currentResume.certificates.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Certifications</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__job}>{item.name}</div>

                          <div className={s.text}>{item.skills}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

GreyAndWhiteMinimal.propTypes = {
  setOnRendered: PropTypes.func.isRequired,
  setOverHeight: PropTypes.func.isRequired,
};
export default GreyAndWhiteMinimal;
