import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'src/hooks';
import { templatesActions } from 'src/store/actions';

const ShowMoreButton = ({ id, index, action }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslation();

  const meta = useSelector((state) => state.templatesReducer.meta);

  const [page, setPage] = useState(2);
  const [hidden, setHidden] = useState(false);

  const handelShowMoreBlogsClick = async () => {
    await dispatch(templatesActions.getBlogsWithSectionsAndPage({ section: id, page, index }));
    setPage(page + 1);
    setHidden(page === meta.last_page);
  };

  const handelShowMoreAllBlogsClick = async () => {
    await dispatch(templatesActions.getAllBlogsWithSectionsAndPage({ page }));
    setPage(page + 1);
    setHidden(page === meta.last_page);
  };

  return (
    <div className="btn-center">
      <button
        type="button"
        className="btn btn-primary"
        hidden={hidden}
        onClick={action ? handelShowMoreBlogsClick : handelShowMoreAllBlogsClick}
      >
        {translate('blog.show_more')}
      </button>
    </div>
  );
};

ShowMoreButton.propTypes = {
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  action: PropTypes.bool.isRequired,
};

export default ShowMoreButton;
