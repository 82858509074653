import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { months } from 'src/constants';
import { getDataFormat } from 'src/helpers';

import s from './style.module.scss';

const GreenAndGrey = ({ setOnRendered }) => {
  const { currentResume } = useSelector((state) => state.templatesReducer);

  const { image } = currentResume;

  useEffect(() => {
    const img = document.getElementById('avatar');
    if (img) {
      const imgAvatar = document.getElementById('avatar');
      imgAvatar.onload = () => {
        setOnRendered(true);
      };
    } else {
      setOnRendered(true);
    }
  }, []);

  return (
    <>
      {currentResume && (
        <div className={s.cari}>
          <div className={s.cari__left}>
            <div className="section-col">
              <div className="section">
                {image && (
                  <div className={s.avatar__wrap}>
                    <div className={s.avatar}>
                      <img width={275} src={image} alt="avatar" id="avatar" />
                    </div>
                  </div>
                )}
              </div>
              <div className="section">
                <div className={s.title__wrap}>
                  <div className={s.title}>
                    <div>{currentResume.firstName}</div>
                    <div>{currentResume.lastName}</div>
                  </div>
                  <div className={s.title2}>{currentResume.jobTitle}</div>
                </div>
              </div>
              <div className="section">
                <div className={s.about}>
                  <div className={s.name}>Contacts</div>

                  <div className={s.text}>{currentResume.phone}</div>

                  <div className={s.text}>{currentResume.email}</div>

                  <div className={s.text}>
                    {currentResume.country.name}, {currentResume.city.name}
                    <div className={s.text}>
                      {currentResume.state} {currentResume.zip}
                    </div>
                  </div>
                </div>
              </div>

              {currentResume.languages.length > 0 && (
                <div className="section">
                  <div className={s.about}>
                    <div className={s.name}>Languagess</div>
                    {currentResume.languages.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.language} - {item.proficiency}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {currentResume.hardSkills.length > 0 && (
                <div className="section">
                  <div className={s.about}>
                    <div className={s.name}>Hard skills</div>
                    {currentResume.hardSkills.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.skill}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {currentResume.softSkills.length > 0 && (
                <div className="section">
                  <div className={s.about}>
                    <div className={s.name}>Soft skills</div>
                    {currentResume.softSkills.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.skill}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={s.cari__right}>
            <div className="section-col">
              {currentResume.experiences.length > 0 && (
                <div className="section">
                  {currentResume.experiences.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              height="24"
                            >
                              <path d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z" />
                            </svg>
                          </span>
                          Experiences
                        </div>

                        <div className={s.work__wrap}>
                          <div className={s.work__job}>{item.companyName}</div>
                          <div className={s.work__data}>
                            {getDataFormat(item.startDate)} -{' '}
                            {item.isPresent ? 'Present' : getDataFormat(item.endDate)}
                          </div>
                          <div className={s.work__name}>{item.jobTitle}</div>
                          <div className={s.text}>{item.responsibilities}</div>
                          <div className={s.text}>{item.achievements}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.educations.length > 0 && (
                <div className="section">
                  {currentResume.educations.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 0 640 512"
                            >
                              <path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" />
                            </svg>
                          </span>
                          Education
                        </div>

                        <div className={s.work__wrap}>
                          <div className={s.work__data}>
                            {months[item.graduationMonth].name} {item.graduationYear}
                          </div>
                          <div className={s.work__job}>{item.universityName}</div>
                          <div className={s.work__name}>
                            {item.country.name}, {item.city.name}
                          </div>

                          <div className={s.text}>{item.achievements}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.hobbies.length > 0 && (
                <div className="section">
                  {currentResume.hobbies.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 0 640 512"
                            >
                              <path d="M280 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h57.7l16.4 30.3L256 192l-45.3-45.3c-12-12-28.3-18.7-45.3-18.7H64c-17.7 0-32 14.3-32 32v32h96c88.4 0 160 71.6 160 160c0 11-1.1 21.7-3.2 32h70.4c-2.1-10.3-3.2-21-3.2-32c0-52.2 25-98.6 63.7-127.8l15.4 28.6C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L418.2 128H480c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H459.6c-7.5 0-14.7 2.6-20.5 7.4L391.7 78.9l-14-26c-7-12.9-20.5-21-35.2-21H280zM462.7 311.2l28.2 52.2c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-28.2-52.2c2.3-.3 4.7-.4 7.1-.4c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64c0-15.5 5.5-29.7 14.7-40.8zM187.3 376c-9.5 23.5-32.5 40-59.3 40c-35.3 0-64-28.7-64-64s28.7-64 64-64c26.9 0 49.9 16.5 59.3 40h66.4C242.5 268.8 190.5 224 128 224C57.3 224 0 281.3 0 352s57.3 128 128 128c62.5 0 114.5-44.8 125.8-104H187.3zM128 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
                            </svg>
                          </span>
                          Hobbies
                        </div>

                        <div className={s.work__wrap}>
                          <div className={s.work__job}>{item.name}</div>

                          <div className={s.text}>{item.description}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.certificates.length > 0 && (
                <div className="section">
                  {currentResume.certificates.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 0 384 512"
                            >
                              <path d="M173.8 5.5c11-7.3 25.4-7.3 36.4 0L228 17.2c6 3.9 13 5.8 20.1 5.4l21.3-1.3c13.2-.8 25.6 6.4 31.5 18.2l9.6 19.1c3.2 6.4 8.4 11.5 14.7 14.7L344.5 83c11.8 5.9 19 18.3 18.2 31.5l-1.3 21.3c-.4 7.1 1.5 14.2 5.4 20.1l11.8 17.8c7.3 11 7.3 25.4 0 36.4L366.8 228c-3.9 6-5.8 13-5.4 20.1l1.3 21.3c.8 13.2-6.4 25.6-18.2 31.5l-19.1 9.6c-6.4 3.2-11.5 8.4-14.7 14.7L301 344.5c-5.9 11.8-18.3 19-31.5 18.2l-21.3-1.3c-7.1-.4-14.2 1.5-20.1 5.4l-17.8 11.8c-11 7.3-25.4 7.3-36.4 0L156 366.8c-6-3.9-13-5.8-20.1-5.4l-21.3 1.3c-13.2 .8-25.6-6.4-31.5-18.2l-9.6-19.1c-3.2-6.4-8.4-11.5-14.7-14.7L39.5 301c-11.8-5.9-19-18.3-18.2-31.5l1.3-21.3c.4-7.1-1.5-14.2-5.4-20.1L5.5 210.2c-7.3-11-7.3-25.4 0-36.4L17.2 156c3.9-6 5.8-13 5.4-20.1l-1.3-21.3c-.8-13.2 6.4-25.6 18.2-31.5l19.1-9.6C65 70.2 70.2 65 73.4 58.6L83 39.5c5.9-11.8 18.3-19 31.5-18.2l21.3 1.3c7.1 .4 14.2-1.5 20.1-5.4L173.8 5.5zM272 192a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM1.3 441.8L44.4 339.3c.2 .1 .3 .2 .4 .4l9.6 19.1c11.7 23.2 36 37.3 62 35.8l21.3-1.3c.2 0 .5 0 .7 .2l17.8 11.8c5.1 3.3 10.5 5.9 16.1 7.7l-37.6 89.3c-2.3 5.5-7.4 9.2-13.3 9.7s-11.6-2.2-14.8-7.2L74.4 455.5l-56.1 8.3c-5.7 .8-11.4-1.5-15-6s-4.3-10.7-2.1-16zm248 60.4L211.7 413c5.6-1.8 11-4.3 16.1-7.7l17.8-11.8c.2-.1 .4-.2 .7-.2l21.3 1.3c26 1.5 50.3-12.6 62-35.8l9.6-19.1c.1-.2 .2-.3 .4-.4l43.2 102.5c2.2 5.3 1.4 11.4-2.1 16s-9.3 6.9-15 6l-56.1-8.3-32.2 49.2c-3.2 5-8.9 7.7-14.8 7.2s-11-4.3-13.3-9.7z" />
                            </svg>
                          </span>
                          Certifications
                        </div>

                        <div className={s.work__wrap}>
                          <div className={s.work__job}>{item.name}</div>

                          <div className={s.text}>{item.skills}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

GreenAndGrey.propTypes = {
  setOnRendered: PropTypes.func.isRequired,
};
export default GreenAndGrey;
