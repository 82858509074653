import { Resumes } from 'src/components';
import { useTranslation } from 'src/hooks';

const MyCv = () => {
  const { translate } = useTranslation();
  return (
    <div className="wrap content">
      <div className="content__wrap">
        <div className="content__tilte">{translate('Home.cv')}</div>
        <div className="content__text">{translate('Home.cv_desc')}</div>
      </div>
      <div className="dash">
        <Resumes showTitle={false} />
      </div>
    </div>
  );
};

export default MyCv;
