import './style.scss';

import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sprite from 'src/assets/sprite.svg';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import * as Yup from 'yup';

const EmailField = () => {
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const fixWaiter = useSelector((state) => state.authReducer.passwofdFiledWaiter);

  const validationSchema = Yup.object().shape({
    password: Yup.string().min(5, 'Too Short!').required('Required'),
  });

  const handleSubmit = async (values) => {
    const res = await dispatch(authActions.changeAccountPassword(values));
    if (res.payload.status === 200) {
      setEditMode(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      password: '*******',
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = () => {
    formik.handleSubmit();
  };

  return (
    <div>
      <div className="sett__title2">
        {translate('account.password')}
        {fixWaiter ? (
          <div className="error">
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            <span className="sr-only">{translate('loading')}</span>
          </div>
        ) : (
          <button type="button" onClick={editMode ? handleSave : handleEdit}>
            <svg width="20" height="20" viewBox="0 0 20 20">
              <use xlinkHref={`${sprite}#pencil`} />
            </svg>
            {editMode ? 'save' : 'edit'}
          </button>
        )}
      </div>
      {editMode ? (
        <form onSubmit={formik.handleSubmit}>
          <input
            type="password"
            name="password"
            id="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            className="sett__text input__border"
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="error">{formik.errors.password}</div>
          ) : null}
        </form>
      ) : (
        <div>
          <div className="sett__text">{formik.values.password.replace(/./g, '*')}</div>
        </div>
      )}
    </div>
  );
};

export default EmailField;
