import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { months } from 'src/constants';
import { getDataFormat } from 'src/helpers';

import s from './style.module.scss';

const Laura = ({ setOnRendered }) => {
  const { currentResume } = useSelector((state) => state.templatesReducer);

  const { image } = currentResume;

  useEffect(() => {
    const img = document.getElementById('avatar');
    if (img) {
      const imgAvatar = document.getElementById('avatar');
      imgAvatar.onload = () => {
        setOnRendered(true);
      };
    } else {
      setOnRendered(true);
    }
  }, []);

  const getSlice = (value, size) => {
    const arraySize = size;

    const slicedArray = [];

    for (let i = 0; i < value.length; i += arraySize) {
      slicedArray.push({ id: i, childs: value.slice(i, i + arraySize) });
    }

    return slicedArray;
  };

  const experiences =
    currentResume.experiences.length > 0 ? getSlice(currentResume.experiences, 2) : [];
  const hobbies = currentResume.hobbies.length > 0 ? getSlice(currentResume.hobbies, 2) : [];
  const educations =
    currentResume.educations.length > 0 ? getSlice(currentResume.educations, 2) : [];
  const certificates =
    currentResume.certificates.length > 0 ? getSlice(currentResume.certificates, 2) : [];

  return (
    <>
      {currentResume && (
        <div className={s.laura}>
          <div className="section-col">
            <div className="section">
              <div className={s.laura__name}>
                <div className={s.laura__wrap}>
                  {currentResume.firstName} {currentResume.lastName}
                  {currentResume.jobTitle && <span>{currentResume.jobTitle}</span>}
                </div>
              </div>
            </div>
            <div className={s.laura__body}>
              <div className="section">
                <div className={s.laura__main}>
                  {image && (
                    <div className={s.laura__avatar}>
                      <div className={s.laura__avatar__img}>
                        <img width={141} src={image} alt="avatar" id="avatar" />
                      </div>
                    </div>
                  )}

                  <div className={s.laura__contacts}>
                    <div className={s.laura__title}>Contacts</div>
                    <div className={s.laura__contacts__wrap}>
                      <div className={s.laura__contacts__item}>
                        <svg
                          id="location"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-geo-alt"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                        {currentResume.country.name}, {currentResume.city.name}
                        {currentResume.state && `, ${currentResume.state}`}
                        {currentResume.zip && `, zip: ${currentResume.zip}`}
                      </div>
                      {currentResume.email && (
                        <div className={s.laura__contacts__item}>
                          <svg
                            id="mail"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-envelope"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                          </svg>
                          {currentResume.email}
                        </div>
                      )}

                      {currentResume.phone && (
                        <div className={s.laura__contacts__item}>
                          <svg
                            id="phone"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-telephone"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                          </svg>
                          {currentResume.phone}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {experiences.length && (
                <div className="section">
                  <div className={s.laura__works}>
                    {experiences.map((item) => (
                      <div className="sub-section" key={item.id}>
                        <div className={s.laura__works__wrap}>
                          {item.childs.map((work) => (
                            <div className={s.laura__works__item} key={work.id}>
                              <div className={s.laura__works__job}>{work.jobTitle}</div>
                              <div className={s.laura__works__name}>
                                <div className={s.text_upper}>{work.companyName}</div>
                                <div>
                                  {getDataFormat(item.startDate)} -{' '}
                                  {item.isPresent ? 'Present' : getDataFormat(item.endDate)}
                                </div>
                                <div>
                                  {work.country.name} {work.city.name}
                                </div>
                              </div>

                              <div className={s.laura__works__text}>
                                {work.responsibilities}
                                {work.achievements}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {hobbies.length > 0 && (
                <div className="section">
                  <div className={`${s.laura__works} ${s.laura__works__another}`}>
                    {hobbies.map((item, index) => (
                      <div className="sub-section" key={item.id}>
                        {index === 0 && <div className={s.laura__works__title}>Hobbies</div>}
                        <div className={s.laura__works__wrap}>
                          {item.childs.map((child) => (
                            <div className={s.laura__works__item} key={child.id}>
                              <div className={s.laura__works__job}>{child.name}</div>

                              <div className={s.laura__works__text}>{child.description}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {educations.length > 0 && (
                <div className="section">
                  <div className={`${s.laura__works} ${s.laura__works__another}`}>
                    {educations.map((item, index) => (
                      <div className="sub-section" key={item.id}>
                        {index === 0 && <div className={s.laura__works__title}>Educations</div>}
                        <div className={s.laura__works__wrap}>
                          {item.childs.map((child) => (
                            <div className={s.laura__works__item} key={child.id}>
                              <div className={s.laura__works__job}>{child.universityName}</div>
                              <div className={s.laura__works__name}>
                                <div className={s.text_upper}>
                                  {child.degree.name} {child.field.name}
                                </div>
                                <div>
                                  {months[child.graduationMonth].name} {child.graduationYear}
                                </div>
                                <div>
                                  {child.country.name}, {child.city.name}
                                </div>
                              </div>
                              <div className={s.laura__works__text}>{child.achievements}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {certificates.length > 0 && (
                <div className="section">
                  <div className={`${s.laura__works} ${s.laura__works__another}`}>
                    {certificates.map((item, index) => (
                      <div className="sub-section" key={item.id}>
                        {index === 0 && <div className={s.laura__works__title}>Certificates</div>}
                        <div className={s.laura__works__wrap}>
                          {item.childs.map((child) => (
                            <div className={s.laura__works__item} key={child.id}>
                              <div className={s.laura__works__job}>{child.name}</div>

                              <div className={s.laura__works__text}>{child.skills}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {(currentResume.hardSkills.length > 0 ||
                currentResume.softSkills.length > 0 ||
                currentResume.languages.length > 0) && (
                <div className="section">
                  <div className={s.laura__footer}>
                    {currentResume.hardSkills.length > 0 && (
                      <div className={s.laura__footer__col}>
                        <div className={s.laura__footer__title}>Hard skills</div>
                        <div className={s.laura__footer__list}>
                          {currentResume.hardSkills.map((item) => (
                            <div className={s.laura__footer__item} key={item.id}>
                              {item.skill}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {currentResume.softSkills.length > 0 && (
                      <div className={s.laura__footer__col}>
                        <div className={s.laura__footer__title}>Soft skills</div>
                        <div className={s.laura__footer__list}>
                          {currentResume.softSkills.map((item) => (
                            <div className={s.laura__footer__item} key={item.id}>
                              {item.skill}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {currentResume.languages.length > 0 && (
                      <div className={s.laura__footer__col}>
                        <div className={s.laura__footer__title}>Languages</div>
                        <div className={s.laura__footer__list}>
                          {currentResume.languages.map((item) => (
                            <div className={s.laura__footer__item} key={item.id}>
                              {item.language} - {item.proficiency}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Laura.propTypes = {
  setOnRendered: PropTypes.func.isRequired,
};
export default Laura;
