import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import cv from 'src/assets/img/cv/cv.svg';
import cv from 'src/assets/img/cv/cv.png';
import compLg from 'src/assets/img/home/comp-lg.svg';
import compSm from 'src/assets/img/home/comp-sm.svg';
import { FogotPass, SignIn, SignSoc, SignUp } from 'src/components';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { templatesActions } from 'src/store/actions';

import { CvTemplate } from './components';

const CvExamples = () => {
  const ifAuth = !!useSelector((state) => state.authReducer.access_token);
  const resumeTemplates = useSelector((state2) => state2.templatesReducer.resumeTemplates);
  const { translate } = useTranslation();

  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const [numElements, setNumElements] = useState(12);

  useEffect(() => {
    dispatch(templatesActions.getResumeTemplates());
  }, []);

  const handelShowMoreClick = () => {
    setNumElements(numElements + 3);
  };

  const cvToShow = resumeTemplates.slice(0, numElements);

  // foggot
  const [showFoggot, setShowFoggot] = useState(false);
  const handleCloseFoggot = () => setShowFoggot(false);
  const handleShowFoggot = () => setShowFoggot(true);

  // signIn
  const [showSignIn, setShowSignIn] = useState(false);
  const handleCloseSignIn = () => setShowSignIn(false);
  const handleShowSignIn = () => setShowSignIn(true);

  // signIn
  const [showSignUp, setShowSignUp] = useState(false);
  const handleCloseSignUp = () => setShowSignUp(false);
  const handleShowSignUp = () => setShowSignUp(true);

  // signSoc
  const [showSignSoc, setShowSignSoc] = useState(false);
  const handleCloseSignSoc = () => setShowSignSoc(false);
  const handleShowSignSoc = () => setShowSignSoc(true);
  return (
    <>
      <div className="firstGrey">
        <div className="s1--cv">
          <div className="wrap">
            <div className="cvS1">
              <div className="s1__left">
                <div className="s1__t1 s1__t1--grey">{translate('cv.cv_examples')}</div>
                <div className="s1__t2">{translate('cover_create.learn_create')}</div>
                {width <= 565 && width > 500 && <img src={cv} className="cvS1__mobImg" alt="img" />}
                {width <= 500 && <img src={cv} className="cvS1__mobImg" alt="img" />}
                <div className="s1__t3">{translate('cv.cv_examples_desc')}</div>
                {ifAuth ? (
                  <Link to="/my-cv/create/" className="btn btn-primary s1__link">
                    {translate('cv.create_btn')}
                  </Link>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary s1__link"
                    onClick={handleShowSignSoc}
                  >
                    {translate('cv.create_btn')}
                  </button>
                )}
                <div className="s1__comp">
                  <img src={width >= 565 ? compLg : compSm} alt="" />
                </div>
              </div>
              {width > 565 && (
                <div className="cvS1__right" style={{ backgroundImage: `url(${cv})` }} />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="explore cv-examples">
        <div className="wrap">
          <div className="title__wrap">
            <div className="title__label">{translate('cv.examples_lower')}</div>
            <div className="title">{translate('cv.examples_lower_sub')}</div>
            <div className="title__text">{translate('cv.examples_lower_desc')}</div>
          </div>
          <div className="explore__tabs mt-5">
            <div className="explore__text">{translate('cv.examples_exp')}</div>
            <div className="explore__btns" />
          </div>
          <div className="templates__wrap templates__wrap--colonmob">
            {cvToShow.map((item) => (
              <CvTemplate key={item.id} id={item.id} title={item.name} image={item.preview} />
            ))}
          </div>
          <div className="btn-center">
            <button type="button" className="btn btn-primary" onClick={handelShowMoreClick}>
              {translate('cover.show_more')}
            </button>
          </div>
        </div>
      </div>

      {showSignIn && (
        <SignIn
          show={showSignIn}
          handleClose={handleCloseSignIn}
          handleShowSignUp={handleShowSignUp}
          handleShowFoggot={handleShowFoggot}
        />
      )}

      {showSignUp && (
        <SignUp
          show={showSignUp}
          handleClose={handleCloseSignUp}
          handleShowSignIn={handleShowSignIn}
        />
      )}

      {showSignSoc && (
        <SignSoc
          show={showSignSoc}
          handleClose={handleCloseSignSoc}
          handleShowSignUp={handleShowSignUp}
          handleShowSignIn={handleShowSignIn}
        />
      )}

      {showFoggot && (
        <FogotPass
          show={showFoggot}
          handleClose={handleCloseFoggot}
          handleShowSignIn={handleShowSignIn}
        />
      )}
    </>
  );
};

export default CvExamples;
