import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { templatesActions } from 'src/store/actions';

const CitySelector = ({ countyId, changeCity, index, value, name, setFieldValue }) => {
  const dispatch = useDispatch();
  const [reletedCities, setReletedCities] = useState([]);
  const reletedCitiesOptions = reletedCities.map((item) => ({ value: item.id, label: item.name }));
  const [citiesWaiter, setCitiesWaiter] = useState(false);

  const handleCitiesRelatedToCountry = async () => {
    setCitiesWaiter(true);
    const res = await dispatch(templatesActions.getAllCitiesRelatedToCountry({ id: countyId }));
    setReletedCities(res.payload.data);
    setCitiesWaiter(false);
  };

  useEffect(() => {
    handleCitiesRelatedToCountry();
  }, [countyId]);

  useEffect(() => {
    if (reletedCities.length) {
      changeCity(reletedCities[0].id, index);
    }
  }, [reletedCities]);

  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      value={reletedCitiesOptions.filter((item) => String(item.value) === value)[0]}
      isDisabled={citiesWaiter}
      isLoading={citiesWaiter}
      isSearchable
      name={name}
      options={reletedCitiesOptions}
      onChange={(e) => setFieldValue(name, e.value)}
    />
  );
};
CitySelector.propTypes = {
  countyId: PropTypes.string.isRequired,
  changeCity: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
export default CitySelector;
