import { createSlice } from '@reduxjs/toolkit';

import { authActions } from '../actions';
import { getMeRefresh } from '../actions/auth.actions';

const userInitial = {
  id: 0,
  name: '',
  email: '',
  haveCV: false,
  haveCover: false,
};

// Видаляєм токен авторизації через місяць годину
if (+new Date() >= parseInt(localStorage.getItem('blockTime'), 10)) {
  localStorage.clear();
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: userInitial,
    access_token: localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null,
    errors: false,
    fixWaiter: false,
    waiter: false,
    redirectToAfterAuth: '',
  },
  reducers: {
    clearErrors(state) {
      state.errors = false;
    },
    sethaveCV(state, action) {
      state.user.haveCV = action.payload;
    },
    setHaveCover(state, action) {
      state.user.haveCover = action.payload;
    },
    setRedirectToAfterAuth(state, action) {
      state.redirectToAfterAuth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // signIn
      .addCase(authActions.signIn.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.signIn.fulfilled, (state, action) => {
        state.user = action.payload.data.user;
        state.access_token = action.payload.data.token;
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.signIn.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // signInGoogle
      .addCase(authActions.signInGoogle.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.signInGoogle.fulfilled, (state, action) => {
        state.user = action.payload.data.user;
        state.access_token = action.payload.data.token;
        state.signInViaSocialMedia = true;
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.signInGoogle.rejected, (state, action) => {
        state.fixWaiter = false;
        if (action.errors) {
          state.errors = action.payload.errors;
        }
      })

      // signInGoogleModule
      .addCase(authActions.signInGoogleModule.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(authActions.signInGoogleModule.fulfilled, (state, action) => {
        state.user = action.payload.data.user;
        state.access_token = action.payload.data.token;
        state.signInViaSocialMedia = true;
        state.fixWaiter = false;
      })
      .addCase(authActions.signInGoogleModule.rejected, (state) => {
        state.fixWaiter = false;
      })

      // signInFacebook
      .addCase(authActions.signInFacebook.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.signInFacebook.fulfilled, (state, action) => {
        state.user = action.payload.data.user;
        state.access_token = action.payload.data.token;
        state.signInViaSocialMedia = true;
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.signInFacebook.rejected, (state, action) => {
        state.fixWaiter = false;
        if (action.errors) {
          state.errors = action.payload.errors;
        }
      })

      // signUp
      .addCase(authActions.signUp.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.signUp.fulfilled, (state, action) => {
        state.user = action.payload.data.user;
        state.access_token = action.payload.data.token;
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.signUp.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // logout
      .addCase(authActions.logout.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
        state.user = userInitial;
        state.access_token = null;
      })
      .addCase(authActions.logout.fulfilled, (state) => {
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.logout.rejected, (state) => {
        state.user = userInitial;
        state.access_token = null;
        state.fixWaiter = false;
        state.errors = false;
      })

      // forgotPassword
      .addCase(authActions.forgotPassword.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.forgotPassword.fulfilled, (state) => {
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.forgotPassword.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // ressetPassword
      .addCase(authActions.ressetPassword.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.ressetPassword.fulfilled, (state) => {
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.ressetPassword.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // getMe
      .addCase(authActions.getMe.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(authActions.getMe.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.waiter = false;
        state.errors = false;
      })
      .addCase(authActions.getMe.rejected, (state, action) => {
        state.waiter = false;
        state.errors = action.payload.errors;
      })

      // getMeRefresh
      .addCase(authActions.getMeRefresh.fulfilled, (state, action) => {
        state.user = action.payload.data;
      })

      // getAccountSettingsData
      .addCase(authActions.getAccountSubscriptionData.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.getAccountSubscriptionData.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.getAccountSubscriptionData.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // changeAccountEmail
      .addCase(authActions.changeAccountEmail.pending, (state) => {
        state.emailFieldWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.changeAccountEmail.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.emailFieldWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.changeAccountEmail.rejected, (state, action) => {
        state.emailFieldWaiter = false;
        state.errors = action.payload.errors;
      })

      // changeAccountPassword
      .addCase(authActions.changeAccountPassword.pending, (state) => {
        state.passwofdFiledWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.changeAccountPassword.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.passwofdFiledWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.changeAccountPassword.rejected, (state, action) => {
        state.passwofdFiledWaiter = false;
        state.errors = action.payload.errors;
      })

      .addCase(authActions.getUserNotifications.pending, (state) => {
        state.userNatificationWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.getUserNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload.data.notifications;
        state.userHaveUnReadNotification = false;
        state.userNatificationWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.getUserNotifications.rejected, (state, action) => {
        state.userNatificationWaiter = false;
        state.errors = action.payload.errors;
      })

      .addCase(authActions.checkIfUserHaveUnReadNotification.fulfilled, (state, action) => {
        state.userHaveUnReadNotification = action.payload.data.user_have_un_read_notification;
      })

      .addCase(authActions.createSubscription.pending, (state) => {
        state.createSubscriptionWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.createSubscription.fulfilled, (state) => {
        state.createSubscriptionWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.createSubscription.rejected, (state, action) => {
        state.createSubscriptionWaiter = false;
        state.errors = action.payload.errors;
      })

      .addCase(authActions.getInformationAboutSubscription.pending, (state) => {
        state.createSubscriptionWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.getInformationAboutSubscription.fulfilled, (state, action) => {
        state.subscriptionInformation = action.payload.data;
        state.createSubscriptionWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.getInformationAboutSubscription.rejected, (state, action) => {
        state.createSubscriptionWaiter = false;
        state.errors = action.payload.errors;
      })

      .addCase(authActions.createPaymentIntent.pending, (state) => {
        state.createPaymentIntentWaiter = true;
      })
      .addCase(authActions.createPaymentIntent.fulfilled, (state, action) => {
        state.createPaymentIntentWaiter = false;
        state.clientSecret = action.payload.data.client_secret;
      })
      .addCase(authActions.createPaymentIntent.rejected, (state) => {
        state.createPaymentIntentWaiter = false;
      })

      .addCase(authActions.getDataSubscription.fulfilled, (state, action) => {
        state.user.subscription = action.payload.data.client_secret;
      });
  },
});

export const { clearErrors, sethaveCV, setHaveCover, setRedirectToAfterAuth } = authSlice.actions;
export default authSlice.reducer;
