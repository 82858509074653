import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import sprite from 'src/assets/sprite.svg';
import { constants } from 'src/constants';
import { useTranslation } from 'src/hooks';

import TopSidebar from '../top-sidebar';
import { SubscriptionCard } from './components';

const Index = () => {
  const subscription = useSelector((state) => state.authReducer.user.subscription);
  const redirectTo = useSelector((state) => state.templatesReducer.redirectTo);
  const { translate } = useTranslation();

  const subscribedAtLeastOnce = useSelector(
    (state) => state.authReducer.user.subscribed_at_least_once,
  );

  const [subscriptionOnTrial, setSubscriptionOnTrial] = useState(false);

  useEffect(() => {
    if (subscription) {
      setSubscriptionOnTrial(subscription.stripe_price !== constants.product.days.id);
    } else {
      setSubscriptionOnTrial(true);
    }
  }, [subscription]);

  return (
    <>
      <TopSidebar step={2} />
      <div className="create__templates__t">{translate('choose.upgrade_for_instant')}</div>
      <div className="create__choose">
        <div className="create__choose__col create__choose__col--all">
          <div className="create__choose__first">
            <div className="create__choose__t">{translate('choose.subscription_features')}</div>
            <div className="create__choose__fich">
              <svg width="40" height="40" viewBox="0 0 40 40">
                <use xlinkHref={`${sprite}#download`} />
              </svg>
              <span>{translate('choose.download_and_save')}</span>
            </div>
            <div className="create__choose__fich">
              <svg width="40" height="40" viewBox="0 0 40 40">
                <use xlinkHref={`${sprite}#filetext`} />
              </svg>
              <span>{translate('choose.create_a_cover')}</span>
            </div>
            <div className="create__choose__fich">
              <svg width="40" height="40" viewBox="0 0 40 40">
                <use xlinkHref={`${sprite}#money`} />
              </svg>
              <span>{translate('choose.money_refund')}</span>
            </div>
            <div className="create__choose__text">
              {translate('choose.get_inspired_1')} <span>{translate('choose.get_inspired_2')}</span>
            </div>
          </div>
        </div>
        {(subscription || subscribedAtLeastOnce) && subscriptionOnTrial ? (
          <>
            <SubscriptionCard
              name={translate('choose.monthly')}
              price="23,70€"
              productId={constants.product.weeks.id}
              subscription={!!subscription}
              action={
                subscription ? subscription.stripe_price === constants.product.weeks.id : false
              }
              update
              redirectTo={redirectTo}
            >
              <li>{translate('choose.monthly_desc_1')}</li>
              <li>{translate('choose.monthly_desc_2')}</li>
              <li>{translate('choose.monthly_desc_3')}</li>
              <li>{translate('choose.monthly_desc_4')}</li>
            </SubscriptionCard>
            <SubscriptionCard
              name={translate('choose.yearly')}
              price="71,40€"
              productId={constants.product.year.id}
              subscription={!!subscription}
              action={
                subscription ? subscription.stripe_price === constants.product.year.id : false
              }
              update
              redirectTo={redirectTo}
            >
              <li>{translate('yearly_desc_1')}</li>
              <li>{translate('yearly_desc_2')}</li>
              <li>{translate('yearly_desc_3')}</li>
              <li>{translate('yearly_desc_4')}</li>
            </SubscriptionCard>
          </>
        ) : (
          <>
            <SubscriptionCard
              name={translate('choose.day')}
              price="2,70€"
              productId={constants.product.days.id}
              subscription={
                subscription ? subscription.stripe_price === constants.product.days.id : false
              }
              action={
                subscription ? subscription.stripe_price === constants.product.days.id : false
              }
              update={
                subscription ? subscription.stripe_price === constants.product.days.id : false
              }
              redirectTo={redirectTo}
            >
              <li>{translate('choose.day_desc_1')}</li>
              <li>{translate('choose.day_desc_2')}</li>
              <li>{translate('choose.day_desc_3')}</li>
              <li>{translate('choose.day_desc_4')}</li>
            </SubscriptionCard>
            <SubscriptionCard
              name="Monthly Access"
              price="23,70€"
              productId={constants.product.weeks.id}
              subscription={
                subscription ? subscription.stripe_price === constants.product.days.id : false
              }
              action={
                subscription ? subscription.stripe_price === constants.product.weeks.id : false
              }
              update={
                subscription ? subscription.stripe_price === constants.product.days.id : false
              }
              redirectTo={redirectTo}
            >
              <li>{translate('choose.monthly_desc_1')}</li>
              <li>{translate('choose.monthly_desc_2')}</li>
              <li>{translate('choose.monthly_desc_3')}</li>
              <li>{translate('choose.monthly_desc_4')}</li>
            </SubscriptionCard>
          </>
        )}
      </div>
    </>
  );
};

export default Index;
