import { useFormik } from 'formik';
import { gapi } from 'gapi-script';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import sprite from 'src/assets/sprite.svg';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { clearErrors } from 'src/store/reducers/auth.reducer';
import * as Yup from 'yup';

const googleID = process.env.REACT_APP_GOOGLE_SITE_KEY;
const facebookID = process.env.REACT_APP_FB_SITE_KEY;

const SignIn = ({ show, handleClose, handleShowSignUp, handleShowFoggot }) => {
  const errors = useSelector((state) => state.authReducer.errors);
  const { translate } = useTranslation();
  const fixWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const redirectToAfterAuth = useSelector((state) => state.authReducer.redirectToAfterAuth);
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate('/');
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(clearErrors()), []);

  useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.auth2.init({ clientId: googleID });
    });
  });

  const SignInSchema = Yup.object().shape({
    password: Yup.string().min(5, 'Too Short!').required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
  });

  const handleSubmit = async (values) => {
    const res = await dispatch(authActions.signIn(values));

    if (res.payload.status === 200) {
      if (redirectToAfterAuth === 'cv') {
        navigate('/my-cv/create/');
      } else if (redirectToAfterAuth === 'cover') {
        navigate('/my-covers/create/');
      } else {
        navigate('/account/');
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: SignInSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSignInGoogle = async (response) => {
    if (response.accessToken) {
      const res = await dispatch(authActions.signInGoogle({ accessToken: response.accessToken }));
      if (res.payload.status === 200) {
        if (redirectToAfterAuth === 'cv') {
          navigate('/my-cv/create/');
        } else if (redirectToAfterAuth === 'cover') {
          navigate('/my-covers/create/');
        } else {
          navigate('/account/');
        }
      }
    }
  };

  const handleSignInFacebook = async (response) => {
    if (response.accessToken) {
      const res = await dispatch(authActions.signInFacebook({ accessToken: response.accessToken }));
      if (res.payload.status === 200) {
        if (redirectToAfterAuth === 'cv') {
          navigate('/my-cv/create/');
        } else if (redirectToAfterAuth === 'cover') {
          navigate('/my-covers/create/');
        } else {
          navigate('/account/');
        }
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} fullscreen="sm-down">
      <Modal.Header>
        <Link to="/" className="modal-sm-logo">
          {translate('cv.title')}
        </Link>
        <button className="modal-closeBtn" type="button" onClick={handleClose}>
          <svg id="close" width="24" height="24" viewBox="0 0 24 24">
            <use xlinkHref={`${sprite}#close`} />
          </svg>
        </button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="modal__titlewrap">
            <div className="modal__title">{translate('components.sitya')}</div>
            <div className="modal__text">{translate('components.well_back')}</div>
          </div>
          {errors && <Alert>{errors.message}</Alert>}

          <div className="modal__sign-in">
            <div className="modal__input">
              <input
                className={`form-control ${
                  formik.errors.email && formik.touched.email ? 'is-invalid' : ''
                }`}
                type="email"
                placeholder={translate('cover_create.email')}
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.email && formik.touched.email ? (
                <div className="invalid-feedback">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="modal__input">
              <div
                className={`input-pass ${
                  formik.errors.password && formik.touched.password ? 'is-invalid' : ''
                }`}
              >
                <input
                  className={`form-control ${
                    formik.errors.password && formik.touched.password ? 'is-invalid' : ''
                  }`}
                  type={showPass ? 'text' : 'password'}
                  placeholder={translate('components.password')}
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <button
                  type="button"
                  className="input-pass__btn"
                  onClick={() => setShowPass(!showPass)}
                >
                  <svg id="show-pass" width="19" height="18" viewBox="0 0 19 18">
                    <use xlinkHref={`${sprite}#${!showPass ? 'show-pass' : 'hide-pass'}`} />
                  </svg>
                </button>
              </div>
              {formik.errors.password && formik.touched.password ? (
                <div className="invalid-feedback">{formik.errors.password}</div>
              ) : null}
            </div>
            <div className="modal-fogot">
              <button
                type="button"
                onClick={() => {
                  handleClose();
                  handleShowFoggot();
                }}
              >
                {translate('components.forgot_pass')}
              </button>
            </div>
            <div className="modal__input">
              <button type="submit" className="btn btn-primary btn-full" disabled={fixWaiter}>
                {fixWaiter ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{translate('loading')}</span>
                  </>
                ) : (
                  <>{translate('components.log_in')}</>
                )}
              </button>
            </div>
            <div className="modal__input">
              <FacebookLogin
                appId={facebookID}
                callback={handleSignInFacebook}
                render={(renderProps) => (
                  <button
                    type="button"
                    className="btn btn-soc btn-full"
                    onClick={renderProps.onClick}
                  >
                    <svg width="30" height="30" viewBox="0 0 30 30">
                      <use xlinkHref={`${sprite}#fb-auth`} />
                    </svg>
                    {translate('components.sign_in_face')}
                  </button>
                )}
              />
            </div>
            <div className="modal__input">
              <GoogleLogin
                clientId={googleID}
                render={(renderProps) => (
                  <button
                    type="button"
                    className="btn btn-soc btn-full"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <svg width="29" height="28" viewBox="0 0 29 28">
                      <use xlinkHref={`${sprite}#google`} />
                    </svg>
                    {translate('components.sign_in_goo')}
                  </button>
                )}
                onSuccess={handleSignInGoogle}
                onFailure={handleSignInGoogle}
                cookiePolicy="single_host_origin"
              />
            </div>
            <div className="modal__bot">
              {translate('components.dont_acc')}
              <button
                type="button"
                onClick={() => {
                  handleClose();
                  handleShowSignUp();
                }}
              >
                {translate('components.sign_up')}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

SignIn.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleShowSignUp: PropTypes.func.isRequired,
  handleShowFoggot: PropTypes.func.isRequired,
};

export default SignIn;
