import { api } from 'src/api';
import { getHeaders } from 'src/helpers/';

export const getResumes = async (latest) => {
  const result = await api.get(`resumes?all=${latest ? '0' : '1'}`, getHeaders());

  return result;
};

export const getCovers = async (latest) => {
  const result = await api.get(`cover-letters?all=${latest ? '0' : '1'}`, getHeaders());

  return result;
};

export const getResume = async (id) => {
  const result = await api.get(`resumes/show?resume=${id}`, getHeaders());

  return result;
};

export const getCover = async (id) => {
  const result = await api.get(`cover-letters/show?letter=${id}`, getHeaders());

  return result;
};

export const getCountries = async () => {
  const result = await api.get(`countries`, getHeaders());

  return result;
};

export const getAllCitiesRelatedToCountry = async (data) => {
  const result = await api.get(`country/${data.id}/cities`, getHeaders());

  return result;
};

export const getEducationFields = async () => {
  const result = await api.get(`education-fields`, getHeaders());

  return result;
};

export const getEducationDegrees = async () => {
  const result = await api.get(`education-degrees`, getHeaders());

  return result;
};

export const getResumeTemplates = async () => {
  const result = await api.get(`resume-templates`, getHeaders());

  return result;
};

export const resumeCreate = async (data) => {
  const result = await api.post(`resumes/create`, data, getHeaders());

  return result;
};

export const coverCreate = async (data) => {
  const result = await api.post(`cover-letters/create`, data, getHeaders());

  return result;
};

export const resumeExperience = async (data) => {
  const result = await api.post(`resumes/update/experience`, data, getHeaders());

  return result;
};

export const resumeHobbies = async (data) => {
  const result = await api.post(`resumes/update/hobbies`, data, getHeaders());

  return result;
};

export const resumeEducation = async (data) => {
  const result = await api.post(`resumes/update/education`, data, getHeaders());

  return result;
};

export const resumeCertificates = async (data) => {
  const result = await api.post(`resumes/update/certificates`, data, getHeaders());

  return result;
};

export const resumeSkills = async (data) => {
  const result = await api.post(`resumes/update/skills`, data, getHeaders());

  return result;
};

export const resumeLanguages = async (data) => {
  const result = await api.post(`resumes/update/languages`, data, getHeaders());

  return result;
};

export const resumeTemplate = async (data) => {
  const result = await api.post(`resumes/update/template`, data, getHeaders());

  return result;
};

export const coverContent = async (data) => {
  const result = await api.post(`cover-letters/update/content`, data, getHeaders());

  return result;
};

export const getCoversTemplates = async () => {
  const result = await api.get(`cover-letter-templates`, getHeaders());

  return result;
};

export const coverTemplate = async (data) => {
  const result = await api.post(`cover-letters/update/template`, data, getHeaders());

  return result;
};

export const resumesUploadPreview = async (data) => {
  const result = await api.post(`resumes/upload-preview`, data, getHeaders());

  return result;
};

export const coverUploadPreview = async (data) => {
  const result = await api.post(`cover-letters/upload-preview`, data, getHeaders());

  return result;
};

export const resumesDelete = async (data) => {
  const result = await api.delete(`resumes/delete?resume=${data.resume}`, getHeaders());

  return result;
};

export const coverDelete = async (data) => {
  const result = await api.delete(`cover-letters/delete?letter=${data.cover}`, getHeaders());

  return result;
};

export const getBlogs = async () => {
  const result = await api.get('blog', getHeaders());

  return result;
};

export const getBlogsWithSectionsAndPage = async (data) => {
  const result = await api.get(`blog-page?section=${data.section}&page=${data.page}`, getHeaders());

  return result;
};

export const getAllBlogsWithSectionsAndPage = async (data) => {
  const result = await api.get(`blog-all-elements?page=${data.page}`, getHeaders());

  return result;
};

export const getBlog = async (data) => {
  const result = await api.get(`blog/${data.slug}`, getHeaders());

  return result;
};

export const getAllBlogs = async () => {
  const result = await api.get('blog-all-elements', getHeaders());

  return result;
};
