import { Route, Routes } from 'react-router-dom';

import { Choose, Completed, Payment } from './components';

const ChoosePage = () => (
  <div className="create">
    <div className="wrap content">
      <Routes>
        <Route path="/" element={<Choose />} />
        <Route path="/payment/" element={<Payment />} />
        <Route path="/completed/" element={<Completed />} />
      </Routes>
    </div>
  </div>
);

export default ChoosePage;
