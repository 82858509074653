import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import cvDefault from 'src/assets/img/cv/cv-default.jpg';
import sprite from 'src/assets/sprite.svg';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { templatesActions } from 'src/store/actions';
import { setHaveCover } from 'src/store/reducers/auth.reducer';
import {
  setCurrentCover,
  setRedirectTo,
  setShowCoverPreview,
} from 'src/store/reducers/templates.reducer';

import DeleteCover from './delete-cover';

const Covers = ({ showTitle }) => {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const coversWaiter = useSelector((state) => state.templatesReducer.coversWaiter);
  const covers = useSelector((state) => state.templatesReducer.covers);
  const [latest, setLatest] = useState(true);
  const { subscription } = useSelector((state) => state.authReducer.user);
  useEffect(() => {
    dispatch(templatesActions.getCovers(latest));
  }, [latest]);

  const handleDownload = (item) => {
    dispatch(setCurrentCover(item));
    dispatch(setShowCoverPreview(true));
  };
  const [coverId, setCoverId] = useState(0);
  const [deleteCover, setDeleteCover] = useState(false);
  const handleShowDeleteCover = () => setDeleteCover(true);
  const handleCloseDeleteCover = () => setDeleteCover(false);

  const handleDeleteResume = async () => {
    await dispatch(templatesActions.coverDelete({ cover: coverId, latest }));
    if (latest) {
      dispatch(templatesActions.getCovers(latest));
    }
    handleCloseDeleteCover();
  };

  const handelRedirect = (id) => {
    dispatch(setRedirectTo(`/my-covers/create/templates/?id=${id}`));
    navigate('/choose');
  };

  useEffect(() => {
    if (covers.length === 0 && !subscription) {
      dispatch(setHaveCover(false));
    } else {
      dispatch(setHaveCover(true));
    }
  }, [covers]);

  return (
    <>
      <div className="dash__block">
        <div className="dash__topMob">
          {latest ? 'Latest' : 'All'} {translate('components.covers')}
        </div>
        <div className="dash__top">
          {showTitle ? 'COVER LETTERS' : <div />}
          <div className="dash__btns">
            <button
              className={`btn btn-outline-primary btn-outline--purple ${latest ? 'active' : ''}`}
              type="button"
              onClick={() => setLatest(true)}
            >
              {translate('components.latest')}
            </button>
            <button
              className={`btn btn-outline-primary btn-outline--purple ${!latest ? 'active' : ''}`}
              type="button"
              onClick={() => setLatest(false)}
            >
              {translate('components.all')}
            </button>
          </div>
        </div>
        <div className="dash__content">
          <div className="dash__list">
            <div className="dash__item dash__item--create">
              <Link to="/my-covers/create/" className="dash__create">
                <svg id="big-pencil" width="33" height="32" viewBox="0 0 33 32">
                  <use xlinkHref={`${sprite}#big-pencil`} />
                </svg>
                {translate('components.create_cover')}
                <br /> {translate('components.latter')}
              </Link>
            </div>
            {coversWaiter ? (
              <>
                <div className="dash__item dash__item--load" width="170" height="234" />
                {width > 450 && (
                  <>
                    <div className="dash__item dash__item--load" width="170" height="234" />
                    <div className="dash__item dash__item--load" width="170" height="234" />
                    <div className="dash__item dash__item--load" width="170" height="234" />
                  </>
                )}
              </>
            ) : (
              <>
                {covers &&
                  covers.map((item, index) => {
                    if ((latest && index < 4) || !latest) {
                      return (
                        <div className="dash__item" key={item.id}>
                          <div to={`/my-covers/create/?id=${item.id}`} className="dash__img">
                            <div className="dash__item__actions">
                              <Link
                                to={`/my-covers/create/?id=${item.id}`}
                                className="btn btn-primary"
                              >
                                <svg width="24" height="24" viewBox="0 0 24 24">
                                  <use xlinkHref={`${sprite}#edit`} />
                                </svg>
                                Edit
                              </Link>
                              {subscription ? (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => handleDownload(item)}
                                >
                                  <svg width="24" height="24" viewBox="0 0 24 24">
                                    <use xlinkHref={`${sprite}#download2`} />
                                  </svg>
                                  {translate('components.download')}
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => {
                                    handelRedirect(item.id);
                                  }}
                                  className="btn btn-primary"
                                >
                                  <svg width="24" height="24" viewBox="0 0 24 24">
                                    <use xlinkHref={`${sprite}#download2`} />
                                  </svg>
                                  {translate('components.download')}
                                </button>
                              )}

                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                  setCoverId(item.id);
                                  handleShowDeleteCover();
                                }}
                              >
                                <svg width="24" height="24" viewBox="0 0 24 24">
                                  <use xlinkHref={`${sprite}#delete`} />
                                </svg>
                                Delete
                              </button>
                            </div>
                            <img
                              src={item.preview ? item.preview : cvDefault}
                              width="170"
                              height="234"
                              alt="img"
                            />
                          </div>
                          <div className="dash__data">
                            <span>{translate('components.created')}:</span>3 Dec 2022
                          </div>
                        </div>
                      );
                    }
                    return false;
                  })}
              </>
            )}
          </div>
        </div>
        <div className="dash__botMob">
          {latest && (
            <div className="dash__more">
              <button type="button" onClick={() => setLatest(false)}>
                See all
              </button>
            </div>
          )}

          <div className="btn-center">
            <Link to="/my-cv/create/" type="button" className="btn btn-primary">
              {translate('components.cover_letter')}
            </Link>
          </div>
        </div>
      </div>
      <DeleteCover
        handleDeleteCover={handleDeleteResume}
        show={deleteCover}
        handleClose={handleCloseDeleteCover}
      />
    </>
  );
};
Covers.propTypes = {
  showTitle: PropTypes.bool.isRequired,
};
export default Covers;
