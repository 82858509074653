import PropTypes from 'prop-types';
import { useTranslation } from 'src/hooks';

const TopSidebar = ({ step }) => {
  const { translate } = useTranslation();

  return (
    <nav className="create__topSidebar">
      <ul>
        <li className={step === 1 ? 'active' : ''}>
          <span>1</span>
          <div>{translate('choose.top_sidebar_1')}</div>
        </li>
        <li className={step === 2 ? 'active' : ''}>
          <span>2</span>
          <div>{translate('choose.top_sidebar_2')}</div>
        </li>
        <li className={step === 3 ? 'active' : ''}>
          <span>3</span>
          <div>{translate('choose.top_sidebar_3')}</div>
        </li>
        <li className={step === 4 ? 'active' : ''}>
          <span>4</span>
          <div>{translate('choose.top_sidebar_4')}</div>
        </li>
      </ul>
    </nav>
  );
};
TopSidebar.propTypes = {
  step: PropTypes.number.isRequired,
};
export default TopSidebar;
