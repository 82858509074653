import './style.scss';

import { Link } from 'react-router-dom';
import sprite from 'src/assets/sprite.svg';
import { useTranslation } from 'src/hooks';

const Footer = () => {
  const { translate } = useTranslation();
  return (
    <footer className="footer">
      <div className="wrap">
        <div className="footer__wrap">
          <div className="footer__left">
            <Link to="/" className="footer__logo">
              {translate('cv.title')}
            </Link>
            <div className="footer__soc">
              <Link to="/">
                <svg width="20" height="21" viewBox="0 0 20 21">
                  <use xlinkHref={`${sprite}#fb`} />
                </svg>
              </Link>
              <Link to="/">
                <svg width="20" height="21" viewBox="0 0 20 21">
                  <use xlinkHref={`${sprite}#linkedin`} />
                </svg>
              </Link>
            </div>
            <div className="footer__text">© 2023 CV Builder</div>
            <div className="footer__text"> All rights reserved</div>
          </div>
          <nav className="footer__right">
            <div className="footer__col">
              <div className="footer__col__name">ABOUT</div>
              <ul>
                <li>
                  <Link to="/blog">{translate('components.blog')}</Link>
                </li>
                <li>
                  <Link to="/#faq">{translate('components.faq')}</Link>
                </li>
              </ul>
            </div>
            <div className="footer__col">
              <div className="footer__col__name">{translate('Home.resume')}</div>
              <ul>
                <li>
                  <Link to="/cv">{translate('cv.title')}</Link>
                </li>
                <li>
                  <Link to="/cv-examples">{translate('cv.cv_examples')}</Link>
                </li>
                <li>
                  <Link to="/cv-templates">{translate('cv.cv_templates')}</Link>
                </li>
              </ul>
            </div>
            <div className="footer__col">
              <div className="footer__col__name">{translate('components.cl')}</div>
              <ul>
                <li>
                  <Link to="/cover">{translate('components.clb')}</Link>
                </li>
                <li>
                  <Link to="/cover-examples">{translate('components.cle')}</Link>
                </li>
                <li>
                  <Link to="/cover-templates">{translate('components.clt')}</Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="footer__bot">
          <Link to="/">{translate('components.terms')}</Link>
          <Link to="/">{translate('components.privacy')}</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
