import 'swiper/css';
import 'swiper/css/navigation';

import { useState } from 'react';
import sprite from 'src/assets/sprite.svg';
import { useTranslation } from 'src/hooks';

const Faq = () => {
  const [faq, setFaq] = useState(false);
  const { translate } = useTranslation();

  const faqList = [
    {
      id: '0',
      title: translate('Home.FAQ.how_to_build_a_cv_title'),
      text: translate('Home.FAQ.how_to_build_a_cv'),
    },
    {
      id: '1',
      title: translate('Home.FAQ.what_is_cover_letter_title'),
      text: translate('Home.FAQ.what_is_cover_letter'),
    },
    {
      id: '2',
      title: translate('Home.FAQ.how_to_create_a_cover_letter_title'),
      text: translate('Home.FAQ.how_to_create_a_cover_letter'),
    },
    {
      id: '3',
      title: translate('Home.FAQ.can_i_edit_my_cv_title'),
      text: translate('Home.FAQ.can_i_edit_my_cv'),
    },
  ];

  return (
    <>
      {faqList.map((item) => (
        <div className={`s6__row ${faq === item.id ? 'active' : ''}`} key={item.id}>
          <button
            type="button"
            className="s6__title"
            onClick={() => {
              if (faq === item.id) {
                setFaq(false);
              } else {
                setFaq(item.id);
              }
            }}
          >
            {item.title}
            <svg width="27" height="26">
              <use xlinkHref={`${sprite}#${faq !== item.id ? 'plus' : 'minus'}`} />
            </svg>
          </button>
          <div className="s6__body">
            <div className="content-wraper">
              <div dangerouslySetInnerHTML={{ __html: item.text }} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Faq;
