import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { GetPreviewCover } from 'src/components';
import { useTranslation } from 'src/hooks';
import { templatesActions } from 'src/store/actions';
import { setRedirectTo, setShowCoverPreview } from 'src/store/reducers/templates.reducer';

const Templates = () => {
  const fixWaiter = useSelector((state) => state.templatesReducer.fixWaiter);
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { subscription } = useSelector((state) => state.authReducer.user);
  const { id, template } = useSelector((state2) => state2.templatesReducer.currentCover);
  const coverTemplates = useSelector((state2) => state2.templatesReducer.coverTemplates);

  const [preview, setPreview] = useState('');
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (preview) {
      setShowPreview(false);
      const file = new File([preview], 'file');
      dispatch(templatesActions.coverUploadPreview({ image: file, id })).then(() => {
        if (subscription) {
          dispatch(setShowCoverPreview(true));
        } else {
          dispatch(setRedirectTo(`/my-covers/create/templates/?id=${id}`));
          navigate(`/choose/`);
        }
      });
    }
  }, [preview]);

  const handleSubmit = async (values) => {
    await dispatch(templatesActions.coverTemplate(values));
    setShowPreview(true);
  };

  const formik = useFormik({
    initialValues: {
      id: id || '',
      template: (template && template.id) || (coverTemplates.length && coverTemplates[0].id),
      step: 7,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  return (
    <>
      {showPreview && <GetPreviewCover setPreview={setPreview} />}
      <form className="create__templates" onSubmit={formik.handleSubmit}>
        <div className="create__templates__t">{translate('cover_create.choose_template')}</div>
        <div className="templates__wrap templates__wrap--colonmob">
          {coverTemplates &&
            coverTemplates.map((temp) => (
              <div
                className={`templates__item ${formik.values.template === temp.id ? 'active' : ''}`}
                onClick={() => formik.setFieldValue('template', temp.id)}
                onKeyUp={() => formik.setFieldValue('template', temp.id)}
                role="button"
                tabIndex="0"
                key={temp.id}
              >
                <div className="templates__name">{temp.name}</div>
                <div className="templates__img">
                  <img src={temp.preview} alt="template" />
                </div>
                <div className="template__des">{temp.description}</div>
              </div>
            ))}
        </div>
        <div className="create__templates__btns">
          <Link to={`/my-covers/create/content?id=${id}`} className="btn btn-outline-primary">
            {translate('cover_create.edit_cover_letter')}
          </Link>
          <button type="submit" className="btn btn-primary" disabled={fixWaiter}>
            {fixWaiter ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="sr-only">{translate('loading')}</span>
              </>
            ) : (
              <>{translate('cover_create.save_continue')}</>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default Templates;
