import './style.scss';

import { Route, Routes } from 'react-router-dom';

import BlogDetail from './blog-detail';
import BlogList from './blog-list';

const Blog = () => (
  <Routes>
    <Route path="/*" element={<BlogList />} />
    <Route path="/:detail" element={<BlogDetail />} />
  </Routes>
);

export default Blog;
