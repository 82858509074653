import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import add from 'src/assets/img/create/add.svg';
import remove from 'src/assets/img/create/remove.svg';
import sprite from 'src/assets/sprite.svg';
import { GetPreview } from 'src/components';
import { languagesLevel } from 'src/constants/index';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { templatesActions } from 'src/store/actions';
import { setCvLangs } from 'src/store/reducers/templates.reducer';
import { v4 as uuidv4 } from 'uuid';

import Sidebar from './sidebar';

const Languages = () => {
  const cvLangs = useSelector((state) => state.templatesReducer.cvLangs);
  const { translate } = useTranslation();
  const fixWaiter = useSelector((state) => state.templatesReducer.fixWaiter);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, languages } = useSelector((state2) => state2.templatesReducer.currentResume);
  const { width } = useWindowDimensions();

  const [preview, setPreview] = useState('');
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (preview) {
      setShowPreview(false);
      const file = new File([preview], 'file');
      dispatch(templatesActions.resumesUploadPreview({ image: file, id })).then(() => {
        navigate(`/my-cv/create/templates/?id=${id}`);
      });
    }
  }, [preview]);

  const handleSubmit = async (values) => {
    await dispatch(templatesActions.resumeLanguages(values));
    setShowPreview(true);
  };

  const [cvLangsState] = useState(cvLangs);

  const formik = useFormik({
    initialValues: cvLangsState || {
      id: id || '',
      languages: languages.length
        ? languages
        : [
            {
              id: 0,
              language: '',
              proficiency: 'A1 Beginner',
            },
          ],
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    const handleSet = () => {
      if (id) {
        dispatch(setCvLangs(formik.values));
      }
    };

    return handleSet;
  }, [formik.values]);

  const handleAddLang = () => {
    formik.setFieldValue('languages', [
      ...formik.values.languages,
      {
        id: uuidv4(),
        language: '',
        proficiency: 'A1 Beginner',
      },
    ]);
  };

  const removeRow = () => {
    formik.setFieldValue('languages', formik.values.languages.slice(0, -1));
  };

  return (
    <>
      {showPreview && <GetPreview setPreview={setPreview} />}
      <div className="create__mob">
        <Link to={`/my-cv/skills/?id=${id}`} type="button" className="create__back">
          <svg width="24" height="24" viewBox="0 0 24 24">
            <use xlinkHref={`${sprite}#arr-back`} />
          </svg>
          {translate('cover_create.back')}
        </Link>

        <button type="button" className="create__mobPreview">
          <svg width="32" height="32" viewBox="0 0 32 32">
            <use xlinkHref={`${sprite}#preview`} />
          </svg>
        </button>
      </div>
      <div className="create__wrap">
        <Sidebar step={7} />
        <div className="create__right">
          <div className="create__title">{translate('cv_create.languages')}</div>

          <form className="create__form" onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
              <FieldArray
                name="languages"
                render={() => (
                  <div className="create__blocks">
                    {formik.values.languages.map((item, index) => (
                      <div className="create__block" key={item.id}>
                        <div className="form-group">
                          <div className="label" title="Hard SkillsHard SkillsHard Skills">
                            {translate('cv_create.languages')}
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="English"
                            name={`languages[${index}].language`}
                            defaultValue={item.language}
                            onChange={formik.handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <div className="label" title="Hard SkillsHard SkillsHard Skills">
                            {translate('cv_create.proficiency')}
                          </div>
                          <select
                            className="form-control"
                            placeholder="Fluent"
                            name={`languages[${index}].proficiency`}
                            defaultValue={
                              formik.values.languages[index].proficiency
                                ? formik.values.languages[index].proficiency
                                : 'A1 Beginner'
                            }
                            onChange={formik.handleChange}
                          >
                            {languagesLevel.map((languageLevel) => (
                              <option key={languageLevel.id} value={languageLevel.name}>
                                {languageLevel.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              />
              {formik.values.languages.length > 1 && (
                <button type="button" className="create__add mb-1" onClick={removeRow}>
                  <img src={remove} alt="add icon" />
                  {translate('cv_create.remove_certifications')}
                </button>
              )}
              <button type="button" className="create__add" onClick={handleAddLang}>
                <img src={add} alt="add icon" />
                {translate('cv_create.add_language')}
              </button>
            </FormikProvider>

            <div className="create__sub">
              {width > 565 && (
                <Link to={`/my-cv/create/skills?id=${id}`} className="btn btn-outline-primary">
                  {translate('cover_create.back')}
                </Link>
              )}

              <button type="submit" className="btn btn-primary" disabled={fixWaiter}>
                {fixWaiter ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{translate('loading')}</span>
                  </>
                ) : (
                  <>{translate('cover_create.continue')}</>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Languages;
