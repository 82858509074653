import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const BlogListComponent = ({ to, image, title, data, description, cssClass }) => (
  <div className={`blog__item ${cssClass}`}>
    <Link to={`/blog/${to}`} className="blog__img">
      <img src={image} alt="blog img" />
    </Link>
    <Link to={`/blog/${to}`} className="blog__t">
      {title}
    </Link>
    <div className="blog__data">{data}</div>
    <div className="blog__text">{description}</div>
  </div>
);

BlogListComponent.propTypes = {
  to: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cssClass: PropTypes.string.isRequired,
};

export default BlogListComponent;
