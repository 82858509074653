import sprite from 'src/assets/sprite.svg';
import { useTranslation } from 'src/hooks';

const MoneyBackGuaranty = () => {
  const { translate } = useTranslation();

  return (
    <div className="payment__text">
      <div className="payment__text__t">
        <svg id="coin" width="32" height="32" viewBox="0 0 32 32">
          <use xlinkHref={`${sprite}#coin`} />
        </svg>
        {translate('choose.money_back_guaranty')}
      </div>
      <div className="payment__text__content">
        {translate('choose.money_back_guaranty_desc_1')}
        <a href="/">{translate('choose.money_back_guaranty_desc_2')}</a>
        {translate('choose.money_back_guaranty_desc_3')}
      </div>
    </div>
  );
};

export default MoneyBackGuaranty;
