import { api } from 'src/api';
import { getHeaders } from 'src/helpers/';

export const signIn = async (data) => {
  const result = await api.post(`auth/login`, data);

  return result;
};

export const signInGoogle = async (data) => {
  const result = await api.post(`auth/google`, data);

  return result;
};

export const signInGoogleModule = async (data) => {
  const result = await api.post(`auth/google-by-data`, data);

  return result;
};

export const signInFacebook = async (data) => {
  const result = await api.post(`auth/facebook`, data);

  return result;
};

export const signUp = async (data) => {
  const result = await api.post(`auth/register`, data);

  return result;
};

export const logout = async (data) => {
  const result = await api.post(`auth/logout`, data, getHeaders());

  return result;
};

export const forgotPassword = async (data) => {
  const result = await api.post(`/auth/forgot-password`, data, getHeaders());

  return result;
};

export const ressetPassword = async (data) => {
  const result = await api.post(`/auth/reset-password`, data, getHeaders());

  return result;
};

export const getAccountSubscriptionData = async () => {
  const result = await api.get('account/subscription', getHeaders());

  return result;
};

export const getUserNotifications = async () => {
  const result = await api.get(`/notifications`, getHeaders());

  return result;
};

export const checkIfUserHaveUnReadNotification = async () => {
  const result = await api.get(`/notifications/check-un-read-notification`, getHeaders());

  return result;
};

export const markNotificationsAsRead = async () => {
  const result = await api.get(`/notifications/mark-as-read`, getHeaders());

  return result;
};

export const changeAccountEmail = async (data) => {
  const result = await api.post('account/change/email', data, getHeaders());

  return result;
};

export const changeAccountPassword = async (data) => {
  const result = await api.post('account/change/password', data, getHeaders());

  return result;
};

export const getMe = async () => {
  const result = await api.get(`/auth/me`, getHeaders());

  return result;
};

export const createSubscription = async (data) => {
  const result = await api.post('/subscription/create', data, getHeaders());

  return result;
};

export const getInformationAboutSubscription = async () => {
  const result = await api.get(`/account/subscription`, getHeaders());

  return result;
};

export const createPaymentIntent = async () => {
  const result = await api.get('/subscription/payment-intent', getHeaders());

  return result;
};

export const updateSubscription = async (data) => {
  const result = await api.put('/subscription/update', data, getHeaders());

  return result;
};

export const cancelSubscription = async () => {
  const result = await api.post('/subscription/cancel', null, getHeaders());

  return result;
};

export const getDataSubscription = async () => {
  const result = await api.get('/subscription/data', getHeaders());

  return result;
};
