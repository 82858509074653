import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';

const SubscriptionCard = ({
  name,
  price,
  children,
  productId,
  subscription,
  action,
  update,
  redirectTo,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handelUpdated = async () => {
    setLoading(true);
    if (action) {
      await dispatch(authActions.cancelSubscription());
      await dispatch(authActions.getDataSubscription());
      setLoading(false);
      navigate('/account');
    } else {
      await dispatch(authActions.updateSubscription({ plan: productId }));
      setLoading(false);
      navigate('/choose/completed', {
        state: {
          redirectTo,
        },
      });
    }
  };

  return (
    <div className="create__choose__col">
      <div className="create__choose__plan">
        <div className="create__choose__plan__name">{name}</div>
        <div className="create__choose__plan__price">{price}</div>
        <ul className="create__choose__plan__list">{children}</ul>
        <div className="create__choose__plan__btb">
          {update && subscription ? (
            <button
              type="button"
              className="btn btn-primary"
              onClick={handelUpdated}
              disabled={loading}
            >
              {loading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{translate('loading')}</span>
                </>
              ) : (
                <>{action ? 'Close' : 'Update'}</>
              )}
            </button>
          ) : (
            <Link
              to="/choose/payment"
              state={{ name, price, productId, redirectTo }}
              type="button"
              className="btn btn-primary"
            >
              {translate('choose.choose')}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

SubscriptionCard.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  productId: PropTypes.string.isRequired,
  subscription: PropTypes.bool.isRequired,
  action: PropTypes.bool.isRequired,
  update: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string.isRequired,
};

export default SubscriptionCard;
