import 'react-datepicker/dist/react-datepicker.css';

import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import sprite from 'src/assets/sprite.svg';
import { GetPreviewCover } from 'src/components';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { templatesActions } from 'src/store/actions';
import { setCoverContent } from 'src/store/reducers/templates.reducer';

import Sidebar from './sidebar';

const changeDataFormat = (data) => {
  let newData = data.split('.');
  newData = `${newData[2]}-${newData[1]}-${newData[0]}`;
  return newData;
};

const Content = () => {
  const { width } = useWindowDimensions();
  const { translate } = useTranslation();
  const coverContent = useSelector((state) => state.templatesReducer.coverContent);
  const fixWaiter = useSelector((state) => state.templatesReducer.fixWaiter);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    id,
    date,
    greeting,
    jobTitle,
    openingParagraph,
    secondParagraph,
    thirdParagraph,
    signature,
  } = useSelector((state2) => state2.templatesReducer.currentCover);

  const [preview, setPreview] = useState('');
  const [showPreview, setShowPreview] = useState(false);

  const newData = date ? changeDataFormat(date) : '';
  const [startDate, setStartDate] = useState(date ? new Date(newData) : new Date());

  useEffect(() => {
    if (preview) {
      setShowPreview(false);
      const file = new File([preview], 'file');
      dispatch(templatesActions.coverUploadPreview({ image: file, id })).then(() => {
        navigate(`/my-covers/create/templates/?id=${id}`);
      });
    }
  }, [preview]);

  const handleSubmit = async (values) => {
    await dispatch(templatesActions.coverContent(values));
    setShowPreview(true);
  };

  const [coverContentState] = useState(coverContent);

  const formik = useFormik({
    initialValues: coverContentState || {
      id: id || '',
      date: date || '',
      jobTitle: jobTitle || '',
      greeting: greeting || '',
      openingParagraph: openingParagraph || '',
      secondParagraph: secondParagraph || '',
      thirdParagraph: thirdParagraph || '',
      signature: signature || '',
      step: 2,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    const handleSet = () => {
      if (id) {
        dispatch(setCoverContent(formik.values));
      }
    };

    return handleSet;
  }, [formik.values]);

  useEffect(() => {
    const formattedDate = startDate.toISOString().slice(0, 10);

    formik.setFieldValue('date', formattedDate);
  }, [startDate]);

  return (
    <>
      {showPreview && <GetPreviewCover setPreview={setPreview} />}
      <div className="create__mob">
        <Link to="/my-cv/create/" type="button" className="create__back">
          <svg width="24" height="24" viewBox="0 0 24 24">
            <use xlinkHref={`${sprite}#arr-back`} />
          </svg>
          {translate('cover_create.back')}
        </Link>

        <button type="button" className="create__mobPreview">
          <svg width="32" height="32" viewBox="0 0 32 32">
            <use xlinkHref={`${sprite}#preview`} />
          </svg>
        </button>
      </div>
      <div className="create__wrap">
        <Sidebar step={2} />
        <div className="create__right">
          <div className="create__title">{translate('cover_create.content')}</div>

          <form className="create__form" onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.job_title')}</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={translate('cover_create.job_title_placeholder')}
                    name="jobTitle"
                    defaultValue={formik.values.jobTitle}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <div className="label">{translate('cover_create.date')}</div>
                  <DatePicker
                    selected={startDate}
                    onChange={(d) => setStartDate(d)}
                    placeholder="LLC Experts"
                    name="date"
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="label">{translate('cover_create.greeting')}</div>
              <input
                type="text"
                className="form-control"
                placeholder="Dear Ms Jones"
                name="greeting"
                defaultValue={formik.values.greeting}
                onChange={formik.handleChange}
              />
            </div>

            <div className="form-group">
              <div className="label">{translate('cover_create.opening_paragraph')}</div>
              <textarea
                className="form-control"
                placeholder={translate('cover_create.opening_paragraph_desc')}
                name="openingParagraph"
                defaultValue={formik.values.openingParagraph}
                onChange={formik.handleChange}
              />
            </div>
            <div className="form-group">
              <div className="label">{translate('cover_create.second_paragraph')}</div>
              <textarea
                className="form-control"
                placeholder={translate('cover_create.second_paragraph_desc')}
                name="secondParagraph"
                defaultValue={formik.values.secondParagraph}
                onChange={formik.handleChange}
              />
            </div>
            <div className="form-group">
              <div className="label">{translate('cover_create.third_paragraph')}</div>
              <textarea
                className="form-control"
                placeholder={translate('cover_create.third_paragraph_desc')}
                name="thirdParagraph"
                defaultValue={formik.values.thirdParagraph}
                onChange={formik.handleChange}
              />
            </div>

            <div className="form-group">
              <div className="label">{translate('cover_create.signature')}</div>
              <input
                type="text"
                className="form-control"
                placeholder="Sincerely Chris Brown"
                name="signature"
                defaultValue={formik.values.signature}
                onChange={formik.handleChange}
              />
            </div>

            <div className="create__sub">
              {width > 565 ? (
                <Link to={`/my-covers/create?id=${id}`} className="btn btn-outline-primary">
                  {translate('cover_create.back')}
                </Link>
              ) : (
                <Link
                  to={`/my-covers/create/templates?id=${id}`}
                  className="btn btn-outline-primary"
                >
                  {translate('cover_create.skip_this_step')}
                </Link>
              )}

              <button type="submit" className="btn btn-primary" disabled={fixWaiter}>
                {fixWaiter ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{translate('loading')}</span>
                  </>
                ) : (
                  <>{translate('cover_create.continue')}</>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Content;
