import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import add from 'src/assets/img/create/add.svg';
import remove from 'src/assets/img/create/remove.svg';
import sprite from 'src/assets/sprite.svg';
import { GetPreview } from 'src/components';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { templatesActions } from 'src/store/actions';
import { setCvHobbies } from 'src/store/reducers/templates.reducer';
import { v4 as uuidv4 } from 'uuid';

import Sidebar from './sidebar';

const Hobbies = () => {
  const cvHobbies = useSelector((state) => state.templatesReducer.cvHobbies);
  const { translate } = useTranslation();
  const fixWaiter = useSelector((state) => state.templatesReducer.fixWaiter);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, hobbies } = useSelector((state2) => state2.templatesReducer.currentResume);
  const { width } = useWindowDimensions();

  const [preview, setPreview] = useState('');
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (preview) {
      setShowPreview(false);
      const file = new File([preview], 'file');
      dispatch(templatesActions.resumesUploadPreview({ image: file, id })).then(() => {
        navigate(`/my-cv/create/education/?id=${id}`);
      });
    }
  }, [preview]);

  const handleSubmit = async (values) => {
    await dispatch(templatesActions.resumeHobbies(values));
    setShowPreview(true);
  };

  const [cvHobbiesState] = useState(cvHobbies);

  const formik = useFormik({
    initialValues: cvHobbiesState || {
      id: id || '',
      hobbies: hobbies.length
        ? hobbies
        : [
            {
              id: 0,
              name: '',
              description: '',
            },
          ],
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    const handleSet = () => {
      if (id) {
        dispatch(setCvHobbies(formik.values));
      }
    };

    return handleSet;
  }, [formik.values]);

  const addNewRow = () => {
    const newCert = [
      ...formik.values.hobbies,
      {
        id: uuidv4(),
        name: '',
        description: '',
      },
    ];
    formik.setFieldValue('hobbies', newCert);
  };

  const removeRow = () => {
    formik.setFieldValue('hobbies', formik.values.hobbies.slice(0, -1));
  };

  return (
    <>
      {showPreview && <GetPreview setPreview={setPreview} />}
      <div className="create__mob">
        <Link to={`/my-cv/work/?id=${id}`} type="button" className="create__back">
          <svg width="24" height="24" viewBox="0 0 24 24">
            <use xlinkHref={`${sprite}#arr-back`} />
          </svg>
          {translate('cover_create.back')}
        </Link>

        <button type="button" className="create__mobPreview">
          <svg width="32" height="32" viewBox="0 0 32 32">
            <use xlinkHref={`${sprite}#preview`} />
          </svg>
        </button>
      </div>
      <div className="create__wrap">
        <Sidebar step={3} />
        <div className="create__right">
          <div className="create__title">{translate('cv_create.hobbies')}</div>
          <div className="create__skip">
            <Link to={`/my-cv/create/education?id=${id}`}>
              {translate('cover_create.skip_this_step')}
            </Link>
          </div>
          <form className="create__form" onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
              <FieldArray
                name="hobbies"
                render={() => (
                  <div className="create__blocks">
                    {formik.values.hobbies.map((item, index) => (
                      <div className="create__block" key={item.id}>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="label">Enter your hobby</div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={`hobby #${index + 1}`}
                                defaultValue={item.name}
                                name={`hobbies[${index}].name`}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="label">{translate('cv_create.your_skills')}</div>
                              <textarea
                                className="form-control"
                                placeholder="List your skills"
                                defaultValue={item.description}
                                name={`hobbies[${index}].description`}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        {index > 0 && index + 1 === formik.values.hobbies.length && (
                          <button type="button" className="create__add mb-1" onClick={removeRow}>
                            <img src={remove} alt="add icon" />
                            {translate('cv_create.remove_certifications')}
                          </button>
                        )}
                        {formik.values.hobbies.length === index + 1 && index < 2 && (
                          <button type="button" className="create__add" onClick={addNewRow}>
                            <img src={add} alt="add icon" />
                            {translate('cv_create.add_certifications')}
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              />
            </FormikProvider>

            <div className="create__sub">
              {width > 565 ? (
                <Link to={`/my-cv/create/work?id=${id}`} className="btn btn-outline-primary">
                  {translate('cover_create.back')}
                </Link>
              ) : (
                <Link to={`/my-cv/create/education/?id=${id}`} className="btn btn-outline-primary">
                  {translate('cover_create.skip_this_step')}
                </Link>
              )}

              <button type="submit" className="btn btn-primary" disabled={fixWaiter}>
                {fixWaiter ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{translate('loading')}</span>
                  </>
                ) : (
                  <>{translate('cover_create.continue')}</>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Hobbies;
