import 'react-datepicker/dist/react-datepicker.css';

import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import add from 'src/assets/img/create/add.svg';
import remove from 'src/assets/img/create/remove.svg';
import sprite from 'src/assets/sprite.svg';
import { GetPreview } from 'src/components';
import { getDataObj } from 'src/helpers';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { templatesActions } from 'src/store/actions';
import { setCvWork } from 'src/store/reducers/templates.reducer';
import { v4 as uuidv4 } from 'uuid';

import CitySelector from './city-selector';
import Sidebar from './sidebar';

const Work = () => {
  const cvWork = useSelector((state) => state.templatesReducer.cvWork);
  const fixWaiter = useSelector((state) => state.templatesReducer.fixWaiter);
  const { translate } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countries = useSelector((state) => state.templatesReducer.countries);
  const { id, experiences, country, city } = useSelector(
    (state2) => state2.templatesReducer.currentResume,
  );
  const { width } = useWindowDimensions();
  const [preview, setPreview] = useState('');
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (preview) {
      setShowPreview(false);
      const file = new File([preview], 'file');
      dispatch(templatesActions.resumesUploadPreview({ image: file, id })).then(() => {
        navigate(`/my-cv/create/hobbies/?id=${id}`);
      });
    }
  }, [preview]);

  const handleSubmit = async (values) => {
    await dispatch(templatesActions.resumeExperience(values));
    setShowPreview(true);
  };

  const [cvWorkState] = useState(cvWork);

  const formik = useFormik({
    initialValues: cvWorkState || {
      id: id || '',
      experience: experiences.length
        ? experiences.map((item) => ({
            ...item,
            startDate: item.startDate ? getDataObj(item.startDate) : new Date(),
            endDate: item.endDate ? getDataObj(item.endDate) : new Date(),
          }))
        : [
            {
              id: 0,
              jobTitle: '',
              companyName: '',
              country: { id: (country && country.id) || (countries[0] && countries[0].id) || '' },
              city: { id: (city && city.id) || '1' },
              startDate: new Date(),
              endDate: new Date(),
              isPresent: false,
              responsibilities: '',
              achievements: '',
            },
          ],
      step: 3,
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    const handleSet = () => {
      if (id) {
        dispatch(setCvWork(formik.values));
      }
    };

    return handleSet;
  }, [formik.values]);

  const changeCounty = (newId, index) => {
    formik.setFieldValue(`experience[${index}].country.id`, newId);
  };

  const changeCity = (newId, index) => {
    formik.setFieldValue(`experience[${index}].city.id`, newId);
  };

  const addNewRow = () => {
    const newExperience = [
      ...formik.values.experience,
      {
        id: uuidv4(),
        jobTitle: '',
        companyName: '',
        country: { id: (country && country.id) || (countries[0] && countries[0].id) || '1' },
        city: { id: (city && city.id) || '1' },
        startDate: new Date(),
        endDate: new Date(),
        isPresent: false,
        responsibilities: '',
        achievements: '',
      },
    ];
    formik.setFieldValue('experience', newExperience);
  };

  const removeRow = () => {
    formik.setFieldValue('experience', formik.values.experience.slice(0, -1));
  };

  return (
    <>
      {showPreview && <GetPreview setPreview={setPreview} />}
      <div className="create__mob">
        <Link to={`/my-cv/create/?id=${id}`} type="button" className="create__back">
          <svg width="24" height="24" viewBox="0 0 24 24">
            <use xlinkHref={`${sprite}#arr-back`} />
          </svg>
          {translate('cover_create.back')}
        </Link>

        <button type="button" className="create__mobPreview">
          <svg width="32" height="32" viewBox="0 0 32 32">
            <use xlinkHref={`${sprite}#preview`} />
          </svg>
        </button>
      </div>
      <div className="create__wrap">
        <Sidebar step={2} />
        <div className="create__right">
          <div className="create__title">{translate('cv_create.work_experience')}</div>
          <div className="create__skip">
            <Link to={`/my-cv/create/hobbies/?id=${id}`}>
              {translate('cover_create.skip_this_step')}
            </Link>
          </div>
          <form className="create__form" onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
              <FieldArray
                name="experience"
                render={() => (
                  <div className="create__blocks">
                    {formik.values.experience.map((item, index) => (
                      <div className="create__block" key={item.id}>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="label">{translate('cover_create.job_title')}</div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Project Manager"
                                value={item.jobTitle || ''}
                                name={`experience[${index}].jobTitle`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="label">
                                {translate('cv_create.company_organization')}
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="LLC Experts"
                                value={item.companyName || ''}
                                name={`experience[${index}].companyName`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <div className="col-12 col-sm-6">
                              <div className="label">{translate('cover_create.country')}</div>
                              <select
                                className="form-control"
                                defaultValue={formik.values.experience[index].country.id}
                                name={`experience[${index}].country.id`}
                                onChange={(e) => changeCounty(e.target.value, index)}
                              >
                                {countries.length &&
                                  countries.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="label">{translate('cover_create.city')} </div>
                              {countries.length && (
                                <CitySelector
                                  countyId={String(
                                    formik.values.experience[index].country.id || countries[0].id,
                                  )}
                                  index={index}
                                  changeCity={changeCity}
                                  value={String(formik.values.experience[index].city.id)}
                                  name={`experience[${index}].city.id`}
                                  setFieldValue={formik.setFieldValue}
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <div className="col-12 col-sm-6">
                              <div className="label">{translate('cv_create.start_month')}</div>
                              <DatePicker
                                className="form-control"
                                selected={formik.values.experience[index].startDate || new Date()}
                                dateFormat="MMM yyyy"
                                showMonthYearPicker
                                onChange={(date) => {
                                  formik.setFieldValue(`experience[${index}].startDate`, date);
                                }}
                              />
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="label">{translate('cv_create.end_month')}</div>
                              <DatePicker
                                className="form-control"
                                selected={formik.values.experience[index].endDate || new Date()}
                                dateFormat="MMM yyyy"
                                showMonthYearPicker
                                disabled={formik.values.experience[index].isPresent}
                                onChange={(date) => {
                                  formik.setFieldValue(`experience[${index}].endDate`, date);
                                }}
                              />

                              <label
                                htmlFor={`experience[${index}].isPresent`}
                                className="checkboxes__label mt-2 mb-0"
                              >
                                <input
                                  checked={formik.values.experience[index].isPresent}
                                  type="checkbox"
                                  id={`experience[${index}].isPresent`}
                                  name={`experience[${index}].isPresent`}
                                  onChange={formik.handleChange}
                                />
                                <div className="checkbox__check" />
                                <span className="checkbox__text">
                                  {translate('cv_create.presentHere')}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="label">
                                {translate('cv_create.work_responsibilities')}
                              </div>
                              <textarea
                                className="form-control"
                                placeholder="Write bullets describing what you were doing on this position."
                                name={`experience[${index}].responsibilities`}
                                defaultValue={formik.values.experience[index].responsibilities}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="label">
                                {translate('cv_create.your_achievements')}
                              </div>
                              <textarea
                                className="form-control"
                                placeholder="Describe what you have achieved."
                                name={`experience[${index}].achievements`}
                                defaultValue={formik.values.experience[index].achievements}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                        </div>
                        {index > 0 && index + 1 === formik.values.experience.length && (
                          <button type="button" className="create__add mb-1" onClick={removeRow}>
                            <img src={remove} alt="add icon" />
                            {translate('cv_create.remove_experience')}
                          </button>
                        )}
                        {formik.values.experience.length === index + 1 && index < 2 && (
                          <button type="button" className="create__add" onClick={addNewRow}>
                            <img src={add} alt="add icon" />
                            {translate('cv_create.add_experience')}
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              />
            </FormikProvider>

            <div className="create__sub">
              {width > 565 ? (
                <Link to={`/my-cv/create/?id=${id}`} className="btn btn-outline-primary">
                  {translate('cover_create.back')}
                </Link>
              ) : (
                <Link to={`/my-cv/create/education/?id=${id}`} className="btn btn-outline-primary">
                  {translate('cover_create.skip_this_step')}
                </Link>
              )}

              <button type="submit" className="btn btn-primary" disabled={fixWaiter}>
                {fixWaiter ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">
                      <span className="sr-only">{translate('loading')}</span>
                    </span>
                  </>
                ) : (
                  <>{translate('cover_create.save_continue')}</>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Work;
