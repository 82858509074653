import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import lock from 'src/assets/img/create/lock.svg';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';

const FullPaymentElement = ({ productId, redirectTo }) => {
  const stripe = useStripe();
  const { translate } = useTranslation();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: 'http://localhost:3000/',
      },
      redirect: 'if_required',
    });

    if (!error) {
      dispatch(
        authActions.createSubscription({
          plan: productId,
          payment_method: setupIntent.payment_method,
        }),
      ).then(() => {
        setLoading(false);
        navigate('/choose/completed', {
          state: {
            redirectTo,
          },
        });
      });
    }
  };

  const paymentElementOptions = {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: false,
    },
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <div className="payment__secure">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="payment__secure__left">
              <img src={lock} alt="Secure payments solutions" />
              {translate('choose.secure_payments')}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <button type="submit" id="submit" className="btn btn-primary" disabled={loading}>
              {loading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{translate('loading')}</span>
                </>
              ) : (
                <>{translate('choose.checkout')}</>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

FullPaymentElement.propTypes = {
  productId: PropTypes.string.isRequired,
  redirectTo: PropTypes.string.isRequired,
};

export default FullPaymentElement;
