import './style.scss';

import PropTypes from 'prop-types';

const TopWaiter = ({ isActive }) => (
  <div className={`topWaiter ${isActive ? 'active' : ''}`}>
    <div className="topWaiter__line" />
  </div>
);

TopWaiter.propTypes = {
  isActive: PropTypes.bool.isRequired,
};
export default TopWaiter;
