import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import cover from 'src/assets/img/cover/cover.svg';
import cover from 'src/assets/img/cover/cover.png';
// import exCenter from 'src/assets/img/cover/ex-center.jpg';
import exCenter from 'src/assets/img/cover/ex-center.png';
import s2n1 from 'src/assets/img/cover/s2-1.svg';
import s2n2 from 'src/assets/img/cover/s2-2.svg';
import s2n3 from 'src/assets/img/cover/s2-3.svg';
import s2n4 from 'src/assets/img/cover/s2-4.svg';
import structure from 'src/assets/img/cover/structure.jpg';
import t1 from 'src/assets/img/cover/tips/1.png';
import t1m from 'src/assets/img/cover/tips/1m.png';
import t2 from 'src/assets/img/cover/tips/2.png';
import t2m from 'src/assets/img/cover/tips/2m.png';
import t3 from 'src/assets/img/cover/tips/3.png';
import t3m from 'src/assets/img/cover/tips/3m.png';
import t4 from 'src/assets/img/cover/tips/4.png';
import t4m from 'src/assets/img/cover/tips/4m.png';
import t5 from 'src/assets/img/cover/tips/5.png';
import t5m from 'src/assets/img/cover/tips/5m.png';
import t6 from 'src/assets/img/cover/tips/6.png';
import t6m from 'src/assets/img/cover/tips/6m.png';
import circle1 from 'src/assets/img/cv/circle1.svg';
import circle2 from 'src/assets/img/cv/circle2.svg';
import compLg from 'src/assets/img/home/comp-lg.svg';
import compSm from 'src/assets/img/home/comp-sm.svg';
import sprite from 'src/assets/sprite.svg';
import { AuthButton } from 'src/components';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { templatesActions } from 'src/store/actions';

import { CoverTemplate } from './components';

const Cover = () => {
  const covers = useSelector((state2) => state2.templatesReducer.coverTemplates);
  const { translate } = useTranslation();

  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const [numElements, setNumElements] = useState(12);

  useEffect(() => {
    dispatch(templatesActions.getCoversTemplates());
  }, []);

  const handelShowMoreClick = () => {
    setNumElements(numElements + 3);
  };

  const coversToShow = covers.slice(0, numElements);

  return (
    <>
      <div className="firstGrey">
        <div className="s1--cv">
          <div className="wrap">
            <div className="cvS1">
              <div className="s1__left">
                <div className="s1__t1 s1__t1--grey">{translate('cover.title')}</div>
                <div className="s1__t2">{translate('cover.sub_title')}</div>
                {width <= 565 && width > 500 && (
                  <img src={cover} className="cvS1__mobImg" alt="img" />
                )}
                {width <= 500 && <img src={cover} className="cvS1__mobImg" alt="img" />}
                <div className="s1__t3">{translate('cover.title_desc')}</div>
                <AuthButton to="/my-covers/create/" content={translate('cover.create_cover')} />
                <div className="s1__comp">
                  <img src={width >= 565 ? compLg : compSm} alt="" />
                </div>
              </div>
              {width > 565 && (
                <div className="cvS1__right" style={{ backgroundImage: `url(${cover})` }} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="s2">
        <div className="wrap">
          <div className="title__wrap">
            <div className="title__label">BENEFITS</div>
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: translate('cover.why_do_you') }}
            />
            <div className="title__text">{translate('cover.why_do_you_desc')}</div>
          </div>
          <div className="s2__wrap">
            <div className="s2__col">
              <img src={s2n1} alt="img" width="43.09" height="45" />
              <div
                className="s2__title"
                dangerouslySetInnerHTML={{ __html: translate('cover.employer') }}
              />
              <div className="s2__text">{translate('cover.employer_desc')}</div>
            </div>
            <div className="s2__col">
              <img src={s2n2} alt="img" width="43.09" height="45" />
              <div className="s2__title">{translate('cover.additional')}</div>
              <div className="s2__text">{translate('cover.additional_desc')}</div>
            </div>
            <div className="s2__col">
              <img src={s2n3} alt="img" width="43.09" height="45" />
              <div
                className="s2__title"
                dangerouslySetInnerHTML={{ __html: translate('cover.perfect_match') }}
              />
              <div className="s2__text">{translate('cover.perfect_match_desc')}</div>
            </div>
            <div className="s2__col">
              <img src={s2n4} alt="img" width="43.09" height="45" />
              <div className="s2__title">{translate('cover.research')}</div>
              <div className="s2__text">{translate('cover.research_desc')}</div>
            </div>
          </div>
          <div className="s2__btn btn-center">
            <AuthButton to="/my-covers/create/" content={translate('cover.create_cover')} />
          </div>
        </div>
      </div>
      <div className="templates">
        <div className="wrap">
          <div className="title__wrap">
            <div className="title__label">{translate('cover.templates')}</div>
            <div className="title">{translate('cover.best_practice')}</div>
            <div className="title__text">{translate('cover.best_practice_desc')}</div>
          </div>
          <div className="templates__tab">
            <button type="button" className="btn btn-outline-primary btn-outline--purple active">
              {translate('cover.popular')}
            </button>
            <button type="button" className="btn btn-outline-primary btn-outline--purple ">
              {translate('cover.all')}
            </button>
          </div>
          <div className="templates__wrap">
            {coversToShow.map((item) => (
              <CoverTemplate
                key={item.id}
                id={item.id}
                title={item.name}
                image={item.preview}
                description={item.description}
              />
            ))}
          </div>
          <div className="btn-center">
            <button type="button" className="btn btn-primary" onClick={handelShowMoreClick}>
              {translate('cover.show_more')}
            </button>
          </div>
        </div>
      </div>
      <div className="examples">
        <div className="wrap">
          <div className="title__wrap">
            <div className="title__label">{translate('cover.examples')}</div>
            <div className="title">{translate('cover.look_at_the_examples')}</div>
            <div className="title__text">{translate('cover.look_at_the_examples_desc')}</div>
          </div>
        </div>
        {width > 767 ? (
          <div className="examples__cover">
            <div className="wrap">
              <div className="examples__cover__wrap">
                <div className="examples__cover__col examples__cover__col1">
                  <div className="examples__cover__item1">
                    <div className="examples__title">
                      Heading
                      <svg id="arr-long" width="104" height="16" viewBox="0 0 104 16">
                        <use xlinkHref={`${sprite}#arr-long`} />
                      </svg>
                    </div>
                  </div>
                  <div className="examples__cover__item2">
                    <div className="examples__title">
                      {translate('cover.salutation')}
                      <svg id="arr-long" width="104" height="16" viewBox="0 0 104 16">
                        <use xlinkHref={`${sprite}#arr-long`} />
                      </svg>
                    </div>
                  </div>
                  <div className="examples__cover__item3">
                    <div className="examples__title">
                      {translate('cover.second_paragraph')}
                      <svg id="arr-long" width="30" height="16" viewBox="0 0 30 16">
                        <use xlinkHref={`${sprite}#arr-short`} />
                      </svg>
                    </div>
                    <div className="examples__cover__text">
                      {translate('cover.second_paragraph')}
                    </div>
                  </div>
                  <div className="examples__cover__item4">
                    <div className="examples__title">
                      {translate('cover.closing')}
                      <svg id="arr-long" width="104" height="16" viewBox="0 0 104 16">
                        <use xlinkHref={`${sprite}#arr-long`} />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="examples__cover__center">
                  <img src={exCenter} alt="example" />
                </div>
                <div className="examples__cover__col examples__cover__col2">
                  <div className="examples__cover__item1">
                    <div className="examples__title">
                      <svg id="arr-short" width="30" height="16" viewBox="0 0 30 16">
                        <use xlinkHref={`${sprite}#arr-short`} />
                      </svg>
                      {translate('cover.opening_paragraph')}
                    </div>
                    <div className="examples__cover__text">
                      {translate('cover.opening_paragraph_desc')}
                    </div>
                  </div>
                  <div className="examples__cover__item2">
                    <div className="examples__title">
                      <svg id="arr-short" width="30" height="16" viewBox="0 0 30 16">
                        <use xlinkHref={`${sprite}#arr-short`} />
                      </svg>
                      {translate('cover.third_paragraph')}
                    </div>
                    <div className="examples__cover__text">
                      {translate('cover.third_paragraph_desc')}
                    </div>
                  </div>
                </div>
                <img src={circle1} alt="decor" className="examples__cover__circle1" />
                <img src={circle2} alt="decor" className="examples__cover__circle2" />
                <div
                  className="examples__cover__decorText"
                  dangerouslySetInnerHTML={{ __html: translate('cover.explore') }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="structure">
            <div className="wrap">
              <div className="structure__wrap">
                <div
                  className="structure__title"
                  dangerouslySetInnerHTML={{ __html: translate('cover.explore') }}
                />
                <div className="structure__img">
                  <img src={structure} alt="structure" />
                </div>
                <div className="structure__row">
                  <div className="structure__title2">
                    <span style={{ backgroundColor: '#F79E1B' }} />
                    {translate('cover.heading')}
                  </div>
                </div>
                <div className="structure__row">
                  <div className="structure__title2">
                    <span style={{ backgroundColor: '#61F71B' }} />
                    {translate('cover.salutation')}
                  </div>
                </div>
                <div className="structure__row">
                  <div className="structure__title2">
                    <span style={{ backgroundColor: ' #FF5368' }} />
                    {translate('cover.first_paragraph')}
                  </div>
                  <div className="structure__text">{translate('cover.first_paragraph_desc')}</div>
                </div>
                <div className="structure__row">
                  <div className="structure__title2">
                    <span style={{ backgroundColor: '  #0077FB' }} />
                    {translate('cover.second_paragraph')}
                  </div>
                  <div className="structure__text">{translate('cover.second_paragraph_desc')}</div>
                </div>
                <div className="structure__row">
                  <div className="structure__title2">
                    <span style={{ backgroundColor: '  #0CC4ED' }} />
                    {translate('cover.third_paragraph')}
                  </div>
                  <div className="structure__text">{translate('cover.third_paragraph_desc')}</div>
                </div>
                <div className="structure__row">
                  <div className="structure__title2">
                    <span style={{ backgroundColor: '  #C9FB00' }} />
                    {translate('cover.closing')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="explore">
        <div className="wrap">
          <div className="explore__tabs">
            <div
              className="explore__text"
              dangerouslySetInnerHTML={{ __html: translate('cover.explore_the_examples') }}
            />
            <div className="explore__btns">
              <button type="button" className="btn btn-outline-primary btn-outline--purple active">
                {translate('cover.popular')}
              </button>
              <button type="button" className="btn btn-outline-primary btn-outline--purple ">
                {translate('cover.all')}
              </button>
            </div>
          </div>
          <div className="templates__wrap">
            {coversToShow.map((item) => (
              <CoverTemplate
                key={item.id}
                id={item.id}
                title={item.name}
                image={item.preview}
                description={item.description}
              />
            ))}
          </div>
          <div className="btn-center">
            <button type="button" className="btn btn-primary" onClick={handelShowMoreClick}>
              {translate('cover.show_more')}
            </button>
          </div>
        </div>
      </div>

      <div className="tips">
        <div className="wrap--small">
          <div className="title__wrap">
            <div className="title__label">TIPS</div>
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: translate('cover.how_to_create') }}
            />
            <div className="title__text">{translate('cover.how_to_create_desc')}</div>
          </div>
          <div className="tips__wrap">
            <div className="tips__row">
              <div className="tips__img">
                <img src={width > 565 ? t1 : t1m} alt="tip simg" />
              </div>
              <div className="tips__content">
                <div className="tips__title">{translate('cover.do_research_first')}</div>
                <div className="tips__text">{translate('cover.do_research_first_desc')}</div>
              </div>
            </div>
            <div className="tips__row">
              <div className="tips__img">
                <img src={width > 565 ? t2 : t2m} alt="tip simg" />
              </div>
              <div className="tips__content">
                <div className="tips__title">{translate('cover.focus_the_future')}</div>
                <div className="tips__text">{translate('cover.focus_the_future_desc')}</div>
              </div>
            </div>
            <div className="tips__row">
              <div className="tips__img">
                <img src={width > 565 ? t3 : t3m} alt="tip simg" />
              </div>
              <div className="tips__content">
                <div className="tips__title">{translate('cover.open_strong')}</div>
                <div className="tips__text">{translate('cover.open_strong_desc')}</div>
              </div>
            </div>
            <div className="tips__row">
              <div className="tips__img">
                <img src={width > 565 ? t4 : t4m} alt="tip simg" />
              </div>
              <div className="tips__content">
                <div className="tips__title">{translate('cover.emphasize_personal_value')}</div>
                <div className="tips__text">{translate('cover.emphasize_personal_value_desc')}</div>
              </div>
            </div>
            <div className="tips__row">
              <div className="tips__img">
                <img src={width > 565 ? t5 : t5m} alt="tip simg" />
              </div>
              <div className="tips__content">
                <div className="tips__title">{translate('cover.convey_enthusiasm')}</div>
                <div className="tips__text">{translate('cover.convey_enthusiasm_desc')}</div>
              </div>
            </div>
            <div className="tips__row">
              <div className="tips__img">
                <img src={width > 565 ? t6 : t6m} alt="tip simg" />
              </div>
              <div className="tips__content">
                <div className="tips__title">{translate('cover.keep_it_short')}</div>
                <div className="tips__text">{translate('cover.keep_it_short_desc')}</div>
              </div>
            </div>
          </div>
          <div className="btn-center">
            <AuthButton to="/my-covers/create/" content={translate('cover.create_cover')} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Cover;
