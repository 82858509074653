import './style.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import circle1 from 'src/assets/img/cv/circle1.svg';
import circle2 from 'src/assets/img/cv/circle2.svg';
import cv from 'src/assets/img/cv/cv.png';
import exmArr from 'src/assets/img/cv/exm-ar.svg';
import exmAvatar from 'src/assets/img/cv/exm-avatar.png';
import exm1 from 'src/assets/img/cv/exm1.png';
// import exm1 from 'src/assets/img/cv/exm1.jpg';
import exm2 from 'src/assets/img/cv/exm2.png';
// import exm2 from 'src/assets/img/cv/exm2.jpg';
import t1 from 'src/assets/img/cv/tips/1.png';
import t1m from 'src/assets/img/cv/tips/1m.png';
import t2 from 'src/assets/img/cv/tips/2.png';
import t2m from 'src/assets/img/cv/tips/2m.png';
import t3 from 'src/assets/img/cv/tips/3.png';
import t3m from 'src/assets/img/cv/tips/3m.png';
import t4 from 'src/assets/img/cv/tips/4.png';
import t4m from 'src/assets/img/cv/tips/4m.png';
import t5 from 'src/assets/img/cv/tips/5.png';
import t5m from 'src/assets/img/cv/tips/5m.png';
import t6 from 'src/assets/img/cv/tips/6.png';
import t6m from 'src/assets/img/cv/tips/6m.png';
import t7 from 'src/assets/img/cv/tips/7.png';
import t7m from 'src/assets/img/cv/tips/7m.png';
import t8 from 'src/assets/img/cv/tips/8.png';
import t8m from 'src/assets/img/cv/tips/8m.png';
import compLg from 'src/assets/img/home/comp-lg.svg';
import compSm from 'src/assets/img/home/comp-sm.svg';
import s2n1 from 'src/assets/img/home/s2-1.svg';
import s2n2 from 'src/assets/img/home/s2-2.svg';
import s2n3 from 'src/assets/img/home/s2-3.svg';
import s2n4 from 'src/assets/img/home/s2-4.svg';
import s4n1 from 'src/assets/img/home/s4-1.svg';
import s4n2 from 'src/assets/img/home/s4-2.svg';
import s4n3 from 'src/assets/img/home/s4-3.svg';
import s4n4 from 'src/assets/img/home/s4-4.svg';
import s4n5 from 'src/assets/img/home/s4-5.svg';
import s4n6 from 'src/assets/img/home/s4-6.svg';
import { AuthButton } from 'src/components';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { templatesActions } from 'src/store/actions';

import { CvTemplate } from './components';

const Cv = () => {
  const resumeTemplates = useSelector((state2) => state2.templatesReducer.resumeTemplates);
  const { translate } = useTranslation();

  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const [numElements, setNumElements] = useState(12);
  const [cvNumElements, setCvNumElements] = useState(12);

  useEffect(() => {
    dispatch(templatesActions.getResumeTemplates());
  }, []);

  const handelShowMoreCvClick = () => {
    setNumElements(numElements + 3);
  };

  const handelShowMoreTemplateClick = () => {
    setCvNumElements(numElements + 3);
  };

  const cvToShow = resumeTemplates.slice(0, numElements);
  const cvTemplateToShow = resumeTemplates.slice(0, cvNumElements);

  return (
    <>
      <div className="firstGrey">
        <div className="s1--cv">
          <div className="wrap">
            <div className="cvS1">
              <div className="s1__left">
                <div className="s1__t1 s1__t1--grey">{translate('cv.title')}</div>
                <div className="s1__t2">{translate('cv.sub_title')}</div>
                {width <= 565 && width > 500 && <img src={cv} className="cvS1__mobImg" alt="img" />}
                {width <= 500 && <img src={cv} className="cvS1__mobImg" alt="img" />}
                <div className="s1__t3">{translate('cv.sub_title_desc')}</div>
                <AuthButton to="/my-cv/create/" content={translate('cv.create_btn')} />
                <div className="s1__comp">
                  <img src={width >= 565 ? compLg : compSm} alt="" />
                </div>
              </div>
              {width > 565 && (
                <div className="cvS1__right" style={{ backgroundImage: `url(${cv})` }} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="s2">
        <div className="wrap">
          <div className="title__wrap">
            <div className="title__label">{translate('Home.process')}</div>
            <div className="title">{translate('Home.see_how_it_works')}</div>
            <div className="title__text">{translate('Home.see_how_it_works_desc')}</div>
          </div>
          <div className="s2__wrap">
            <div className="s2__col">
              <img src={s2n1} alt="img" width="43.09" height="45" />
              <div className="s2__title">{translate('Home.pick_a_template')}</div>
              <div className="s2__text">{translate('Home.choose_one')}</div>
            </div>
            <div className="s2__col">
              <img src={s2n2} alt="img" width="43.09" height="45" />
              <div className="s2__title">{translate('Home.customize_layout')}</div>
              <div className="s2__text">{translate('Home.customize_the_layout')}</div>
            </div>
            <div className="s2__col">
              <img src={s2n3} alt="img" width="43.09" height="45" />
              <div className="s2__title">{translate('Home.fill_in_the_blanks')}</div>
              <div className="s2__text">{translate('Home.fill_in_your_resume')}</div>
            </div>
            <div className="s2__col">
              <img src={s2n4} alt="img" width="43.09" height="45" />
              <div className="s2__title">{translate('Home.download')}</div>
              <div className="s2__text">{translate('Home.download_your_cv')}</div>
            </div>
          </div>
          <div className="s2__btn btn-center">
            <AuthButton to="/my-cv/create/" content={translate('cv.create_btn')} />
          </div>
        </div>
      </div>
      <div className="templates">
        <div className="wrap">
          <div className="title__wrap">
            <div className="title__label">{translate('Home.templates')}</div>
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: translate('Home.choose_a_sample') }}
            />
            <div className="title__text">{translate('Home.choose_a_sample_desc')}</div>
          </div>
          <div className="templates__tab">
            <button type="button" className="btn btn-outline-primary btn-outline--purple active">
              {translate('Home.choose_popular')}
            </button>
            <button type="button" className="btn btn-outline-primary btn-outline--purple ">
              {translate('Home.choose_all')}
            </button>
          </div>
          <div className="templates__wrap">
            {cvToShow.map((item) => (
              <CvTemplate
                key={item.id}
                id={item.id}
                title={item.name}
                image={item.preview}
                description={item.description}
              />
            ))}
          </div>
          <div className="btn-center">
            <button type="button" className="btn btn-primary" onClick={handelShowMoreCvClick}>
              {translate('cover.show_more')}
            </button>
          </div>
        </div>
      </div>
      <div className="examples">
        <div className="wrap">
          <div className="title__wrap">
            <div className="title__label">{translate('cv.examples')}</div>
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: translate('cv.how_a_perfect') }}
            />
            <div className="title__text">{translate('cv.how_a_perfect_desc')}</div>
          </div>
        </div>
        <div className="examples__container">
          <div className="wrap">
            <div className="examples__wrap">
              <div className="examples__flex">
                <div className="examples__item">
                  <div className="examples__name">{translate('cv.before')}</div>
                  <div className="examples__img">
                    <img src={exm1} alt="example" />
                  </div>
                </div>
                <div className="examples__item">
                  <div className="examples__name">{translate('cv.after')}</div>
                  <div className="examples__img">
                    <img src={exm2} alt="example" />
                  </div>
                </div>
              </div>

              {width > 565 && <img src={exmArr} alt="decor" className="examples__arr" />}
              {width > 991 && (
                <>
                  <img src={circle1} alt="decor" className="examples__circle1" />
                  <img src={circle2} alt="decor" className="examples__circle2" />
                </>
              )}

              <img src={exmAvatar} alt="avatar" className="examples__avatar" />
              <div className="examples__text">
                <span>{translate('cv.follow_best_practices')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="explore">
        <div className="wrap">
          <div className="explore__tabs">
            <div className="explore__text">
              {translate('cover.explore_the_examples_without_html')}
            </div>
            <div className="explore__btns">
              <button type="button" className="btn btn-outline-primary btn-outline--purple active">
                {translate('Home.choose_popular')}
              </button>
              <button type="button" className="btn btn-outline-primary btn-outline--purple ">
                {translate('Home.choose_all')}
              </button>
            </div>
          </div>
          <div className="templates__wrap">
            {cvTemplateToShow.map((item) => (
              <CvTemplate
                key={item.id}
                id={item.id}
                title={item.name}
                image={item.preview}
                description={item.description}
              />
            ))}
          </div>
          <div className="btn-center">
            <button type="button" className="btn btn-primary" onClick={handelShowMoreTemplateClick}>
              {translate('cover.show_more')}
            </button>
          </div>
        </div>
      </div>

      <div className="s4">
        <div className="wrap">
          <div className="title__wrap">
            <div className="title__label">{translate('Home.benefits')}</div>
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: translate('Home.what_you_get') }}
            />
            <div className="title__text">{translate('Home.what_you_get_desc')}</div>
          </div>
          <div className="s4__wrap">
            <div className="s4__col">
              <div className="s4__top">
                <img src={s4n1} alt="icon" />
                {translate('Home.unlimited')}
              </div>
              <div className="s4__text">
                {translate('Home.our_templates_1')}
                <br /> {translate('Home.our_templates_2')}
                <Link to="/cv-templates">{translate('Home.our_templates_3')}</Link>
              </div>
            </div>
            <div className="s4__col">
              <div className="s4__top">
                <img src={s4n2} alt="icon" />
                {translate('Home.cover_letter_builder')}
              </div>
              <div className="s4__text">
                {translate('Home.generate_a_cover_1')}
                <Link to="/cover">{translate('Home.generate_a_cover_2')}</Link>
              </div>
            </div>
            <div className="s4__col">
              <div className="s4__top">
                <img src={s4n3} alt="icon" />
                {translate('Home.quick_smart_approach')}
              </div>
              <div className="s4__text">
                {translate('Home.cv_builder_1')}
                <Link to="/cv">CV</Link> or{' '}
                <Link to="/cover">{translate('Home.cv_builder_2')}</Link>.
              </div>
            </div>
            <div className="s4__col">
              <div className="s4__top">
                <img src={s4n4} alt="icon" />
                {translate('Home.tips_career_articles')}
              </div>
              <div className="s4__text">{translate('Home.tips_career_articles_desc')}</div>
            </div>
            <div className="s4__col">
              <div className="s4__top">
                <img src={s4n5} alt="icon" />
                {translate('Home.easy_editable_templates')}
              </div>
              <div className="s4__text">{translate('Home.easy_editable_templates_desc')}</div>
            </div>
            <div className="s4__col">
              <div className="s4__top">
                <img src={s4n6} alt="icon" />
                {translate('Home.high_quality_output')}
              </div>
              <div className="s4__text">{translate('Home.high_quality_output_desc')}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="tips">
        <div className="wrap--small">
          <div className="title__wrap">
            <div className="title__label">{translate('cv.tips')}</div>
            <div className="title" dangerouslySetInnerHTML={{ __html: translate('cv.how_to') }} />
            <div className="title__text">{translate('cv.how_to_desc')}</div>
          </div>
          <div className="tips__wrap">
            <div className="tips__row">
              <div className="tips__img">
                <img src={width > 565 ? t1 : t1m} alt="tip simg" />
              </div>
              <div className="tips__content">
                <div className="tips__title">{translate('cv.pick_the_right')}</div>
                <div className="tips__text">
                  {translate('cv.pick_the_right_desc_1')}
                  <Link to="/cv">{translate('cv.pick_the_right_desc_2')}</Link>
                  <Link to="/cover-templates">{translate('cv.pick_the_right_desc_3')}</Link>
                  {translate('cv.pick_the_right_desc_4')}
                  <Link to="/cv-examples">{translate('cv.pick_the_right_desc_5')}</Link>
                  {translate('cv.pick_the_right_desc_6')}
                  <Link to="/cv">{translate('cv.pick_the_right_desc_7')}</Link>
                </div>
              </div>
            </div>
            <div className="tips__row">
              <div className="tips__img">
                <img src={width > 565 ? t2 : t2m} alt="tip simg" />
              </div>
              <div className="tips__content">
                <div
                  className="tips__title"
                  dangerouslySetInnerHTML={{ __html: translate('cv.tailor') }}
                />
                <div className="tips__text">{translate('cv.tailor_desc')}</div>
              </div>
            </div>
            <div className="tips__row">
              <div className="tips__img">
                <img src={width > 565 ? t3 : t3m} alt="tip simg" />
              </div>
              <div className="tips__content">
                <div className="tips__title">{translate('cv.make_short')}</div>
                <div className="tips__text">{translate('cv.make_short_desc')}</div>
              </div>
            </div>
            <div className="tips__row">
              <div className="tips__img">
                <img src={width > 565 ? t4 : t4m} alt="tip simg" />
              </div>
              <div className="tips__content">
                <div className="tips__title">{translate('cv.miss_vital')}</div>
                <div className="tips__text">{translate('cv.miss_vital_desc')}</div>
              </div>
            </div>
            <div className="tips__row">
              <div className="tips__img">
                <img src={width > 565 ? t5 : t5m} alt="tip simg" />
              </div>
              <div className="tips__content">
                <div className="tips__title">{translate('cv.focus_achievements')}</div>
                <div className="tips__text">{translate('cv.focus_achievements_desc')}</div>
              </div>
            </div>
            <div className="tips__row">
              <div className="tips__img">
                <img src={width > 565 ? t6 : t6m} alt="tip simg" />
              </div>
              <div className="tips__content">
                <div
                  className="tips__title"
                  dangerouslySetInnerHTML={{ __html: translate('cv.prioritise') }}
                />
                <div className="tips__text">{translate('cv.prioritise_desc')}</div>
              </div>
            </div>
            <div className="tips__row">
              <div className="tips__img">
                <img src={width > 565 ? t7 : t7m} alt="tip simg" />
              </div>
              <div className="tips__content">
                <div className="tips__title">{translate('cv.be_honest')}</div>
                <div className="tips__text">{translate('cv.be_honest_desc')}</div>
              </div>
            </div>
            <div className="tips__row">
              <div className="tips__img">
                <img src={width > 565 ? t8 : t8m} alt="tip simg" />
              </div>
              <div className="tips__content">
                <div
                  className="tips__title"
                  dangerouslySetInnerHTML={{ __html: translate('cv.complement_your_cv') }}
                />
                <div className="tips__text">
                  {translate('cv.complement_your_cv_desc_1')}
                  <Link to="/cover">{translate('cv.complement_your_cv_desc_2')}</Link>{' '}
                  {translate('cv.complement_your_cv_desc_3')}
                  <Link to="/cv"> {translate('cv.complement_your_cv_desc_4')}</Link>.
                </div>
              </div>
            </div>
          </div>
          <div className="btn-center">
            <AuthButton to="/my-cv/create/" content={translate('cv.create_btn')} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Cv;
