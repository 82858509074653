import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import blogWhat from 'src/assets/img/blog/blogWhat.png';
import t1 from 'src/assets/img/cover/tips/1.png';
import t1m from 'src/assets/img/cover/tips/1m.png';
import t2 from 'src/assets/img/cover/tips/2.png';
import t2m from 'src/assets/img/cover/tips/2m.png';
import t3 from 'src/assets/img/cover/tips/3.png';
import t3m from 'src/assets/img/cover/tips/3m.png';
import t4 from 'src/assets/img/cover/tips/4.png';
import t4m from 'src/assets/img/cover/tips/4m.png';
import t5 from 'src/assets/img/cover/tips/5.png';
import t5m from 'src/assets/img/cover/tips/5m.png';
import t6 from 'src/assets/img/cover/tips/6.png';
import t6m from 'src/assets/img/cover/tips/6m.png';
import sprite from 'src/assets/sprite.svg';
import { BigWaiter } from 'src/components';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { templatesActions } from 'src/store/actions';

import BlogListComponent from './components/blogsList';

const BlogDetail = () => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const { translate } = useTranslation();

  const blog = useSelector((state) => state.templatesReducer.blog);
  const fixWaiter = useSelector((state) => state.templatesReducer.fixWaiter);
  console.log(blog);
  const { detail } = useParams();

  useEffect(() => {
    dispatch(templatesActions.getBlog({ slug: detail }));
  }, []);

  return (
    <>
      {fixWaiter ? (
        <BigWaiter />
      ) : (
        <>
          {blog && (
            <div className="wrap">
              <nav className="breadcrumb">
                <ul>
                  <li>
                    <Link to="/">{translate('blog.home')}</Link>
                    <svg width="11" height="6">
                      <use xlinkHref={`${sprite}#v`} />
                    </svg>
                  </li>
                  <li>
                    <Link to="/blog">{translate('blog.blog')}</Link>
                    <svg width="11" height="6">
                      <use xlinkHref={`${sprite}#v`} />
                    </svg>
                  </li>
                  <li>
                    <span>{blog.title}</span>
                  </li>
                </ul>
              </nav>

              <div className="blogDetail__title">{blog.title}</div>
              <div className="blogDetail__data">{blog.created}</div>
              <div className="blogDetail__img">
                <img src={blog.pictureUrl} alt={blog.title} />
              </div>
              <div
                className="blogDetail__wrap"
                dangerouslySetInnerHTML={{ __html: blog.detailText }}
              />

              <div className="blogDetail__what">
                {width <= 767 && (
                  <div className="blogDetail__what__t">{translate('blog.what_is_cover')}</div>
                )}
                <div className="row">
                  <div className="col-12 col-md-6">
                    <img src={blogWhat} alt="blog detail" />
                  </div>
                  <div className="col-12 col-md-6">
                    {width > 767 && (
                      <div className="blogDetail__what__t">{translate('blog.what_is_cover')}</div>
                    )}

                    <p>{translate('blog.what_is_cover_desc')}</p>

                    <div className="blogDetail__what__flex">
                      <Link to="/my-cv/create/" className="btn btn-primary">
                        {translate('blog.see_templates')}
                      </Link>

                      <Link to="/my-covers/create/" className="btn btn-primary">
                        {translate('blog.see_templates2')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tips">
                <div className="wrap--small">
                  <div
                    className="blogDetail__title2"
                    dangerouslySetInnerHTML={{ __html: translate('blog.how_to_create') }}
                  />
                  <div className="tips__wrap">
                    <div className="tips__row">
                      <div className="tips__img">
                        <img src={width > 565 ? t1 : t1m} alt="tip simg" />
                      </div>
                      <div className="tips__content">
                        <div className="tips__title">{translate('blog.do_research_first')}</div>
                        <div className="tips__text">{translate('blog.do_research_first_desc')}</div>
                      </div>
                    </div>
                    <div className="tips__row">
                      <div className="tips__img">
                        <img src={width > 565 ? t2 : t2m} alt="tip simg" />
                      </div>
                      <div className="tips__content">
                        <div className="tips__title">{translate('blog.focus_it_on')}</div>
                        <div className="tips__text">{translate('blog.focus_it_on_desc')}</div>
                      </div>
                    </div>
                    <div className="tips__row">
                      <div className="tips__img">
                        <img src={width > 565 ? t3 : t3m} alt="tip simg" />
                      </div>
                      <div className="tips__content">
                        <div className="tips__title">{translate('blog.open_strong')}</div>
                        <div className="tips__text">{translate('blog.open_strong_desc')}</div>
                      </div>
                    </div>
                    <div className="tips__row">
                      <div className="tips__img">
                        <img src={width > 565 ? t4 : t4m} alt="tip simg" />
                      </div>
                      <div className="tips__content">
                        <div className="tips__title">
                          {translate('blog.emphasize_your_personal')}
                        </div>
                        <div className="tips__text">
                          {translate('blog.emphasize_your_personal_desc')}
                        </div>
                      </div>
                    </div>
                    <div className="tips__row">
                      <div className="tips__img">
                        <img src={width > 565 ? t5 : t5m} alt="tip simg" />
                      </div>
                      <div className="tips__content">
                        <div className="tips__title">{translate('blog.convey_enthusiasm')}</div>
                        <div className="tips__text">{translate('blog.convey_enthusiasm_desc')}</div>
                      </div>
                    </div>
                    <div className="tips__row">
                      <div className="tips__img">
                        <img src={width > 565 ? t6 : t6m} alt="tip simg" />
                      </div>
                      <div className="tips__content">
                        <div className="tips__title">{translate('blog.keep_it_short')}</div>
                        <div className="tips__text">{translate('blog.keep_it_short_desc')}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="blogDetail__bot">
                <div className="blogDetail__title2">{translate('blog.related_articles')}</div>
                <div className="blog__block mt-0">
                  <div className="row">
                    {blog &&
                      blog.relatedArticles.map((item) => (
                        <div className="col-12 col-sm-6 col-lg-4" key={item.id}>
                          <BlogListComponent
                            to={item.slug}
                            image={item.pictureUrl}
                            title={item.title}
                            data={item.created}
                            description=""
                            cssClass="blog__item--small"
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BlogDetail;
