import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { months } from 'src/constants';
import { getDataFormat } from 'src/helpers';

import s from './style.module.scss';

const BrightBlue = ({ setOnRendered, setOverHeight }) => {
  const { currentResume } = useSelector((state) => state.templatesReducer);
  useEffect(() => {
    setOverHeight(1000);
  });
  const { image } = currentResume;

  useEffect(() => {
    const img = document.getElementById('avatar');
    if (img) {
      const imgAvatar = document.getElementById('avatar');
      imgAvatar.onload = () => {
        setOnRendered(true);
      };
    } else {
      setOnRendered(true);
    }
  }, []);

  return (
    <>
      {currentResume && (
        <div className={s.cari}>
          <div className={s.left}>
            {image && (
              <div className={s.avatar__wrap}>
                <div className={s.avatar}>
                  <img width={230} src={image} alt="avatar" id="avatar" />
                </div>
              </div>
            )}
            <div className={s.about}>
              <div className={s.name}>Contacts</div>

              <div className={s.about__block}>
                <div className={s.title__left}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                    imageRendering="optimizeQuality"
                    preserveAspectRatio="xMidYMid meet"
                    shapeRendering="geometricPrecision"
                    textRendering="geometricPrecision"
                    version="1.0"
                    viewBox="-0.0 -0.0 58.3 58.3"
                    zoomAndPan="magnify"
                    height={20}
                  >
                    <g id="__id125_sw3qh7yex">
                      <path
                        d="m24.186 22.5c-5.4466 3.1445 2.8637 18.724 8.8237 15.283l5.526 9.5712c-2.5146 1.4519-4.6432 2.3671-7.5585 0.70054-8.0946-4.6273-17.005-20.054-16.751-29.256 0.08805-3.196 1.971-4.4482 4.434-5.8702l5.526 9.5712z"
                        fill="#354f59"
                      />
                    </g>
                    <g id="__id126_sw3qh7yex">
                      <path
                        d="m26.4704,22.2399c-.58849.33978-1.3458.13697-1.68564-.45158l-5.2095-9.02316c-.33984-.58862-.13703-1.34586.45152-1.6857l2.73799-1.58075c.58855-.33984 1.34598-.13685 1.68576.4517l5.2095,9.0231c.33978.58849.13691,1.3458-.45158,1.68558l-2.73805,1.58081z"
                        fill="#354f59"
                      />
                    </g>
                    <g id="__id127_sw3qh7yex">
                      <path
                        d="m40.7023,46.8904c-.58849.33978-1.3458.13697-1.68564-.45164l-5.2095-9.02316c-.33984-.58855-.13703-1.34586.45152-1.68564l2.73799-1.58081c.58855-.33978 1.34598-.13679 1.68576.4517l5.2095,9.0231c.33978.58855.13691,1.34586-.45158,1.68564l-2.73805,1.58081z"
                        fill="#354f59"
                      />
                    </g>
                    <g id="__id128_sw3qh7yex">
                      <path
                        d="m29.141-2.845e-15h4.3e-4v0.00679c8.05 1.2e-4 15.335 3.26 20.604 8.5296s8.5291 12.554 8.5293 20.604h0.00679v0.00136h-0.00679c-1.2e-4 8.05-3.26 15.335-8.5296 20.604s-12.554 8.5291-20.604 8.5293v0.00679h-0.00136v-0.00679c-8.05-1.2e-4 -15.335-3.26-20.604-8.5296s-8.5291-12.554-8.5293-20.604h-0.00679v-0.00136h0.00679c1.2e-4 -8.05 3.26-15.335 8.5296-20.604s12.554-8.5291 20.604-8.5293v-0.00679h9.3e-4zm4.3e-4 3.4758v0.00679h-0.00136v-0.00679c-7.0792 1.9e-4 -13.494 2.8753-18.141 7.5226s-7.5229 11.063-7.5229 18.142h0.00679v0.00136h-0.00679c1.9e-4 7.0792 2.8753 13.494 7.5226 18.141s11.063 7.5229 18.142 7.5229v-0.00679h0.00136v0.00679c7.0792-1.9e-4 13.494-2.8753 18.141-7.5226s7.5229-11.063 7.5229-18.142h-0.00679v-0.00136h0.00679c-1.9e-4 -7.0792-2.8753-13.494-7.5226-18.141s-11.063-7.5229-18.142-7.5229z"
                        fillRule="nonzero"
                        fill="#354f59"
                      />
                    </g>
                  </svg>
                </div>
                <div className={s.about__block__right}>{currentResume.phone}</div>
              </div>

              <div className={s.about__block}>
                <div className={s.title__left}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid meet"
                    version="1.0"
                    viewBox="3.0 6.0 26.0 20.0"
                    zoomAndPan="magnify"
                    width={20}
                  >
                    <g data-name="1" id="__id131_sw3qh7yex">
                      <path
                        d="M16,15.834l13-7.8V8a2,2,0,0,0-2-2H5A2,2,0,0,0,3,8v.034Z"
                        fill="#354f59"
                      />
                      <path
                        d="M16.515,17.857a1,1,0,0,1-1.03,0L3,10.366V24a2,2,0,0,0,2,2H27a2,2,0,0,0,2-2V10.366Z"
                        fill="#354f59"
                      />
                    </g>
                  </svg>
                </div>
                <div className={s.about__block__right}>{currentResume.email}</div>
              </div>

              <div className={s.about__block}>
                <div className={s.title__left}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid meet"
                    version="1.0"
                    viewBox="10.0 4.0 28.0 40.0"
                    zoomAndPan="magnify"
                    height={20}
                    fill="#354f59"
                  >
                    <g id="__id121_s493g7wdh8">
                      <path d="M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
                    </g>
                  </svg>
                </div>
                <div className={s.about__block__right}>
                  <div>
                    {currentResume.country.name}, {currentResume.city.name}
                  </div>

                  <div>
                    {currentResume.state} {currentResume.zip}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={s.cari__left}>
            <div className="section-col">
              {currentResume.languages.length > 0 && (
                <div className={`section ${s.section}`}>
                  <div className={s.about}>
                    <div className={s.name}>Languagess</div>
                    {currentResume.languages.map((item) => (
                      <div className={s.text} key={item.id}>
                        {item.language} - {item.proficiency}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {currentResume.hardSkills.length > 0 && (
                <div className={`section ${s.section}`}>
                  <div className={s.about}>
                    <div className={s.name}>Hard skills</div>
                    {currentResume.hardSkills.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.skill}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {currentResume.softSkills.length > 0 && (
                <div className={`section ${s.section}`}>
                  <div className={s.about}>
                    <div className={s.name}>Soft skills</div>
                    {currentResume.softSkills.map((item) => (
                      <div className={s.skill} key={item.id}>
                        {item.skill}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={s.cari__right}>
            <div className="section-col">
              <div className="section">
                <div className={s.title__wrap}>
                  <div className={s.title}>
                    {currentResume.firstName} {currentResume.lastName}
                  </div>
                  <div className={s.title2}>{currentResume.jobTitle}</div>
                </div>
              </div>
              {currentResume.experiences.length > 0 && (
                <div className={`section ${s.section}`}>
                  {currentResume.experiences.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Experiences</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__top}>
                            <div className={s.work__name}>{item.companyName}</div>
                            <div className={s.work__data}>
                              <div className={s.work__data__col}>
                                {getDataFormat(item.startDate)}
                              </div>
                              -
                              <div className={s.work__data__col}>
                                {item.isPresent ? 'Present' : getDataFormat(item.endDate)}
                              </div>
                            </div>
                          </div>
                          <div className={s.work__job}>{item.jobTitle}</div>

                          <div className={s.text}>{item.responsibilities}</div>
                          <div className={s.text}>{item.achievements}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.educations.length > 0 && (
                <div className={`section ${s.section}`}>
                  {currentResume.educations.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Education</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__top}>
                            <div className={s.work__name}>{item.universityName}</div>
                            <div className={s.work__data}>
                              <div className={s.work__data__col}>
                                {months[item.graduationMonth].name} {item.graduationYear}
                              </div>
                            </div>
                          </div>

                          <div className={s.work__job}>
                            {item.country.name}, {item.city.name}
                          </div>

                          <div className={s.text}>{item.achievements}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.hobbies.length > 0 && (
                <div className={`section ${s.section}`}>
                  {currentResume.hobbies.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Hobbies</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__job}>{item.name}</div>

                          <div className={s.text}>{item.description}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {currentResume.certificates.length > 0 && (
                <div className={`section ${s.section}`}>
                  {currentResume.certificates.map((item) => (
                    <div className={`sub-section ${s.work__sub__section}`} key={item.id}>
                      <div className={s.work}>
                        <div className={s.work__title}>Certifications</div>

                        <div className={s.work__wrap}>
                          <div className={s.work__job}>{item.name}</div>

                          <div className={s.text}>{item.skills}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

BrightBlue.propTypes = {
  setOnRendered: PropTypes.func.isRequired,
  setOverHeight: PropTypes.func.isRequired,
};
export default BrightBlue;
