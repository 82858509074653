import './style.scss';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import planetLang from 'src/assets/img/home/lang-planet.svg';
import sprite from 'src/assets/sprite.svg';
import { FogotPass, RessetPass, SignIn, SignSoc, SignUp } from 'src/components';
import { useTranslation } from 'src/hooks';

const Header = () => {
  const [headerFix, setHeaderFix] = useState(false);
  const { translate, setLanguage, language } = useTranslation();
  const [burger, setBurger] = useState(false);
  const ifAuth = !!useSelector((state) => state.authReducer.access_token);
  const location = useLocation();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset) {
        setHeaderFix(true);
      } else {
        setHeaderFix(false);
      }
    });
  }, []);

  useEffect(() => {
    setBurger(false);
  }, [location.pathname]);

  const handelChangeLang = (e) => {
    e.preventDefault();
    setLanguage(e.target.value);
    window.location.reload();
  };

  // signIn
  const [showSignIn, setShowSignIn] = useState(false);
  const handleCloseSignIn = () => setShowSignIn(false);
  const handleShowSignIn = () => setShowSignIn(true);

  // signIn
  const [showSignUp, setShowSignUp] = useState(false);
  const handleCloseSignUp = () => setShowSignUp(false);
  const handleShowSignUp = () => setShowSignUp(true);

  // foggot
  const [showFoggot, setShowFoggot] = useState(false);
  const handleCloseFoggot = () => setShowFoggot(false);
  const handleShowFoggot = () => setShowFoggot(true);

  // ressetPass
  const { token } = useParams();
  const [showRessetPass, setShowRessetPass] = useState(!!token);
  const handleCloseRessetPass = () => setShowRessetPass(false);
  const handleShowRessetPass = () => setShowRessetPass(true);

  // signSoc
  const [showSignSoc, setShowSignSoc] = useState(false);
  const handleCloseSignSoc = () => setShowSignSoc(false);
  const handleShowSignSoc = () => setShowSignSoc(true);

  return (
    <>
      <div className={`header ${headerFix ? 'fix' : ''}`}>
        <div className="wrap">
          <div className="header__wrap">
            <div className="header__logo">
              <Link to="/">{translate('cv.title')}</Link>
            </div>
            <nav className="header__nav">
              <ul>
                <li className="header__nav__li">
                  <Link to="/cv" className="header__nav__link">
                    {translate('cv.title')}
                    <svg width="11" height="6">
                      <use xlinkHref={`${sprite}#v`} />
                    </svg>
                  </Link>
                  <ul className="header__nav__ul">
                    <li>
                      <Link to="/cv">{translate('cv.title')}</Link>
                    </li>
                    <li>
                      <Link to="/cv-examples">{translate('cv.cv_examples')}</Link>
                    </li>
                    <li>
                      <Link to="/cv-templates">{translate('cv.cv_templates')}</Link>
                    </li>
                  </ul>
                </li>
                <li className="header__nav__li">
                  <Link to="/cover" className="header__nav__link">
                    {translate('Home.cv_builder_2')}
                    <svg width="11" height="6">
                      <use xlinkHref={`${sprite}#v`} />
                    </svg>
                  </Link>
                  <ul className="header__nav__ul">
                    <li>
                      <Link to="/cover">{translate('components.clb')}</Link>
                    </li>
                    <li>
                      <Link to="/cover-examples">{translate('components.cle')}</Link>
                    </li>
                    <li>
                      <Link to="/cover-templates">{translate('components.clt')}</Link>
                    </li>
                  </ul>
                </li>
                <li className="header__nav__li">
                  <Link to="/blog" className="header__nav__link">
                    {translate('components.blog')}
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="lang">
              <img src={planetLang} width={23} height={23} alt="" className="lang-image" />
              <select onChange={handelChangeLang} defaultValue={language}>
                <option value="en">English</option>
                <option value="fn">Suomi</option>
                <option value="lit">Lietuviškai</option>
              </select>
            </div>
            <div className="header__auth">
              {ifAuth ? (
                <Link to="/account" className="btn btn-outline-primary">
                  <svg width="20" height="20" viewBox="0 0 20 20">
                    <use xlinkHref={`${sprite}#user`} />
                  </svg>
                  {translate('account.my_account')}
                </Link>
              ) : (
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleShowSignSoc}
                >
                  <svg width="20" height="20" viewBox="0 0 20 20">
                    <use xlinkHref={`${sprite}#user`} />
                  </svg>
                  {translate('account.my_account')}
                </button>
              )}
            </div>
            <button
              type="button"
              className={`header__burger ${burger ? 'active' : ''}`}
              onClick={() => setBurger(!burger)}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" className="open">
                <use xlinkHref={`${sprite}#burger-open`} />
              </svg>
              <svg width="24" height="24" viewBox="0 0 24 24" className="close">
                <use xlinkHref={`${sprite}#burger-close`} />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <nav className={`header__mobMenu ${burger ? 'fadeIn active' : ''}`}>
        <ul>
          <li>
            <Link to="/">{translate('blog.home')}</Link>
          </li>
          <li>
            <Link to="/cv">{translate('cv.title')}</Link>
          </li>
          <li>
            <Link to="/cv-examples">{translate('cv.cv_examples')}</Link>
          </li>
          <li>
            <Link to="/cv-templates">{translate('cv.cv_templates')}</Link>
          </li>
          <li>
            <Link to="/cover">{translate('components.clb')}</Link>
          </li>
          <li>
            <Link to="/cover-templates">{translate('components.clt')}</Link>
          </li>
          <li>
            <Link to="/cover-examples">{translate('components.cle')}</Link>
          </li>
          <li>
            <Link to="/blog">{translate('components.blog')}</Link>
          </li>
        </ul>
        {ifAuth ? (
          <Link to="/account" className="btn btn-outline-primary">
            <svg width="20" height="20" viewBox="0 0 20 20">
              <use xlinkHref={`${sprite}#user`} />
            </svg>
            {translate('account.my_account')}
          </Link>
        ) : (
          <button type="button" className="btn btn-outline-primary" onClick={handleShowSignSoc}>
            <svg width="20" height="20" viewBox="0 0 20 20">
              <use xlinkHref={`${sprite}#user`} />
            </svg>
            {translate('account.my_account')}
          </button>
        )}
      </nav>

      {showSignSoc && (
        <SignSoc
          show={showSignSoc}
          handleClose={handleCloseSignSoc}
          handleShowSignUp={handleShowSignUp}
          handleShowSignIn={handleShowSignIn}
        />
      )}

      {showSignIn && (
        <SignIn
          show={showSignIn}
          handleClose={handleCloseSignIn}
          handleShowSignUp={handleShowSignUp}
          handleShowFoggot={handleShowFoggot}
        />
      )}

      {showSignUp && (
        <SignUp
          show={showSignUp}
          handleClose={handleCloseSignUp}
          handleShowSignIn={handleShowSignIn}
        />
      )}

      {showRessetPass && (
        <RessetPass
          show={showRessetPass}
          handleClose={handleCloseRessetPass}
          handleShowSignIn={handleShowSignIn}
        />
      )}

      {showFoggot && (
        <FogotPass
          show={showFoggot}
          handleClose={handleCloseFoggot}
          handleShowSignIn={handleShowSignIn}
        />
      )}
    </>
  );
};

export default Header;
