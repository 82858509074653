import './style.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import * as htmlToImage from 'html-to-image';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import sprite from 'src/assets/sprite.svg';
import { BigWaiterNotFon } from 'src/components';
import CvTemplates from 'src/cv-templates';
import { useTranslation } from 'src/hooks';
import { setRedirectTo, setShowCvPreview } from 'src/store/reducers/templates.reducer';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuidv4 } from 'uuid';

const Preview = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const { translate } = useTranslation();
  const { subscription } = useSelector((state) => state.authReducer.user);
  const [onRendered, setOnRendered] = useState(false);
  const [chuncksImage, setchunckImage] = useState([]);
  const [toPrint, setToPrint] = useState(true);
  const [overHeight, setOverHeight] = useState(1122);
  const handleClose = () => {
    dispatch(setRedirectTo(''));
    dispatch(setShowCvPreview(false));
  };

  const handlePrint = useReactToPrint({
    content: () => ref.current,

    onAfterPrint: () => {
      setToPrint(false);
    },
  });

  const setBreak = (id) => {
    const page = document.getElementById(id);
    const sectionCol = page.querySelectorAll('.section-col');

    sectionCol.forEach((col) => {
      const sections = col.querySelectorAll('.section');
      let totalHeight = 0;
      sections.forEach((item) => {
        totalHeight += item.offsetHeight;

        if (totalHeight >= overHeight) {
          const subSections = item.querySelectorAll('.sub-section');

          if (subSections.length) {
            totalHeight -= item.offsetHeight;

            subSections.forEach((subSec) => {
              totalHeight += subSec.offsetHeight;

              if (totalHeight >= overHeight) {
                subSec.classList.add('break-before');
                totalHeight = subSec.offsetHeight;
              }
            });
          } else {
            item.classList.add('break-before');
            totalHeight = 0;
          }
        }
      });
    });
  };

  const removeSecClone = (id) => {
    const page = document.getElementById(id);
    const sectionCol = page.querySelectorAll('.section-col');

    sectionCol.forEach((col) => {
      const sections = col.querySelectorAll('.section');
      let deleteSec = true;
      let deleteSub = true;
      sections.forEach((sec) => {
        const subSections = sec.querySelectorAll('.sub-section');

        if (subSections) {
          subSections.forEach((sub) => {
            if (sub.classList.contains('break-before')) {
              sub.classList.remove('break-before');
              deleteSec = false;
              deleteSub = false;
            } else if (deleteSub) {
              sub.remove();
            }
          });
        }

        if (sec.classList.contains('break-before')) {
          sec.classList.remove('break-before');
          deleteSec = false;
        } else if (deleteSec) {
          sec.remove();
        }
      });
    });
  };

  const removeSec = (id) => {
    const page = document.getElementById(id);
    const sectionCol = page.querySelectorAll('.section-col');

    sectionCol.forEach((col) => {
      const sections = col.querySelectorAll('.section');
      let deleteSec = false;
      sections.forEach((item) => {
        const subSections = item.querySelectorAll('.sub-section');

        if (item.classList.contains('break-before')) {
          item.remove();
          deleteSec = true;
        } else if (deleteSec) {
          item.remove();
        }

        if (subSections) {
          let deleteSub = false;
          subSections.forEach((sub) => {
            if (sub.classList.contains('break-before')) {
              sub.remove();
              deleteSub = true;
              deleteSec = true;
            } else if (deleteSub) {
              sub.remove();
            }
          });
        }
      });
    });
  };

  const checkAnotherPage = (id) => {
    const view = document.getElementById('view');
    const page = document.getElementById(id);
    setBreak(id);
    let haveBreack = page.querySelectorAll('.break-before');

    while (haveBreack.length) {
      const allPages = view.querySelectorAll('.template__page');

      const pageClone = allPages[allPages.length - 1].cloneNode(true);
      const newId = uuidv4();
      pageClone.setAttribute('id', newId);
      view.appendChild(pageClone);
      removeSec(id);
      removeSecClone(newId);
      setBreak(newId);
      haveBreack = document.getElementById(newId).querySelectorAll('.break-before');
    }
  };

  useEffect(() => {
    if (onRendered) {
      const view = document.getElementById('view');
      const firstPage = document.getElementById('firstPage');
      setBreak('firstPage');

      const allBreack = view.querySelectorAll('.break-before');

      if (allBreack.length) {
        const pageClone = firstPage.cloneNode(true);
        const id = uuidv4();
        pageClone.setAttribute('id', id);
        view.appendChild(pageClone);

        removeSec('firstPage');
        removeSecClone(id);
        checkAnotherPage(id);
      }

      const allPages = view.querySelectorAll('.template__page');

      htmlToImage.toPng(view, { pixelRatio: 1 }).then((dataUrl) => {
        const img = new Image();
        img.src = dataUrl;
        img.onload = () => {
          let startY = 0;
          const height = 1122;
          for (let i = 0; i < allPages.length; i += 1) {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = height;
            ctx.drawImage(img, 0, startY, img.width, 1122, 0, 0, canvas.width, canvas.height);

            const url = canvas.toDataURL('image/png');
            setchunckImage((prevArray) => [...prevArray, url]);
            startY += height;

            setTimeout(() => {
              setToPrint(false);
            }, 1000);
          }
        };
      });
    }
  }, [onRendered]);

  return (
    <>
      <div className="preview">
        <button type="button" className="close" onClick={handleClose}>
          <svg width="40" height="40" viewBox="0 0 24 24">
            <use xlinkHref={`${sprite}#burger-close`} />
          </svg>
        </button>
        {!toPrint ? (
          <div className="preview__wrap" id="preview__wrap">
            <div className="template">
              <div className="fadeIn" id="previewSwiper">
                <div className="preview__download">
                  {subscription ? (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => {
                        setToPrint(true);
                        setTimeout(() => {
                          handlePrint();
                        }, 100);
                      }}
                    >
                      {translate('components.download_pdf')}
                    </button>
                  ) : (
                    <Link to="choose" className="btn btn-primary" onClick={handleClose}>
                      {translate('components.download_pdf')}
                    </Link>
                  )}
                </div>

                <Swiper
                  className="preview__swiper"
                  pagination={{
                    type: 'fraction',
                  }}
                  navigation
                  modules={[Pagination, Navigation]}
                >
                  {chuncksImage.map((item) => (
                    <SwiperSlide key={uuidv4()}>
                      <div className="preview__swiper__img">
                        <img src={item} alt="preview" />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        ) : (
          <BigWaiterNotFon />
        )}
      </div>
      <div
        ref={ref}
        id="view"
        className={`template toPrint ${toPrint ? 'showPrint' : 'hidePrint'}`}
      >
        <div className="template__page" id="firstPage">
          <CvTemplates setOnRendered={setOnRendered} setOverHeight={setOverHeight} />
        </div>
      </div>
    </>
  );
};

export default Preview;
