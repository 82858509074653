import './style.scss';

import { useSelector } from 'react-redux';
import { Covers, Resumes } from 'src/components';
import { useTranslation } from 'src/hooks';

const Dashboard = () => {
  const { translate } = useTranslation();
  const name = useSelector((state) => state.authReducer.user.name);
  return (
    <div className="wrap content">
      <div className="content__wrap">
        <div className="content__tilte">
          {translate('Home.welcome')} {name}!
        </div>
        <div className="content__text">{translate('Home.build')}</div>
      </div>
      <div className="dash">
        <Resumes showTitle />
        <Covers showTitle />
      </div>
    </div>
  );
};

export default Dashboard;
